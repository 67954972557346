import _ from "lodash";
import { AppAuthStatesCollection } from "./auth-state";
import { liveReviewDownloadKey, StoreState } from "./store";
import { createSelector } from 'reselect';
import { Filter, getMultipleFilteredTrainingTaskGroups, getSearchedTrainingTasks, getSortedTrainingTaskGroups, getTrainingTaskGroups, SortType, TrainingTask } from "../datasets/training-task";
import { Workspace } from "./work-state";

export function getHasUploadFailed(scanId: string) {
    return (store: StoreState) => store.uploads[scanId].failed;
}

export function getAuthState(authAppName: string) {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(authAppName);
}

export function getAuthStatesThatNeedLogin() {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).getAppsThatNeedLogin();
}

export function getAuthStatesAreLoginsNeeded() {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).isWaitingForLogins();
}

export function getUrlQuery() {
    return (store: StoreState) => store.urlQuery;
}

export function isLiveReviewDownloadInitialized() {
    return (store: StoreState) => _.has(store.downloads, liveReviewDownloadKey) && store.downloads[liveReviewDownloadKey];
}

export function getUser() {
    return (store: StoreState) => store.user;
}

export function getUserAccess() {
    return (store: StoreState) => store.userStorageAccess !== undefined ? Object.values(store.userStorageAccess) : store.userStorageAccess;
}

export function getDataset(datasetId: string) {
    return (store: StoreState) => store.datasets[datasetId];
}

export function getLocksForDataset(datasetId: string) {
    return (store: StoreState) => store.datasetLocks[datasetId];
}

export function getLocksforStructure(structureId: string) {
    return (store: StoreState) => {
        if (store.structureSetLocks === undefined) {
            return {};
        }
        return store.structureSetLocks[structureId];
    };
}

export function getStructureSetLocks() {
    return (store: StoreState) => store.structureSetLocks;
}

export function getModifiedDatasetGradings(datasetId: string) {
    return (store: StoreState) => store.modifiedDatasetGradings[datasetId] || [];
}

export function getLoginError() {
    return (store: StoreState) => store.loginError;
}

export function getWasLoginSuccessful() {
    return (store: StoreState) => store.loginError === null;
}

export function getCurrentWorkState() {
    return (store: StoreState) => store.currentWorkState;
}

export function getScan(scanId: string) {
    return (store: StoreState) => {
        if (!store.scans || !store.scans[scanId]) { throw new Error(`Could not retrieve scan "${scanId}" from store`); }
        return store.scans[scanId];
    }
}

export function getTaskById(id: string) {
    return (store: StoreState) => store.trainingTasks ? store.trainingTasks.find(t => t.id === id) : undefined;
}

export function getArchivedTaskById(id: string) {
    return (store: StoreState) => store.archivedTrainingTasks ? store.archivedTrainingTasks.find(t => t.id === id) : undefined;
}

export function getCurrentTask() {
    return (store: StoreState) => store.currentTask;
}

export function isDownloadFinished(downloadKey: string) {
    return (store: StoreState) => store.downloads[downloadKey] && store.downloads[downloadKey].ready;
}

export function getUserSettings() {
    return (store: StoreState) => store.userSettings;
}

export function getRoiAliasMap() {
    return (store: StoreState) => store.roiAliasMap;
}



// MEMOIZED RE-SELECT SELECTORS

const selectTrainingTasks = (state: StoreState) => state.trainingTasks || [];
const selectSearchText = (state: StoreState) => state.searchText || '';
const selectSortType = (state: StoreState) => state.sortType || undefined;
const selectScans = (state: StoreState) => state.scans || {};
const selectFilters = (state: StoreState) => state.tasksFilters || [];
export const selectScanById = createSelector([selectScans, (state: StoreState, scanId: string) => scanId],
    (scans, scanId) => {
        if (!scans || !scans[scanId]) { throw new Error(`Could not retrieve scan "${scanId}" from store`); }
        return scans[scanId];
    });

export const selectTasksGroupedByName = createSelector(selectTrainingTasks, trainingTasks => getTrainingTaskGroups(trainingTasks));

export const selectFilteredTasks = createSelector(
    [selectTrainingTasks, selectSearchText, selectSortType, selectFilters],
    (
        trainingTasks: TrainingTask[],
        searchText: string | undefined,
        sortType?: SortType,
        filters?: Filter[]
    ) => {
        let filteredTasks = getSearchedTrainingTasks(trainingTasks, searchText || '');

        if (filters && filters.length > 0) {
            filteredTasks = getMultipleFilteredTrainingTaskGroups(filteredTasks, filters);
        }

        if (sortType) {
            filteredTasks = getSortedTrainingTaskGroups(filteredTasks, sortType);
        }

        return filteredTasks;
    }
);

const currentWorkState = (state: StoreState) => state.currentWorkState;

/** Returns an array of supporting scans for current active scan in workstate (does NOT return the current scan!) */
export const selectSupportingScansForCurrentWorkState = createSelector([currentWorkState], (currentWorkState) => {
    if (!currentWorkState || !currentWorkState.dataset || !currentWorkState.datasetImage) return [];
    
    const img = currentWorkState.datasetImage;
    return currentWorkState.dataset.images.filter(i => i.patientId === img.patientId && i.scanId !== img.scanId);
});

/** Returns current workspace (Reference Library, Guide, Verify...) */
export const selectCurrentWorkspace = createSelector([currentWorkState], (currentWorkState) => currentWorkState ? currentWorkState.workspace : Workspace.None);

/** Returns true if current workspace is Reference Library, false otherwise */
export const selectIsReferenceLibraryWorkspace = createSelector([selectCurrentWorkspace], (workspace) => workspace === Workspace.ReferenceLibrary);

/** Returns true if current workspace is Guide, false otherwise */
export const selectIsGuideWorkspace = createSelector([selectCurrentWorkspace], (workspace) => workspace === Workspace.GuidelineTraining);

/** Returns true if current workspace is Verify, false otherwise */
export const selectIsVerifyWorkspace = createSelector([selectCurrentWorkspace], (workspace) => workspace === Workspace.QATool);
