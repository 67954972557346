import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';

import LandingPageLayout from './LandingPageLayout';
import { StoreState } from '../store/store';
import { LoginError } from '../store/errors';
import { getAppName } from '../environments';

import './ErrorBoundary.css';


type OwnProps = {
    children: React.ReactNode;
}

type AllProps = OwnProps & StoreState;

type OwnState = {
    crashed: boolean,
    error?: any
}


class ErrorBoundary extends React.Component<AllProps, OwnState> {
    displayName = ErrorBoundary.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
            crashed: false,
            error: undefined
        };
    }

    componentDidCatch(error: any, info: any) {
        console.log('Catched an error in error boundary:');
        console.log(error);
        this.setState({ crashed: true, error: error });
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { failed: true };
    }

    errorToString(error: any): string {
        if (error) {
            if (_.isError(error)) {
                return `Name: ${error.name}\nMessage: ${error.message}\n${error.stack}`
            } else {
                try {
                    if (_.has(error, 'errorMessage')) {
                        return error.errorMessage;
                    } else if (_.has(error, 'message')) {
                        return error.message;
                    } else {
                        return error.toString();
                    }
                }
                catch (err) {
                    return '';
                }
            }
        }
        return '';
    }

    render() {
        const loginError: LoginError | null = this.props.loginError;
        const wasLoginSuccessful = loginError === null;
        const arePopupsBlocked = !wasLoginSuccessful && loginError && loginError.arePopupsBlocked;
        const loginErrorMessage = !wasLoginSuccessful && !arePopupsBlocked && loginError && loginError.errorMessage;

        const hasErrored = !wasLoginSuccessful || this.state.crashed || this.props.appCrashError;
        if (!hasErrored) {
            return this.props.children;
        }

        const errorMessage = this.errorToString(this.state.crashed ? this.state.error : this.props.appCrashError);

        return (
            <Container fluid className="home-page">
                <Row>
                    <LandingPageLayout>
                        <div className="crash-page">
                            {arePopupsBlocked ? (
                                <>
                                    <div className="crash-heading">Pop-ups are blocked</div>
                                    <div>
                                        It looks like your browser might be blocking pop-up windows. {getAppName()} requires
                                    pop-ups to be enabled for it to work. Please enable pop-ups for this site
                                    and reload the page.
                                </div>
                                    <div>
                                        For instructions on how to enable pop-ups in different browsers, see the following links:
                                </div>
                                    <ul>
                                        <li>
                                            <a href='https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=en#zippy=%2Callow-pop-ups-from-a-site'>Chrome</a>
                                        </li>
                                        <li>
                                            <a href='https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting'>Firefox</a>
                                        </li>
                                        <li>
                                            <a href='https://support.apple.com/en-gb/guide/safari/sfri40696/16.1/mac/13.0'>Safari</a>
                                        </li>
                                        <li>
                                            <a href='https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5'>Edge</a>
                                        </li>
                                    </ul>
                                    <div>
                                        If you're still seeing this error after enabling pop-ups for {getAppName()},
                                    try restarting your browser.
                                </div>
                                </>
                            ) : !wasLoginSuccessful && loginErrorMessage ? (
                                <>
                                    <div className="crash-heading">An error occurred during login</div>
                                    <div>
                                        An error occurred when trying to log you in:
                                    </div>
                                    <div>{loginErrorMessage}</div>
                                    <div>
                                        If this error persists, please contact a system administrator.
                                    </div>
                                </>
                            ) : (
                                        <>
                                            <div className="crash-heading">Application has crashed</div>
                                            <div>If this error problem persists please contact MVision support.</div>
                                            {errorMessage && (
                                                <>
                                                    <div>The following error message was received:</div>
                                                    <div className="error-message-container">{errorMessage}</div>
                                                </>
                                            )}
                                        </>
                                    )
                            }

                        </div>
                    </LandingPageLayout>
                </Row>
            </Container>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
)(ErrorBoundary);
