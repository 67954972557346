import React from 'react';
import { Button } from 'react-bootstrap';
import { contextMenu } from 'react-contexify';
import { connect } from 'react-redux';

import TriStateCheckbox, { TriStateCheckboxState } from '../common/TriStateCheckbox';
import DatasetBatchJobCell from '../annotation-page/dataset-table/DatasetBatchJobCell';
import { PageImage, PageStructureSet } from '../annotation-page/models/PagePatient';
import LinkButton from '../common/LinkButton';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import { StoreState } from '../../store/store';
import { BatchJobOperation } from '../annotation-page/dataset-table/DatasetPage';
import { LockAction } from '../../datasets/locks';
import { IoMdEye } from 'react-icons/io';
import { MdClose, MdDownload } from 'react-icons/md';
import { ExpertContoursName } from '../../dicom/structure-set';
import routePaths from '../../routes';
import { UrlQuery, UrlQueryType } from '../../store/url-query';
import { Link } from 'react-router-dom';


type OwnProps = {
    dataset: Dataset;
    image: PageImage,
    isLastImageOfList: boolean,
    moreItemsAvailable: boolean,
    areBatchColumnsVisible: boolean,
    patientIndex: number,
    imageIndex: number,
    batchSelections: { [checkboxId: string]: TriStateCheckboxState },
    showBatchControls?: boolean,
    batchSelectionOperations: { [checkboxId: string]: BatchJobOperation },
    getCheckboxId: (patientId: string, imageId?: string, structureSetId?: string) => string,
    handleTriStateCheckboxChange: (checkboxId: string, event: any) => void,
    handleLockClick: (datasetImage: DatasetImage, lockAction: LockAction) => void,
    handleLoadClick: (datasetImage: DatasetImage) => void,
    handleUnloadClick: (datasetImage: DatasetImage) => void,
    handleViewImageClick: (datasetImage: DatasetImage) => void,
    handleBatchOperationTypeChange: (value: { checkboxId: string, operation: BatchJobOperation }) => void,
    handleShowAllImagesClick: (image: PageImage) => void,
    handleShowAllStructureSetsClick: (image: PageImage) => void,
}

type AllProps = OwnProps & StoreState;

class ReferenceLibraryRow extends React.PureComponent<AllProps> {
    displayName = ReferenceLibraryRow.name

    onShowAllImagesClick = () => {
        this.props.handleShowAllImagesClick(this.props.image);
    }

    onShowAllStructureSetsClick = () => {
        this.props.handleShowAllStructureSetsClick(this.props.image);
    }

    handleShowContextMenu = (menuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: menuId, event: evt });
    }

    handleLoadClick = (datasetImage: DatasetImage, evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // don't perform this action if user is using ctrl-click to open this scan in a new tab
        if (!evt.ctrlKey && !evt.metaKey) {
            evt.preventDefault();
            this.props.handleLoadClick(datasetImage);
        }
    }

    handleViewClick = (datasetImage: DatasetImage, evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // don't perform this action if user is using ctrl-click to open this task in a new tab
        if (!evt.ctrlKey && !evt.metaKey) {
            evt.preventDefault();
            this.props.handleViewImageClick(datasetImage);
        }
    }

    getDirectLink = (pageImage: PageImage): { pathname: string, search: string } => {
        const { datasetFile } = this.props.dataset;
        const { image } = pageImage;
        const storageAccount = datasetFile.storageAccountName;
        const fileShare = datasetFile.fileShareName;
        const patientId = image.patientId;
        const forUid = image.frameOfReferenceUid;
        const seriesUid = image.seriesId;

        return {
            pathname: routePaths.referenceLibrary,
            search: new UrlQuery(UrlQueryType.AnnotationWorkQuery, storageAccount, fileShare, patientId, forUid, seriesUid)
                .getQueryParameters(UrlQueryType.AnnotationWorkQuery)
        }
    }

    renderStructureSet = (pss: PageStructureSet, img: PageImage) => {
        const { handleTriStateCheckboxChange, getCheckboxId, batchSelections } = this.props;

        const ss = pss.structureSet;
        const ptId = img.image.patientId;

        const tooltip = ss.roiMappings.map(rm => rm.originalName).sort().join(", ");

        return (
            <div key={img.seriesId + ss.sopId} className="structure-set-item">
                <div title={tooltip} className="structure-set-info">
                    {this.props.showBatchControls ? <TriStateCheckbox
                        onCheckboxChange={handleTriStateCheckboxChange}
                        id={getCheckboxId(ptId, img.seriesId, img.seriesId + ss.sopId)}
                        checked={batchSelections[getCheckboxId(ptId, img.seriesId, img.seriesId + ss.sopId)].state}>
                        {ss.label}
                    </TriStateCheckbox> : ss.label
                    }
                </div>
            </div>
        );
    }

    renderLoadCell = (pageImage: PageImage) => {
        const datasetImage = pageImage.image;
        const download = this.props.downloads![datasetImage.downloadKey];

        if (!download) {
            return (
                <Link to={this.getDirectLink(pageImage)}>
                    <Button variant={"light"} size="sm" data-cy="load-button" title="Load DICOM files"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleLoadClick(datasetImage, evt)}>
                        <MdDownload size={20} />
                    </Button>
                </Link>
            );
        }
        else if (download.failed) {
            return (<b title="Download failed! Please refresh the page and try again.">Failed!</b>);
        }
        else if (download.ready) {
            return (<Button variant={"danger"} data-cy="unload-button" size="sm" title="Unload DICOM files"
                onClick={(evt: any) => this.props.handleUnloadClick(datasetImage)}>
                <MdClose size={20} />
            </Button>);
        }
        else {
            let progress = parseInt(download.progressPercentage.toString(), 10) + '%';
            return (<b data-cy="progress-number">{progress}</b>);
        }
    }

    renderViewCell = (pageImage: PageImage) => {
        const datasetImage = pageImage.image;
        const download = this.props.downloads![datasetImage.downloadKey];
        if (download && !download.failed && download.ready) {
            return (
                <Link to={this.getDirectLink(pageImage)}>
                    <Button variant={"light"} size="sm" title="View image"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleViewClick(datasetImage, evt)}>
                        <IoMdEye size={20} />
                    </Button>
                </Link>
            );
        }
        return null;
    }

    render() {
        const { image, areBatchColumnsVisible, patientIndex, imageIndex, batchSelections, getCheckboxId, handleTriStateCheckboxChange } = this.props;
        const datasetImage = image.image;

        const patientCheckboxId = getCheckboxId(datasetImage.patientId);
        const imageCheckboxId = getCheckboxId(datasetImage.patientId, image.seriesId);
        const patientChecked = batchSelections[patientCheckboxId].state;
        const imageChecked = batchSelections[imageCheckboxId].state;

        // only show structure sets that are either expert contours or are AI contouring practice sets (i.e. match model names)
        const structureSets = image.getStructureSets().filter(pss => pss.structureSet.label === ExpertContoursName
            || this.props.referenceAiModels && this.props.referenceAiModels.map(r => r.name).includes(pss.structureSet.label));

        return (
            <tr className={patientIndex % 2 ? "odd-row" : ""}>
                <td>{imageIndex === 0 ? (this.props.showBatchControls ?
                    <TriStateCheckbox
                        onCheckboxChange={handleTriStateCheckboxChange}
                        id={patientCheckboxId}
                        checked={patientChecked}
                    >{datasetImage.patientId}</TriStateCheckbox> : datasetImage.patientId) : ""}
                </td>
                <td>{datasetImage.modality}</td>
                <td className={this.props.showBatchControls ? 'minimized' : ''}>
                    <div className="structure-set-list">
                        {structureSets.map(pss => this.renderStructureSet(pss, image))}
                        {image.hasMoreStructureSets() && (<div className="structure-set-item"><LinkButton onClick={this.onShowAllStructureSetsClick}>Show all structure sets</LinkButton></div>)}
                    </div>
                </td>
                <td>
                    {this.renderLoadCell(image)}
                </td>
                <td>
                    {this.renderViewCell(image)}
                </td>
                {areBatchColumnsVisible && (
                    <DatasetBatchJobCell
                        image={image}
                        imageCheckboxId={imageCheckboxId}
                        imageChecked={imageChecked}
                        currentBatchOperation={this.props.batchSelectionOperations[imageCheckboxId]}
                        handleBatchOperationTypeChange={this.props.handleBatchOperationTypeChange}
                    />)}
            </tr>
        );
    }
}

export default connect(
    state => Object.assign({}, state)
)(ReferenceLibraryRow);
