import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Table, Form } from 'react-bootstrap';

import * as sagas from '../../../store/sagas';
import { StoreState } from '../../../store/store';
import { AliasMap } from '../../../store/alias-map';
import { sortAlphanumeric } from '../../../util';

const ALIAS_SEPARATOR = ';';

type AliasAsCsv = {
    roiName: string,
    aliases: string,
};

type Props = {
    show: boolean;
    onClose: (initializeAgain?: boolean) => void;
}

type DispatchProps = {
    setRoiAliases: (aliasMap: AliasMap) => void,
}

type AllProps = Props & StoreState & DispatchProps;

type OwnState = {
    aliasMapAsCsv: AliasAsCsv[],
}


class AliasMapEditorDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            aliasMapAsCsv: [],
        };
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.roiAliasMap !== this.props.roiAliasMap) {
            this.initialize();
        }
    }

    initialize = () => {
        const roiAliasMap: AliasMap = this.props.roiAliasMap || {};
        const aliasMapAsCsv: AliasAsCsv[] = Object.keys(roiAliasMap).sort(sortAlphanumeric).map(roiName => ({ roiName, aliases: roiAliasMap[roiName].join(ALIAS_SEPARATOR) }));
        this.setState({ aliasMapAsCsv });
    }

    handleSetCsvAlias = (roiName: string, newAliases: string) => {
        this.setState({
            aliasMapAsCsv: this.state.aliasMapAsCsv.map(a => a.roiName === roiName ? { roiName, aliases: newAliases } : a)
        });
    }

    handleRemoveAlias = (roiName: string) => {
        this.setState({
            aliasMapAsCsv: this.state.aliasMapAsCsv.filter(a => a.roiName !== roiName)
        });
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleSave = () => {

        if (!this.props.roiAliasMap) {
            return;
        }

        // update alias map
        const updatedAliasMap: AliasMap = {};
        for (const aliases of this.state.aliasMapAsCsv) {
            updatedAliasMap[aliases.roiName] = aliases.aliases.split(ALIAS_SEPARATOR).map(a => a.trim());
        }

        this.props.setRoiAliases(updatedAliasMap);
        this.props.onClose(true);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Structure Alias Mapping Editor</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Table className="modalTable roi-alias-table">
                                <thead className="metrics-headers">
                                    <tr>
                                        <th>Structure Name</th>
                                        <th title='Use semicolons to separate aliases, e.g. "alias_1;alias_2;alias_3"'>Aliases (semicolon-separated)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.aliasMapAsCsv.map(alias => (
                                        <AliasCsvField
                                            key={alias.roiName}
                                            roiName={alias.roiName}
                                            aliases={alias.aliases}
                                            onAliasesChanged={this.handleSetCsvAlias}
                                            onAliasRemoved={this.handleRemoveAlias}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleSave}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class AliasCsvField extends React.Component<{
    roiName: string,
    aliases: string,
    onAliasesChanged: (roiName: string, newAliases: string) => void,
    onAliasRemoved: (roiName: string) => void,
}> {

    handleAliasesChanged = (evt: any) => {
        const value: string = evt.target.value;
        this.props.onAliasesChanged(this.props.roiName, value);
    }

    handleClearAlias = () => {
        this.props.onAliasRemoved(this.props.roiName);
    }

    render() {
        const { roiName, aliases } = this.props;

        return (
            <tr key={roiName}>
                <td className="test-roi-name">
                    {roiName}
                </td>
                <td className="test-roi-alias">
                    <Form.Control type="text" value={aliases} onChange={this.handleAliasesChanged} />
                </td>
                <td className="save-alias-check">
                    <Button onClick={this.handleClearAlias}>Clear alias</Button>
                </td>
            </tr>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AliasMapEditorDialog);
