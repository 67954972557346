import React from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdCheck, MdClose } from 'react-icons/md';
import { Roi } from '../../dicom/structure-set';

import './ColorPickerDialog.css'

interface ColorPickerProps {
    roi: Roi;
    position: {
        top: number;
        left: number;
    };
    selectedColor: {
        r: number;
        g: number;
        b: number;
        rgb?: {
            r: number;
            g: number;
            b: number;
        };
    };
    onSelect: (color: ColorResult) => void;
    onCancel: () => void;
    onConfirm: () => void;
    onClose: () => void;
}

type ColorType = ColorPickerProps['selectedColor'];

class ColorPickerDialog extends React.Component<ColorPickerProps> {
    getBackgroundColor = (color: ColorType): string => {
        const rgb = color;
        return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    };

    render() {
        const {
            roi,
            position,
            selectedColor,
            onSelect,
            onCancel,
            onConfirm,
            onClose
        } = this.props;

        return (
            <div
                style={{
                    position: 'fixed',
                    top: position.top,
                    left: position.left,
                    zIndex: 1000
                }}
            >
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                    }}
                    onClick={onClose}
                />

                <div className="p-2 bg-picker">
                    <div className="mb-1">
                        <span className="text-white ml-1">{roi.name}</span>
                    </div>

                    <SketchPicker
                        color={selectedColor}
                        onChangeComplete={onSelect}
                    />

                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="success"
                            size="sm"
                            onClick={onConfirm}
                            className="d-flex align-items-center"
                        >
                            <MdCheck size={16} className="mr-1" />
                            Confirm
                        </Button>
                        <Button
                            variant="light"
                            size="sm"
                            onClick={onCancel}
                            className="d-flex align-items-center"
                        >
                            <MdClose size={16} className="mr-1" />
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ColorPickerDialog;