import React from 'react';
import Select, { OptionsType } from 'react-select';
import { Modal, Button, Table, Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { IoMdSwap } from "react-icons/io";
import { MdClear } from "react-icons/md";
import { IconContext } from "react-icons";

import * as sagas from '../../../store/sagas';
import { StoreState } from '../../../store/store';
import { sortAlphanumeric, sleep, notEmpty } from '../../../util';
import { AliasMap, LocalAliasMap, initializeLocalAliasMap } from '../../../store/alias-map';
import AliasMapEditorDialog from './AliasMapEditorDialog';

import './RunCalculationModal.css';
import { ComparisonSelectorCollection } from '../toolbars/comparison-types';
import LinkButton from '../../common/LinkButton';

type Props = {
    show: boolean;
    handleClose: () => void;
    handleCalculateMetrics: (aliasMap: LocalAliasMap) => void;
}

type DispatchProps = {
    addRoiAliases: (aliases: AliasMap) => void,
    saveUserSettings: () => void,
    setTestAndReferenceStructureObjects: (testAndReferenceStructureObjects: ComparisonSelectorCollection) => void,
}

type AllProps = Props & StoreState & DispatchProps;

type OwnState = {
    testRois: string[];
    referenceRois: string[];

    /** Current local mapping of reference rois to test rois */
    currentAliasMap: LocalAliasMap;

    /** Controls which of current aliases will be saved to backend (these all default to on) */
    saveAliases: { [roiName: string]: boolean },

    /** Alias mapping dropdown options */
    mappingOptions: OptionsType<{ label: string; value: string; }>;

    /** True to show the full alias mapping editor dialog, false otherwise */
    showAliasMappingEditor: boolean;

    /** Hack to force the form to re-initialize after coming back from alias mapping editor */
    forceInitialization: boolean;

    /** Hides this dialog when alias mapping editor is open -- also part of a hack to get this to re-initialize properly */
    temporaryHide: boolean,
}

class RunCalculationModal extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            testRois: [],
            referenceRois: [],
            currentAliasMap: {},
            saveAliases: {},
            mappingOptions: [],
            showAliasMappingEditor: false,
            forceInitialization: false,
            temporaryHide: false,
        };
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps: AllProps, prevState: OwnState) {
        if (prevProps.testStructureSet !== this.props.testStructureSet || prevProps.referenceStructureSet !== this.props.referenceStructureSet) {
            this.saveMapToStore();
            this.initialize();
        } else if (prevState.forceInitialization !== this.state.forceInitialization) {
            this.initialize();
        }
    }

    componentWillUnmount() {
        const anyAliasesSaved = this.saveMapToStore();

        // also save current alias map to backend (unless none of the ROI aliases are being saved)
        if (anyAliasesSaved) {
            this.props.saveUserSettings();
        }
    }

    initialize = () => {
        const { testStructureSet, referenceStructureSet } = this.props;
        if (!testStructureSet || !referenceStructureSet) {
            return;
        }

        const testRois = Object.values(testStructureSet.rois).map(r => r.name.trim()).sort(sortAlphanumeric);
        const referenceRois = Object.values(referenceStructureSet.rois).map(r => r.name.trim()).sort(sortAlphanumeric);

        // get existing roi alias map from store
        const defaultAliasMap = this.props.roiAliasMap || {};

        const currentAliasMap = initializeLocalAliasMap(testRois, referenceRois, defaultAliasMap);
        const saveAliases: { [roiName: string]: boolean } = {};
        Object.keys(currentAliasMap).forEach(r => saveAliases[r] = true);

        const mappingOptions = referenceRois.map(rr => ({ label: rr, value: rr }));

        this.setState({
            testRois,
            referenceRois,
            currentAliasMap,
            mappingOptions,
            saveAliases,
        });
    }

    /** Saves current local alias map to redux store. Returns 'true' if any aliases were saved, false otherwise. */
    saveMapToStore = (): boolean => {
        // save current roi alias map to store
        // our local alias map uses test roi names as indices, but the store alias map uses
        // reference roi names as indices, so flip the map first
        const flippedAliasMap: AliasMap = {};

        // track if we're actually saving anything
        let anyAliasesSaved = false;

        for (const alias of Object.keys(this.state.currentAliasMap)) {
            const roiName = this.state.currentAliasMap[alias];

            // ignore items where roiName and alias are identical
            if (roiName && roiName !== alias && _.get(this.state.saveAliases, alias, false)) {
                // use reference roi name to index the new alias map, and assign the test roi name into its alias array
                flippedAliasMap[roiName] = (flippedAliasMap[roiName] || []).concat([alias]);
                anyAliasesSaved = true;
            }
        }

        this.props.addRoiAliases(flippedAliasMap);

        return anyAliasesSaved;
    }

    handleAliasChanged = (roiName: string, alias: string | undefined) => {
        const newCurrentAliasMap: LocalAliasMap = {};
        Object.keys(this.state.currentAliasMap).forEach(r => newCurrentAliasMap[r] = r === roiName ? alias : this.state.currentAliasMap[r]);
        this.setState({ currentAliasMap: newCurrentAliasMap });
    }

    handleClearAllClicked = () => {
        const newCurrentAliasMap: LocalAliasMap = {};
        Object.keys(this.state.currentAliasMap).forEach(r => newCurrentAliasMap[r] = undefined);
        this.setState({ currentAliasMap: newCurrentAliasMap });
    }

    handleCalculateMetricsClicked = () => {
        this.props.handleCalculateMetrics(this.state.currentAliasMap);
    }

    handleOpenAliasMappingEditor = () => {
        this.saveMapToStore();
        this.setState({ showAliasMappingEditor: true, temporaryHide: true });
    }

    handleCloseAliasMappingEditor = (initializeAgain: boolean = false) => {
        this.setState({ showAliasMappingEditor: false }, async () => {
            // this is a kludge to get the form UI to properly re-init and refresh after we come back from alias map editor
            if (initializeAgain) {
                await sleep(10);
                this.setState({ forceInitialization: !this.state.forceInitialization }, async () => {
                    await sleep(10);
                    this.setState({ temporaryHide: false });
                });
            }
        });

        if (!initializeAgain) {
            this.setState({ temporaryHide: false });
        }
    }

    handleClose = () => {
        this.props.handleClose();
    }

    handleToggleSaveAlias = (roiName: string) => {
        const newSaveAliases: { [roiName: string]: boolean } = {};
        Object.keys(this.state.saveAliases).forEach(a => newSaveAliases[a] = a === roiName ? !this.state.saveAliases[a] : this.state.saveAliases[a]);
        this.setState({ saveAliases: newSaveAliases });
    }

    handleToggleSaveAliasAll = (toggleAllTo: boolean) => {
        const newSaveAliases: { [roiName: string]: boolean } = {};
        Object.keys(this.state.saveAliases).forEach(a => newSaveAliases[a] = toggleAllTo);
        this.setState({ saveAliases: newSaveAliases });
    }

    handleSwapStructureSets = () => {
        const { testStructureSet, referenceStructureSet, testRoi, referenceRoi } = this.props;

        this.saveMapToStore();

        this.props.setTestAndReferenceStructureObjects({
            testStructureSet: referenceStructureSet,
            referenceStructureSet: testStructureSet,
            testRoi: referenceRoi,
            referenceRoi: testRoi,
        });
    }

    /**
     * Generate a currentAliasMap-derived key for a react-select component (to force it to re-render when
     * its value is cleared)
     */
    getSelectKey = (roi: string) => {
        const { currentAliasMap } = this.state;
        return `roi-mapping-selector-dropdown-${roi}-${Object.values(currentAliasMap).filter(notEmpty).length}`;
    }

    render() {
        const { testRois, currentAliasMap, mappingOptions, saveAliases, temporaryHide } = this.state;

        const metricsCalculationCount = Object.values(currentAliasMap).filter(notEmpty).length;
        const areAllAliasesAreChecked = Object.keys(saveAliases).length > 0 && Object.values(saveAliases).every(c => c === true);
        const calculateMetricsButtonTitle = `Calculate metrics using ${metricsCalculationCount} structure${metricsCalculationCount === 1 ? '' : 's'}`;

        const calculateMetricsButton = (id: string) => (
            <OverlayTrigger
                overlay={
                    (<Tooltip id={`tooltip-metric-header-calculate-button-${id}`}>{calculateMetricsButtonTitle}</Tooltip>)
                }
                placement="top"
                delay={200}
            >
                <Button variant="primary" onClick={this.handleCalculateMetricsClicked}>
                    Calculate Metrics <Badge variant="light" className="calculate-metrics-badge">{metricsCalculationCount}</Badge>
                </Button>
            </OverlayTrigger>
        );

        return (
            <>
                <Modal show={temporaryHide ? false : this.props.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Calculate Metrics</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="calculation-modal-body">
                            <div className="calculation-time-note">Metrics will be calculated for structures that have been mapped. Calculation may take a while for larger sets.</div>
                            <div className="top-actions-bar">
                                {calculateMetricsButton('top')}
                                <Button onClick={this.handleSwapStructureSets}>
                                    <IoMdSwap /> Swap test and reference structure sets
                                </Button>
                            </div>
                            <div className="roi-alias-mapping">
                                <div className="roi-alias-mapping-title"><h4>Structure Alias Mapping</h4></div>

                                <div className="alias-map-editor-button">
                                    <Button variant="secondary" onClick={this.handleOpenAliasMappingEditor}>
                                        Open Editor...
                                </Button>
                                </div>

                                <Table className="modalTable roi-alias-table">
                                    <thead className="metrics-headers">
                                        <tr>
                                            <th className="metrics-test-header">Test structure</th>
                                            <th>
                                                <div className="metrics-reference-header">
                                                    <span>Reference structure</span>
                                                    <span>
                                                        <LinkButton
                                                            title="Clears all current structure mappings"
                                                            onClick={this.handleClearAllClicked}
                                                            className="metrics-clear-all-mappings-button">
                                                            <span className="clear-icon"><IconContext.Provider value={{ size: "20px" }}> <MdClear /></IconContext.Provider></span>
                                                            <span>Clear all</span>
                                                        </LinkButton>
                                                    </span>
                                                </div>
                                            </th>
                                            <OverlayTrigger
                                                overlay={
                                                    (<Tooltip id={`tooltip-metric-header-save-alias`}>
                                                        Uncheck to not save used structure aliases to system
                                                    </Tooltip>)
                                                }
                                            >
                                                <th
                                                    className="roi-mapping-save-alias-column"
                                                    onClick={() => this.handleToggleSaveAliasAll(!areAllAliasesAreChecked)}
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`roi-alias-map-save-toggle-all`}
                                                        checked={areAllAliasesAreChecked}
                                                        onChange={() => { }}
                                                        className="roi-alias-map-save-toggle-all"
                                                    />
                                                    Save alias
                                                </th>
                                            </OverlayTrigger>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testRois.map(tr => (
                                            <tr key={tr}>
                                                <td className="test-roi-name">
                                                    {tr}
                                                </td>
                                                <td className="test-roi-alias">
                                                    <Select
                                                        key={this.getSelectKey(tr)}
                                                        placeholder="Select or type alias, or leave blank"
                                                        className="select-input"
                                                        options={mappingOptions}
                                                        name={`Structure alias mapping for ${tr}`}
                                                        onChange={(e: any) => this.handleAliasChanged(tr, _.get(e, 'value', undefined))}
                                                        data-cy={`roi-alias-mapping-for-${tr}`}
                                                        id={`roi-alias-mapping-for-${tr}`}
                                                        value={mappingOptions.find(mo => mo.value === _.get(currentAliasMap, tr, undefined))}
                                                        isClearable
                                                    />
                                                </td>
                                                <OverlayTrigger
                                                    overlay={
                                                        (<Tooltip id={`tooltip-metric-header-save-alias-${tr}`}>
                                                            Uncheck to not save alias used for {tr} into system
                                                        </Tooltip>)
                                                    }
                                                    placement="right"
                                                    delay={250}
                                                >
                                                    <td
                                                        className="save-alias-check"
                                                        onClick={() => this.handleToggleSaveAlias(tr)}
                                                    >

                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`roi-alias-map-save-${tr}`}
                                                            checked={saveAliases[tr]}
                                                            onChange={() => { /** onClick handler in <td> handles checkbox toggle functionality */ }}
                                                        />
                                                    </td>
                                                </OverlayTrigger>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {calculateMetricsButton('bottom')}
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AliasMapEditorDialog
                    show={this.state.showAliasMappingEditor}
                    onClose={this.handleCloseAliasMappingEditor}
                />
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(RunCalculationModal);
