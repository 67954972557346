import {  BackendDefinition, Backend } from "./web-apis/backends";
import { getFilenameSafeTimestamp } from "./util";
import { newGuid } from "./dicom/guid";

// *** ENVIRONMENT TYPES ***

export const isDemo = () => {
    return window.location.hostname.toLowerCase().includes("mvisiondemo") || isLNDemo();
}

export const isLNDemo = () => {
    return window.location.hostname.toLowerCase().includes("mvisiondemoln");
}

/** 
 * Returns TRUE if this is a Rutherford-specific deployment, false otherwise.
 * Rutherford deployment has certain tools and features disabled, and
 * some specific ones enabled that aren't available in regular
 * environments.
 */
export const isRutherford = () => {
    // as of now rutherford support is deprecated & disabled
    return false;
    // return window.location.hostname.toLowerCase().includes("rutherford");
}

/** MVision Guide and MVision Verify  version displayed to users. (The "actual" version of RTViewer is the Git commit hash.) */
export const RTViewerDisplayVersion = '1.1.0';

// *** BACKENDS ***

// // contouring backends
// export const devBackend = new BackendDefinition('Dev nonclinical (dev)', 'https://dev.seg.api.mvision.ai');
// export const demoBackend = new BackendDefinition('Demo nonclinical (demo)', 'https://demo.seg.api.mvision.ai');
// export const euClinicalBackend = new BackendDefinition('EU clinical', 'https://eu2.seg.api.mvision.ai');
// export const euNonclinicalBackend = new BackendDefinition('EU nonclinical', 'https://preview.eu2.seg.api.mvision.ai');
// export const ukClinicalBackend = new BackendDefinition('UK clinical', 'https://uk.seg.api.mvision.ai');
// export const ukNonclinicalBackend = new BackendDefinition('UK nonclinical', 'https://preview.uk.seg.api.mvision.ai');
// export const previewWestEuropeBackend = new BackendDefinition('1.1 West Europe (preview)', 'https://mvd-api-previewwesteurope.azurewebsites.net');
// export const westEuropeBackend = new BackendDefinition('1.1 West Europe (clinical)', 'https://mvd-api-westeurope.azurewebsites.net');
// export const slowClinicalBackend = new BackendDefinition('Slow backend (clinical)', 'https://slow.seg.api.mvision.ai');
// export const slowNonclinicalBackend = new BackendDefinition('Slow backend (nonclinical)', 'https://preview.slow.seg.api.mvision.ai');
// export const usClinicalBackend = new BackendDefinition('US clinical', 'https://us.seg.api.mvision.ai');
// export const usNonclinicalBackend = new BackendDefinition('US nonclinical', 'https://preview.us.seg.api.mvision.ai');
// export const chClinicalBackend = new BackendDefinition('CH clinical', 'https://ch.seg.api.mvision.ai');
// export const chNonclinicalBackend = new BackendDefinition('CH nonclinical', 'https://preview.ch.seg.api.mvision.ai');

// // other backends (that still need MSAL authentication)
// export const rtViewerBackend = new BackendDefinition('RTViewer backend API', 'https://dev.backend.rtviewer.mvision.ai');

// This is the list of backends rtviewer allows users to change to and use for contouring.
// It can be a subset of the entire list of all available backends.
// NOTE: the order of this array will affect the order they are presented in for the user
// in the configuration dropdown.
export const userAllowedBackends: BackendDefinition[] = [
    // demoBackend,
    // devBackend,
    // euNonclinicalBackend,
    // euClinicalBackend,
    // ukNonclinicalBackend,
    // ukClinicalBackend,
    // previewWestEuropeBackend,
    // westEuropeBackend,
    // slowClinicalBackend,
    // slowNonclinicalBackend,
    // usClinicalBackend,
    // usNonclinicalBackend,
    // chClinicalBackend,
    // chNonclinicalBackend,
];

let rtViewerBackend: Backend | null = null;

export function getRTViewerBackend(): Backend {
  if (rtViewerBackend === null) {
    throw new Error('RTViewer backend has not yet been initialized.');
  }

  return rtViewerBackend;
}

export function setRTViewerBackend(backend: Backend) {
    rtViewerBackend = backend;
}


/// *** APP REGISTRATIONS, AUTHORIZATIONS ***

export const MVAPI_AUTH = 'MVAPI_AUTH';
export const MVDAPIPROD_AUTH = 'MVDAPIPROD_AUTH';

export const MVISION_AUTHORITY = 'https://login.microsoftonline.com/mvision.ai';

export const mvapiClientId = 'ebe6f1a7-69db-46b0-85aa-334284979346';
// export const mvapiUrl = 'https://mvapi.azurewebsites.net';

export const mvdapiprodClientId = '49dd79c5-077f-4fa1-a055-4752e20025bd';
// export const mvdapiprodUrl = 'https://mvd-api-westeurope.azurewebsites.net';

// default auths for guide & verify are empty and are populated from config.json during app initialization (see sagas > initializeDeploymentAuth)
export const defaultAuths: string[] = [];




// *** OTHER GLOBALS ***

export const DEFAULT_APP_NAME = 'MVision Guide & Verify';
export const APP_NAME_SHORT = 'trainingplatform';
export const getAppName = () => DEFAULT_APP_NAME;

// *** CLIENT ID ***

/** Client ID is used to identify this client/session in API calls. */
let clientId: string;

/** (Re-)generates and sets a new client ID. */
export function generateNewClientId() {
    clientId = `${APP_NAME_SHORT}-${getFilenameSafeTimestamp()}-${newGuid()}`.substring(0, 64);
    console.info(`Client ID: ${clientId}`);
}

/** Returns the current client ID. */
export function getClientId(): string {
    if (!clientId) {
        generateNewClientId();
    }
    
    return clientId;
}
