import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

import ModalDialog from '../../common/ModalDialog';

import './BlockingProgressDialog.css';

type OwnProps = {
    show: boolean,
    title: string,
    text?: string,
    screenReaderProgress?: string,
}

class BlockingProgressDialog extends React.Component<OwnProps> {
    render() {
        const { show, title, text, screenReaderProgress } = this.props;

        return (
            <ModalDialog show={show} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>
                        <div className="blocking-progress-dialog">
                            <div>
                                <Spinner animation="border" role="status" className="spinner">
                                    <span className="sr-only">{screenReaderProgress || 'Please wait for current process to finish'}</span>
                                </Spinner>
                            </div>
                            <div>{title}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{text}</div>
                </Modal.Body>
            </ModalDialog>
        );
    }
}

export default BlockingProgressDialog;
