import { MouseToolBase } from "./mouse-tool-base";
import { View } from "../view";

export default class SelectTool extends MouseToolBase {

    /** True if SelectTool is currently selecting something and waiting for the selection
     * to finalize, false otherwise. */
    private isSelecting: boolean;

    constructor() {
        super();
        this.isSelecting = false;
    }

    handleMouseDown(): void {
        // start selection, but wait until mouseup event to finalize
        this.isSelecting = true;
    }

    handleDrag(): void {
        // cancel selection if the mouse was dragged while a button was down
        this.isSelecting = false;
    }

    handleMouseUp(view: View, mousePoint: number[]): void {
        const vm = view.viewManager;
        const vs = vm.viewerState;

        // don't allow selection while doing visual comparisons
        if (!vs.isComparisonStructureSetSelected()) {

            // only allow select to happen if we didn't drag the mouse during selection
            if (this.isSelecting) {
                const vm = view.viewManager;
                const vs = vm.viewerState;

                vs.setSelectedRoi(vs.hoveredRoi);
                vs.setHoveredRoi(null);
            }
        }

        this.isSelecting = false;
    }

    handleHover(view: View, mousePointMm: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        let ss = vs.selectedStructureSet;
        if (!ss) { return; }
        let roiOrNull = vs.findPointedRoi(mousePointMm);
        vs.setHoveredRoi(roiOrNull);
    }


    handleMouseLeave(view: View): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;

        vs.setHoveredRoi(null);
    }
}
