import React from 'react';
import { Button, Dropdown, Form, Badge } from 'react-bootstrap';

import { MetricLabels, Metric, AllMetricsInOrder, MeasurementType, MeasurementTypeLabels, AllMeasurementTypesInOrder, MeasurementTypeExportLabels } from '../../web-apis/similarity-metrics';

import './SimilarityMetricsSelector.css';

type SimilarityMetricsSelectorProps = {
    visibleMetrics: { [m in Metric]: boolean },
    visibleMeasurementTypes?: { [m in MeasurementType]: boolean },
    onMeasurementTypeVisibilityToggled?: (measurementType: MeasurementType) => void,
    onMetricVisibilityToggled: (metric: Metric) => void,
    embed?: boolean,
    // Flag to determine if measurement types should be shown
    showMeasurementTypes?: boolean,
}

type SimilarityMetricsSelectorState = {
    /** Whether the selector dropdown is open or not ("shown") */
    isOpen: boolean,
}

class SimilarityMetricsSelector extends React.Component<SimilarityMetricsSelectorProps, SimilarityMetricsSelectorState> {

    constructor(props: SimilarityMetricsSelectorProps) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    handleMetricsDropdownToggle = (
        isOpen: boolean,
        event: React.SyntheticEvent<Dropdown<"div">, Event>,
        metadata: { source: "select" | "click" | "rootClose" | "keydown"; }
    ) => {
        // don't close dropdown when user clicks on items on it (only allow it to close
        // when user clicks outside of the component), otherwise allow default behaviour
        if (!isOpen && (metadata.source === "select" || metadata.source === "keydown")) {
            return;
        }

        this.setState({ isOpen: isOpen });
    }

    handleToggleMetricVisibility = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>, metric: Metric) => {
        evt.nativeEvent.preventDefault();
        this.props.onMetricVisibilityToggled(metric);
    }

    handleToggleMeasurementTypeVisibility = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>, measurementType: MeasurementType) => {
        evt.nativeEvent.preventDefault();
        if (this.props.onMeasurementTypeVisibilityToggled) {
        this.props.onMeasurementTypeVisibilityToggled(measurementType);
        }
    }

    render() {
        const { visibleMetrics, visibleMeasurementTypes, embed, showMeasurementTypes = true } = this.props;

        const currentVisibleMetricsCount = Object.values(visibleMetrics).filter(v => v === true).length;
        const currentVisibleMeasurementTypesCount = showMeasurementTypes && visibleMeasurementTypes ? Object.values(visibleMeasurementTypes).filter(v => v === true).length : 0;
        const totalVisibleCount = currentVisibleMetricsCount + currentVisibleMeasurementTypesCount;

        return (
            <div className={`metrics-selector ${embed ? 'embedded' : ''}`}>
                <Dropdown show={this.state.isOpen} onToggle={this.handleMetricsDropdownToggle}>
                    <Dropdown.Toggle as={Button} id="metrics-selector-toggle-button">
                        <span className="metrics-selector-label">
                            <span className="metrics-selector-text">View Metrics</span>
                            <span className="metrics-selector-badge"><Badge variant="light">{totalVisibleCount}</Badge></span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* Metrics Section */}
                        {AllMetricsInOrder.map(m => (
                            <Dropdown.Item
                                key={m}
                                eventKey={m}
                                onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMetricVisibility(evt, m)}
                                className="metrics-selector-item"
                            >
                                <Form.Check
                                    key={Math.random()}
                                    type="checkbox"
                                    label={MetricLabels[m]}
                                    defaultChecked={visibleMetrics[m]}
                                    onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMetricVisibility(evt, m)}
                                    onChange={() => { /* already handled in onClick */ }}
                                />
                            </Dropdown.Item>
                        ))}

                        {/* Divider and Measurement Types Section (conditionally rendered) */}
                        {showMeasurementTypes && visibleMeasurementTypes && (
                            <>
                                <Dropdown.Divider />
                                <Dropdown.Header style={{ color: 'gray' }}>Measurements</Dropdown.Header>
                                {AllMeasurementTypesInOrder.map(m => (
                                    <Dropdown.Item
                                        key={m}
                                        eventKey={m}
                                        onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMeasurementTypeVisibility(evt, m)}
                                        className="metrics-selector-item"
                                    >
                                        <Form.Check
                                            key={Math.random()}
                                            type="checkbox"
                                            label={MeasurementTypeExportLabels[m]}
                                            defaultChecked={visibleMeasurementTypes[m]}
                                            onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMeasurementTypeVisibility(evt, m)}
                                            onChange={() => { /* already handled in onClick */ }}
                                        />
                                    </Dropdown.Item>
                                ))}
                            </>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div >
        );
    }
}

export default SimilarityMetricsSelector;