import React from 'react';
import { Row, Button, Modal, Spinner, Table, ButtonGroup, Dropdown, SplitButton, Tooltip, OverlayTrigger, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as sagas from '../../../store/sagas';
import ModalDialog from '../../common/ModalDialog'
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import * as structureSet from '../../../dicom/structure-set';
import { StoreState } from '../../../store/store';
import './DifferencesToolbar.css';
import { rtViewerApiClient, RoiNameMap } from '../../../web-apis/rtviewer-api-client';
import { User } from '../../../store/user';
import { SessionNotification, NotificationType, DEFAULT_SESSION_TIMEOUT_IN_MS } from '../../common/models/SessionNotification';
import { SimilarityMetrics, MetricLabels, Metric, AllMetricsInOrder, getSimilarityMetricsValue, AllMeasurementTypesInOrder, MeasurementType, getSimilarityMetricsValueForMeasurementType, MeasurementTypeLabels, MeasurementTypeExportLabels } from '../../../web-apis/similarity-metrics';
import ComparisonSelector from './ComparisonSelector';
import { TrainingTask, TrainingTaskState, handleTaskState, getDefaultVisibleMetrics, getDefaultVisibilityForMeasurements } from '../../../datasets/training-task';
import { saveFileOnUserDevice, sleep, getFilenameSafeTimestamp } from '../../../util';
import TaskGradeModal from '../TaskGradeModal';
import { DicomMapAnonReal, anonymizeDicomDataset } from '../../../dicom/image_anonymization';
import _ from 'lodash';
import { MdCheckCircle, MdWarning } from 'react-icons/md';
import RunCalculationModal from '../dialogs/RunCalculationModal';
import { Image } from '../../../dicom/image';
import SimilarityMetricsSelector from '../../common/SimilarityMetricsSelector';
import { LocalAliasMap } from '../../../store/alias-map';
import BlockingProgressDialog from '../dialogs/BlockingProgressDialog';

// Generate file name for exporting similarity metrics to CSV
function getSimilarityMetricsFilename(patientId: string | undefined, traineeName?: string): string {
    const patientIdPart = patientId ? `_${patientId}` : '';
    const traineeNamePart = traineeName ? `_${traineeName}` : '';

    return `similarity_metrics${patientIdPart}${traineeNamePart}_${getFilenameSafeTimestamp()}.csv`;
}

type OwnProps = {
    viewerState: ViewerState,
    onSaveAll: () => Promise<void>,
    structureSets: structureSet.StructureSet[],
    createNewStructureSet: (isComparison?: boolean) => void,
}

type DispatchProps = {
    startTask: (task: TrainingTask) => void,
    finishTask: (task: TrainingTask, image: Image, roiNameMaps: RoiNameMap[]) => void,
    startUpdatingTask: () => void,
    finishUpdatingTask: () => void,
    canUserFinishTask: (task: TrainingTask, user: User) => Promise<[boolean, string | undefined]>,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
    dismissNotification: (notificationId: string) => void,
    updateTask: (task: TrainingTask, update: Partial<TrainingTask>) => void,
    setSimilarityMetrics: (similarityMetrics: SimilarityMetrics[]) => void,
    clearSimilarityMetrics: () => void,
}

interface AcceptanceCriteria {
    dice: number | null;
    sdice1: number | null;
    sdice2: number | null;
    sdice3: number | null;
    sdice5: number | null;
    hd: number | null;
    hd95: number | null;
    mean_hd: number | null;
    average_apl_mm: number | null;
    total_apl_mm: number | null;
    user_vol_mm3: number | null;
    gt_vol_mm3: number | null;
}

export enum DifferencesMenu {
    Contours,
    Calculate
}

enum TaskActionType { Start, Finish, Resume, GradeTask }

enum TaskUpdateType {
    /** Default type for task updates */
    Updating,
    /** Task is being started */
    Starting,
    /** Task is being finished */
    Finishing,
    /** Task is being resumed */
    Resuming,
    /** Task is being graded */
    Grading,
}

const getTaskUpdateProgressTitle = (taskUpdateType: TaskUpdateType): string => {
    switch (taskUpdateType) {
        case TaskUpdateType.Starting:
            return 'Starting Task';
        case TaskUpdateType.Finishing:
            return 'Finishing Task';
        case TaskUpdateType.Resuming:
            return 'Resuming Task';
        case TaskUpdateType.Grading:
            return 'Grading Task';
        default:
            return 'Updating Task';
    }
}

const getTaskUpdateProgressText = (taskUpdateType: TaskUpdateType): string => {
    switch (taskUpdateType) {
        case TaskUpdateType.Starting:
            return 'Please wait while task is being started.';
        case TaskUpdateType.Finishing:
            return 'Please wait while task is being finished.';
        case TaskUpdateType.Resuming:
            return 'Please wait while task is being resumed.';
        case TaskUpdateType.Grading:
            return 'Please wait while task is being graded.';
        default:
            return 'Please wait while task is being updated.';
    }
}

type OwnState = {
    showCalculationResultsModal: boolean;
    differenceIsLoading: boolean;
    calcDiceIsLoading: boolean;
    showRunMetricsCalculationModal: boolean;
    saveTaskIsLoading: boolean;
    showTaskGradeModal: boolean;
    /** Similarity metrics that have been toggled as visible (true) or invisible (false)) in the UI. */
    visibleMetrics: { [m in Metric]: boolean };
    visibleMeasurementTypes: { [c in MeasurementType]: boolean };
    taskUpdateType: TaskUpdateType;
}

type AllProps = OwnProps & StoreState & DispatchProps;

// Calculated Results 
const getFixedUserVolMm3 = (userVolCm3: number) => (userVolCm3 / 1000).toFixed(2);
const getFixedGtVolMm3 = (gtVolCm3: number) => (gtVolCm3 / 1000).toFixed(2);
const getFixedVolDiff = (userVolCm3: number, gtVolCm3: number) => (100 * (userVolCm3 / gtVolCm3 - 1)).toFixed(1);

/** Returns the supplied value as a number-formatted string matching the supplied metric.  */
const getFixedMetricValueAsString = (metric: Metric, value: number | null) => {
    if (value === null) {
        return '';
    }

    switch (metric) {
        case Metric.Dice:
        case Metric.SDice1:
        case Metric.SDice2:
        case Metric.SDice3:
        case Metric.SDice5:
            return value.toFixed(2);
        case Metric.HD:
        case Metric.HD95:
        case Metric.MeanHD:
        case Metric.AverageAplMm:
        case Metric.TotalAplMm:
            return value.toFixed(1);
        default:
            throw new Error(`Unsupported metric: ${metric}`);
    }
};

/** Returns the supplied value as a number-formatted string matching the supplied measurement.  */
const getFixedMeasurementValueAsString = (measurementType: MeasurementType, value: number | null) => {
    if (value === null) {
        return '';
    }
    switch (measurementType) {
        case MeasurementType.TestVolume:
            return value.toFixed(2);
        case MeasurementType.ReferenceVolume:
            return value.toFixed(2);
        case MeasurementType.VolumeDifference:
            return value.toFixed(1);
        case MeasurementType.TestZLength:
            return value.toFixed(1);
        case MeasurementType.ReferenceZLength:
            return value.toFixed(1);
        default:
            throw new Error(`Unsupported measurement type: ${measurementType}`);
    }
};


class DifferencesToolbar extends React.Component<AllProps, OwnState> {
    displayName = DifferencesToolbar.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
            showCalculationResultsModal: false,
            calcDiceIsLoading: false,
            differenceIsLoading: false,
            saveTaskIsLoading: false,
            showTaskGradeModal: false,
            showRunMetricsCalculationModal: false,
            // Default visibilities for similarity metrics in UI.
            visibleMetrics: getDefaultVisibleMetrics(this.props.currentTask),
            visibleMeasurementTypes: getDefaultVisibilityForMeasurements(),
            taskUpdateType: TaskUpdateType.Updating,
        };
    }

    componentDidMount = () => {
        // create a transient structure set for ROI comparisons when this component is mounted
        this.props.createNewStructureSet(true);
    }

    componentDidUpdate = (prevProps: AllProps) => {
        if (prevProps.isUpdatingTask && !this.props.isUpdatingTask) {
            // any pending task updates have finished, reset taskUpdateType to default
            this.setState({ taskUpdateType: TaskUpdateType.Updating });
        }
    }

    handleChangeTaskState = async (action: TaskActionType) => {
        const { testStructureSet, referenceStructureSet, structureSets } = this.props;
        const task = this.props.currentTask;
        const vs = this.props.viewerState;
        const image = vs.image;


        if (!testStructureSet) { throw new Error('No test structure set -- cannot calculate comparison metrics.'); }
        if (!referenceStructureSet) { throw new Error('No reference structure set -- cannot calculate comparison metrics.'); }

        if (!task) {
            throw new Error('No valid task');
        }
        // First, check if the user is allowed to start or resume this task, or is allowed to finish it.
        const isStartingTask = action === TaskActionType.Start && task.state === TrainingTaskState.NotStarted;
        const isWaitingForGrading = action === TaskActionType.GradeTask && task.state === TrainingTaskState.Finished;
        const isResumingTask = task.state === TrainingTaskState.Finished || task.state === TrainingTaskState.Graded;
        const [canUserFinishTask, errorMessage] = await this.props.canUserFinishTask(task, this.props.user!);

        // If the task is of type "TEST", the confirm message should reflect that it cannot be restarted.
        let confirmMessage;
        if (isResumingTask) {
            confirmMessage = 'Do you want to restart this task for further adjustments?';
        }
        if (!isStartingTask && !isWaitingForGrading && isResumingTask && task.type === 'TEST') {
            confirmMessage = 'Do you want to restart this task for further adjustments?';
        } else if (isWaitingForGrading) {
            // Add a separate message for when the task is about to be graded.
            confirmMessage = 'Do you want to grade this task?';
        } else if (!isStartingTask && !isResumingTask && task.type && task.state !== TrainingTaskState.Finished) {
            confirmMessage = 'Do you want to save and finish this task? You can no longer adjust it once it\'s been finished.';
        }


        if (isStartingTask) {
            this.setState({ taskUpdateType: TaskUpdateType.Starting });
            this.props.startTask(task);
            vs.canEdit = true;
            this.props.clearSimilarityMetrics();
        }
        else if (isWaitingForGrading) {
            this.setState({ showTaskGradeModal: true });
        }
        else if (isResumingTask) {
            
            // filter out expert contours and comparison structure set if found. select trainee structure set
            const expertContoursIndex = vs.visibleStructureSets.findIndex(ss => ss.getLabel() === structureSet.ExpertContoursName);
            if (expertContoursIndex >= 0) {
                vs.visibleStructureSets.splice(expertContoursIndex, 1);
            }
            const comparisonRoiIndex = vs.visibleStructureSets.findIndex(ss => ss.getLabel() === structureSet.ComparisonStructureSetName);
            if (comparisonRoiIndex >= 0) {
                vs.visibleStructureSets.splice(comparisonRoiIndex, 1);
            }
            const traineeStructureSet = structureSets.find(ss => ss.structureSetId === task.traineeStructureSet.sopInstanceUid);
            if (traineeStructureSet) {
                vs.setSelectedStructureSet(traineeStructureSet, vs.image);
            } else {
                throw new Error(`Could not find trainee structure set ${task.traineeStructureSet.sopInstanceUid}!`);
            }
            
            
            if (window.confirm(confirmMessage)) {
                this.setState({ taskUpdateType: TaskUpdateType.Resuming });

                // restart the task
                if (task.type === 'TEST') {
                    this.props.updateTask(task, { state: TrainingTaskState.Started, grade: "UNGRADED" });
                } else {
                    this.props.startTask(task);
                }
                if (this.props.user && this.props.user.userId === task.trainee.user_id) {
                    vs.canEdit = true;
                }
                this.props.clearSimilarityMetrics();
                this.props.addNotification(new SessionNotification(`task-${task.id}-state-changed-${Date.now()}`, 'Task Resumed', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS));
            }
        }
        else if (canUserFinishTask && window.confirm(confirmMessage)) {
            this.setState({ taskUpdateType: TaskUpdateType.Finishing });
            // if task is Not Started, start it
            if (task.state === TrainingTaskState.NotStarted) {
                this.props.startTask(task);
            }

            // collect ROI names that we want to calculate metrics for
            const taskTraineeRoiNames = task ? task.traineeStructureSet.rois.map(r => r.roiName) : [];
            const referenceRoiNames = referenceStructureSet.getRois().map(r => r.name);
            const testRoisToCalculateMetricsFor = task ? testStructureSet.getRois().filter(r => taskTraineeRoiNames.includes(r.name) && referenceRoiNames.includes(r.name))
                : testStructureSet.getRois().filter(r => referenceRoiNames.includes(r.name));
            const roinames = testRoisToCalculateMetricsFor.map(r => r.name);

            try {
                // task update starts here
                this.props.startUpdatingTask();

                await sleep(500); // temporal: make UI a bit smoother when loading

                // save contouring changes first
                vs.canEdit = false;
                await this.props.onSaveAll();

                // wait a couple of seconds after saving rtstruct for the backend to catch up with its own I/O operations
                await sleep(5000);

                // set current task state to finished using api
                this.props.finishTask(task, image, roinames.map(r => ({ testRoiName: r, referenceRoiName: r })));
                this.props.clearSimilarityMetrics();


                // HACK: currently 'canEdit' is being read from too many places to adjust elegantly just from react code
                // hence we're here manually setting vs.canEdit to FALSE in some specific circumstances, even though
                // that should technically be read from the combination of the state of current task and from the 
                // permissions of the current user. The correct state of the task will be initialized the next time
                // the app is reloaded.
                if (this.props.user && this.props.user.permissions.isTrainee && !this.props.user.permissions.isSupervisor) {
                    // note -- doesn't really matter if the update task API call above is successful or not, because the
                    // task should be FINISHED at this point anyway -- so just set canEdit to false in any case
                    vs.canEdit = false;
                }
            } catch (error) {
                this.props.finishUpdatingTask();
                console.error(error);
                throw new Error('Finishing task failed.');
            }
        }
        else {
            // also give the user an error message if one was received
            if (!canUserFinishTask && !isResumingTask) {
                this.props.addNotification(new SessionNotification(`finish-task-${task.id}-failed-${Date.now()}`, 'Task could not be finished.', NotificationType.Error, errorMessage));
            }
        }
    }

    handleCloseCalculationResultsModal = () => {
        this.setState({ showCalculationResultsModal: false });
    }

    onShowDifferencesClick = async () => {
        const { testStructureSet, testRoi, referenceStructureSet, referenceRoi, viewerState } = this.props;
        if (!testStructureSet || !testRoi || !referenceStructureSet || !referenceRoi) {
            throw new Error('Two comparison structures must be selected to perform visual comparison');
        }

        this.setState({ differenceIsLoading: true });

        const comparisonRois = await viewerState.createComparisonRois(testRoi, referenceRoi);
        if (comparisonRois) {
            viewerState.compareROIs(comparisonRois);
            viewerState.focusOnStructure(comparisonRois.test, testStructureSet)
        }

        this.setState({ differenceIsLoading: false })
    }

    handleIsLoading = (calcDiceIsLoading: boolean) => {
        this.setState({ calcDiceIsLoading: calcDiceIsLoading });
    }

    handleShowRunMetricsCalculationModal = (showModal: boolean) => {
        this.setState({ showRunMetricsCalculationModal: showModal });
        if (!showModal) {
            this.handleIsLoading(false);
        }
    }

    handleRecalculateMetricsClicked = () => {
        this.handleCloseCalculationResultsModal();
        this.props.clearSimilarityMetrics();
        this.setState({ showCalculationResultsModal: false });
        this.handleShowRunMetricsCalculationModal(true);
    }

    handleCalculateMetrics = async (aliasMap: LocalAliasMap) => {
        const { viewerState, currentTask, testStructureSet, referenceStructureSet } = this.props;
        const { image } = viewerState;

        if (!testStructureSet) {
            this.addMetricNotification('No test structure set -- cannot calculate comparison metrics.', NotificationType.Error);
            return;
        }
        if (!referenceStructureSet) {
            this.addMetricNotification('No reference structure set -- cannot calculate comparison metrics.', NotificationType.Error);
            return;
        }

        this.props.clearSimilarityMetrics();
        this.setState({ calcDiceIsLoading: true });

        this.handleShowRunMetricsCalculationModal(false);
        this.handleIsLoading(true);

        // apply alias map
        const roiNameMap: { testRoiName: string, referenceRoiName: string }[] = [];
        for (const roiName of Object.keys(aliasMap)) {
            const alias = aliasMap[roiName];
            if (alias !== undefined) {
                roiNameMap.push({ testRoiName: roiName, referenceRoiName: alias });
            }
        }

        if (!currentTask && roiNameMap.length === 0) {
            this.addMetricNotification(
                'There are no structures with matching names between the test and the reference structure sets. Structure set similarity cannot be calculated.',
                NotificationType.Warning
            );
            this.setState({ showCalculationResultsModal: false, calcDiceIsLoading: false });
            return;
        }

        await sleep(500); // temporal: make UI a bit smoother when loading

        // anonmap isn't actually used or retained, we just need it for the call to anonymizeSlice
        const anonMap = new DicomMapAnonReal();

        // perform anonymization in a post-op in structureSet.structureSetToDicom
        const postOperations = (dataset: any) => anonymizeDicomDataset(dataset, anonMap, true);

        const testBlob = new Blob([structureSet.structureSetToDicom(testStructureSet, image, postOperations)]);
        const referenceBlob = new Blob([structureSet.structureSetToDicom(referenceStructureSet, image, postOperations)]);

        // Call backend to get the calculation data
        try {
            const metrics = await rtViewerApiClient.computeMetrics(testBlob, referenceBlob, image, roiNameMap);
            // set the state for the modal to show the dice calculation result
            this.setState({
                showCalculationResultsModal: true,
                calcDiceIsLoading: false,
            });
            this.props.setSimilarityMetrics(metrics);
        }
        catch (err) {
            console.error(err);
            const genericErrorMessage = 'Could not calculate metrics. Please try excluding some large structures from calculation and try again.';
            const detailedMessage = _.get(err, 'message', undefined);

            const fullErrorMessage = (
                <div className="formatted-error-message">
                    <div>{genericErrorMessage}</div>
                    {detailedMessage && (<div><span>Error details: </span><span>{detailedMessage}</span></div>)}
                </div>
            );

            this.addMetricNotification('Could not calculate metrics.', NotificationType.Error, fullErrorMessage);
            this.setState({
                showCalculationResultsModal: false,
                calcDiceIsLoading: false,
            });
        }
    }


    private addMetricNotification = (message: string, type: NotificationType, detailedMessage?: string | React.ReactNode) => {
        const id = `metrics-calculation-${Date.now().toString()}`;
        this.props.addNotification(new SessionNotification(id, message, type, detailedMessage));
    }

    handleExportMetrics = async () => {
        const { currentTask, structureSets, similarityMetrics } = this.props;

        if (similarityMetrics === undefined) {
            throw new Error('Similarity metrics have not been calculated properly -- cannot export');
        }

        const infoHeaderRow = [
            "PatientID",
            "SeriesInstanceUID",
        ];

        if (this.props.currentTask) {
            infoHeaderRow.push("Task Name");
            infoHeaderRow.push("Trainee Name");
        }

        const infoRows = structureSets
            ? [
                [
                    structureSets && structureSets[0].patientId,
                    structureSets && structureSets[0].imageSeriesInstanceUid,
                ],
            ]
            : [];

        if (this.props.currentTask) {
            infoRows[0].push(this.props.currentTask.name);
            infoRows[0].push(this.props.currentTask.trainee.user_name);
        }

        // deduce whether columns for both test and reference ROI names should be shown. They are not shown if all ROI names are identical or
        // if we're in Guide
        const showBothRoiNames = !this.props.currentTask && similarityMetrics.length > 0 && similarityMetrics.some(m => m.gt_roi_name !== m.user_roi_name);

        // Structure section headers
        const roiHeaderRow = (showBothRoiNames ? ["Test Structure", "Reference Structure"] : ["Structure"])

        // push all similarity metric labels into header row
        AllMeasurementTypesInOrder.forEach(c => roiHeaderRow.push(MeasurementTypeExportLabels[c]));
        // push all similarity metric labels into header row
        AllMetricsInOrder.forEach(m => roiHeaderRow.push(MetricLabels[m]));

        const includedTaskMetrics = AllMetricsInOrder.filter(m => currentTask ?
            currentTask.gtStructureSet.rois.some(r => getSimilarityMetricsValue(m, r.acceptanceCriteria) !== null)
            : false);

        // add acceptance criteria headers items for each used similarity metric in current task (if any)
        includedTaskMetrics.forEach(m => roiHeaderRow.push(`Acceptance Criteria for ${MetricLabels[m]}`));

        const dataRows = similarityMetrics.map(metric => {
            const {
                user_roi_name,
                user_vol_mm3,
                gt_roi_name,
                gt_vol_mm3,
                user_z_length_mm,
                gt_z_length_mm,
            } = metric;

            const acceptanceCriteria: any = [];
            const matchingRoi = currentTask ? currentTask.gtStructureSet.rois.find(r => r.roiName === user_roi_name) : undefined;
            if (matchingRoi) {
                includedTaskMetrics.forEach(m => {
                    const value = getSimilarityMetricsValue(m, matchingRoi.acceptanceCriteria);
                    // Use the same formatting function as the table for acceptance criteria
                    acceptanceCriteria.push(value !== null ? getFixedMetricValueAsString(m, value) : '');
                });
            }

            // collect data using the same formatting as the table
            const metricsData = AllMetricsInOrder.map(m => {
                const metricsValue = getSimilarityMetricsValue(m, metric);
                return metricsValue !== null ? getFixedMetricValueAsString(m, metricsValue) : '';
            });

            // Use the same formatting functions as the table display
            return (showBothRoiNames ? [user_roi_name, gt_roi_name] : [user_roi_name]).concat([
                getFixedUserVolMm3(user_vol_mm3),
                getFixedGtVolMm3(gt_vol_mm3),
                getFixedVolDiff(user_vol_mm3, gt_vol_mm3),
                user_z_length_mm,
                gt_z_length_mm,
                ...metricsData,
                ...acceptanceCriteria,
            ]);
        });

        // Combine task headers, rows, and add spacing
        const infoSection = [infoHeaderRow, ...infoRows]
            .map(row => row.join(","))
            .join("\n");

        // Combine structure header and rows, ensuring the header repeats after the task data
        const roiSection = [roiHeaderRow, ...dataRows]
            .map(row => row.join(","))
            .join("\n");

        // Final CSV content with a blank line between task data and structure data
        const csvContent = `${infoSection}\n\n${roiSection}`;

        // Create a blob and trigger download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const fileName = getSimilarityMetricsFilename(structureSets && structureSets[0].patientId, currentTask && currentTask.trainee.user_name);
        saveFileOnUserDevice(fileName, blob);
    };


    handleTaskStateChange = async (eventKey: string) => {
        const { currentTask } = this.props;
        if (!currentTask) {
            throw new Error('No task -- cannot change task state');
        }
        try {
            if (eventKey === 'start' && currentTask.state !== TrainingTaskState.Started) {
                await this.handleChangeTaskState(TaskActionType.Start)
            } else if (eventKey === 'finish' && currentTask.state !== TrainingTaskState.Finished) {
                await this.handleChangeTaskState(TaskActionType.Finish)
            } else if (eventKey === 'grade') {
                this.setState({ showTaskGradeModal: true });
            }
        } catch (error) {
            console.error(error);
            this.props.addNotification(new SessionNotification(`task-${currentTask.id}-state-changed-${Date.now()}`, `Error changing task state: ${error.message}`, NotificationType.Error));
        }
    };

    handleShowMetrics = () => {
        if (this.props.currentTask) {
            // fill an array with the similarity metrics from each roi in the trainee structure set
            const metrics: SimilarityMetrics[] = [];

            this.props.currentTask.traineeStructureSet.rois.forEach(roi => {
                if (roi.similarityMetrics) {
                    metrics.push({ user_roi_name: roi.roiName, ...roi.similarityMetrics });
                }
            });
            this.setState({ showCalculationResultsModal: true, visibleMetrics: getDefaultVisibleMetrics(this.props.currentTask) });
            this.props.setSimilarityMetrics(metrics);
        }
        else {
            if (this.props.similarityMetrics === undefined) {
                this.setState({ showCalculationResultsModal: false });
                this.handleShowRunMetricsCalculationModal(true);
            } else {
                this.setState({ showCalculationResultsModal: true });
            }
        }
    }

    handleToggleMetricVisibility = (metric: Metric) => {
        const visibleMetrics = this.state.visibleMetrics;
        const newVisibleMetrics = Object.assign({}, visibleMetrics, { [metric]: !visibleMetrics[metric] });
        this.setState({ visibleMetrics: newVisibleMetrics });
    }

    handleToggleMeasurementTypeVisibility = (measurementType: MeasurementType) => {
        const visibleMeasurementTypes = this.state.visibleMeasurementTypes;
        const newVisibleMeasurementTypes = Object.assign({}, visibleMeasurementTypes, { [measurementType]: !visibleMeasurementTypes[measurementType] });
        this.setState({ visibleMeasurementTypes: newVisibleMeasurementTypes });
    }

    handleGradeUpdate = (grade: string, comments: string): boolean => {
        if (this.props.currentTask && grade) {
            this.setState({ taskUpdateType: TaskUpdateType.Grading });

            this.props.updateTask(this.props.currentTask, { grade: grade, state: TrainingTaskState.Graded, comments: comments });
            return true;
        }

        return false;
    }

    isChangeTaskStateButtonDisabled = (): boolean => {
        const { structureSetLocks, currentTask, user: userAny } = this.props;
        const user: User | undefined = userAny;

        if (this.props.isUpdatingTask) {
            return true;
        }

        if (user && user.permissions.isSupervisor) {
            return false;
        }

        // task state can be changed by supervisors or this task's trainee
        if (!currentTask || !user || (!user.permissions.isSupervisor && !user.isTaskTrainee(currentTask))) {
            return true;
        }

        // user must have lock to current task's trainee structure set in order to change task state
        const taskStructureSetId = currentTask.traineeStructureSet.sopInstanceUid;
        const canChangeTaskState = !!taskStructureSetId && !!structureSetLocks &&
            !!structureSetLocks[taskStructureSetId] && user.hasRTStructLock(structureSetLocks[taskStructureSetId]!);
        if (!canChangeTaskState) {
            return true;
        }

        // task must be in a state where it can be changed
        // (supervisors can adjust the state further in another view)
        if (currentTask.type === 'PRACTICE' && (currentTask.state === TrainingTaskState.NotStarted || currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Started)) {
            return false;
        } else if (currentTask.type === 'TEST' && (currentTask.state === TrainingTaskState.NotStarted || currentTask.state === TrainingTaskState.Started)) {
            return false;
        }

        return true;
    }

    getTaskActionType = (currentTask: TrainingTask): TaskActionType => {
        if (currentTask.state === TrainingTaskState.NotStarted) {
            return TaskActionType.Start;
        } else if (
            currentTask.state !== TrainingTaskState.Finished &&
            currentTask.state !== TrainingTaskState.Graded
        ) {
            return TaskActionType.Finish;
        } else if (currentTask.state === TrainingTaskState.Finished && this.props.user && this.props.user.permissions.isSupervisor) {
            return TaskActionType.GradeTask;
        } else {
            return TaskActionType.Resume;
        }
    };

    getTaskButtonText = (currentTask: TrainingTask): string => {
        const actionType = this.getTaskActionType(currentTask);
        switch (actionType) {
            case TaskActionType.Start:
                return 'Start task';
            case TaskActionType.Finish:
                return 'Finish task';
            case TaskActionType.Resume:
                return 'Resume task';
            case TaskActionType.GradeTask:
                return 'Apply Grade...';
            default:
                return '';
        }
    };

    getIsTaskFinished = (): boolean => {
        const { currentTask, testStructureSet, referenceStructureSet } = this.props;
        if (!currentTask) {
            if (!testStructureSet || !referenceStructureSet || testStructureSet === referenceStructureSet) {
                return true;
            }
            return false;
        } else if (currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Graded) {
            return false;
        }
        return true;
    }

    getAcceptanceCriteria = (metric: SimilarityMetrics) => {
        const { currentTask } = this.props;
        let acceptanceCriteria: any = {};

        if (currentTask) {
            const gtRoi = currentTask.gtStructureSet.rois.find((roi) => roi.roiName === metric.user_roi_name);
            if (gtRoi) {
                acceptanceCriteria = gtRoi;
            }
        }
        return acceptanceCriteria;
    };


    renderIcon = (ac: AcceptanceCriteria, metric: Metric, value: number | null): JSX.Element | null => {
        const getIcon = (targetCriteria: number | null, checkFunc: (a: number, b: number) => boolean): JSX.Element | null => {
            // TODO: are there some metric types where we don't want to display "0"?
            if (targetCriteria === null || value === null/* || (metric === Metric.SDice2 && acProp === 0)*/) {
                return null;
            }
            const checkResult = checkFunc(value, targetCriteria);
            return checkResult ? (
                <OverlayTrigger placement="bottom"
                    overlay={(<Tooltip id={'acprop'}>Target criteria: {targetCriteria}</Tooltip>)}
                    delay={100}>
                    <MdCheckCircle size={20} color='#35dc71' style={{ marginLeft: '3px', marginBottom: '2px' }} />
                </OverlayTrigger>
            ) : (
                    <OverlayTrigger placement="bottom"
                        overlay={(<Tooltip id={'acprop'}>Target criteria: {targetCriteria}</Tooltip>)}
                        delay={100}>
                        <MdWarning size={20} color='#dc3545' style={{ marginLeft: '3px', marginBottom: '2px' }} />
                    </OverlayTrigger>
                );
        };

        switch (metric) {
            case Metric.Dice:
                return getIcon(ac.dice, (a, b) => a >= b);
            case Metric.SDice1:
                return getIcon(ac.sdice1, (a, b) => a >= b);
            case Metric.SDice2:
                return getIcon(ac.sdice2, (a, b) => a >= b);
            case Metric.SDice3:
                return getIcon(ac.sdice3, (a, b) => a >= b);
            case Metric.SDice5:
                return getIcon(ac.sdice5, (a, b) => a >= b);
            case Metric.HD:
                return getIcon(ac.hd, (a, b) => a <= b);
            case Metric.HD95:
                return getIcon(ac.hd95, (a, b) => a <= b);
            case Metric.MeanHD:
                return getIcon(ac.mean_hd, (a, b) => a <= b);
            case Metric.AverageAplMm:
                return getIcon(ac.average_apl_mm, (a, b) => a <= b);
            case Metric.TotalAplMm:
                return getIcon(ac.total_apl_mm, (a, b) => a <= b);
            default:
                return null;
        }
    };

    getMetricCell = (metric: Metric, metrics: SimilarityMetrics, acceptanceCriteria: any) => {
        let icon: JSX.Element | null = null;
        const value = getSimilarityMetricsValue(metric, metrics);

        if (acceptanceCriteria.acceptanceCriteria && this.props.currentTask) {
            icon = this.renderIcon(acceptanceCriteria.acceptanceCriteria, metric, value);
        }

        return (<span>{getFixedMetricValueAsString(metric, value)}{this.props.currentTask && icon}</span>);
    }

    getMeasurementTypeCell = (measurementType: MeasurementType, metrics: SimilarityMetrics) => {
        const value = getSimilarityMetricsValueForMeasurementType(measurementType, metrics);

        return (<span>{getFixedMeasurementValueAsString(measurementType, value)}</span>);
    }



    render() {
        const { testStructureSet, testRoi, referenceStructureSet, referenceRoi, currentTask, viewerState: vs, isUpdatingTask, similarityMetrics } = this.props;

        const isStructureSetComparisonDataAvailable = testStructureSet && referenceStructureSet && !isUpdatingTask;
        const isAllComparisonDataAvailable = isStructureSetComparisonDataAvailable && testRoi && referenceRoi;
        const isWaitingForGrading = this.props.user && this.props.user.permissions.isSupervisor && this.props.currentTask && this.props.user.userId === this.props.currentTask.supervisor.user_id && this.props.currentTask.state === TrainingTaskState.Finished;
        const isFinishingTaskDisabled = currentTask && (currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Graded);

        const currentVisibleMetrics = AllMetricsInOrder.filter(m => this.state.visibleMetrics[m] === true);
        const currentVisibleMeasurementTypes = AllMeasurementTypesInOrder.filter(m => this.state.visibleMeasurementTypes[m] === true);

        // deduce whether columns for both test and reference ROI names should be shown. They are not shown if all ROI names are identical or
        // if we're in Guide
        const showBothRoiNames = similarityMetrics !== undefined && !currentTask && similarityMetrics.length > 0 && similarityMetrics.some(m => m.gt_roi_name !== m.user_roi_name);

        return (
            <Row className='differences-toolbar'>

                <div className='row-span'>

                    <ComparisonSelector viewerState={vs} task={currentTask} structureSets={this.props.structureSets} />

                    <div className='tool-buttons'>
                        <div>
                            <Button variant="light" className="btn btn-default btn-sm right-margin" onClick={this.handleShowMetrics} disabled={this.state.calcDiceIsLoading || this.getIsTaskFinished()}>
                                Structure set similarity
                            </Button>
                        </div>
                        <div><Button variant="light" className="btn btn-default btn-sm right-margin"
                            onClick={this.onShowDifferencesClick}
                            disabled={!isAllComparisonDataAvailable ||
                                this.getIsTaskFinished() ||
                                (currentTask && testStructureSet === referenceStructureSet)}>
                            Visual comparison
                            {this.state.differenceIsLoading &&
                                <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            }
                        </Button>
                        </div>
                    </div>
                    <div className='float-right tool-buttons'>
                        <div>
                            {this.props.currentTask && (
                                this.props.currentTask.state === TrainingTaskState.Graded ? (
                                    `Task State: Graded (${this.props.currentTask.grade})`
                                ) : (
                                        `Task State: ${handleTaskState(this.props.currentTask.state)}`
                                    )
                            )}
                        </div>
                        <div>
                            {this.props.currentTask && (
                                <>
                                    {this.props.user && this.props.user.permissions.isSupervisor && currentTask ? (
                                        <>
                                            <SplitButton
                                                as={ButtonGroup}
                                                title={this.getTaskButtonText(currentTask)}
                                                size="sm"
                                                variant="light"
                                                drop="down"
                                                id="bg-vertical-dropdown-3"
                                                onSelect={this.handleTaskStateChange}
                                                onClick={() => this.handleChangeTaskState(this.getTaskActionType(currentTask))}
                                                disabled={this.isChangeTaskStateButtonDisabled()}>
                                                <Dropdown.Item eventKey="start" disabled={currentTask.state === TrainingTaskState.Started ? true : false} className={currentTask.state === TrainingTaskState.Started ? 'disabled-btn' : ''}>Set task to <b>"Started"</b></Dropdown.Item>
                                                <Dropdown.Item eventKey="finish" disabled={isFinishingTaskDisabled ? true : false} className={isFinishingTaskDisabled ? 'disabled-btn' : ''}>Set task to <b>"Finished"</b></Dropdown.Item>
                                                {currentTask && currentTask.state !== TrainingTaskState.Started && (
                                                    <>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item eventKey="grade" className={isWaitingForGrading ? "grade-ready" : ""}>Grade task...</Dropdown.Item>
                                                    </>
                                                )}
                                            </SplitButton>
                                            {isUpdatingTask && (
                                                <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            )}
                                        </>
                                    ) : (
                                            currentTask &&
                                            <Button variant="light" className="btn btn-default btn-sm right-margin btn-darkblue" onClick={() => this.handleChangeTaskState(this.getTaskActionType(currentTask))} disabled={this.isChangeTaskStateButtonDisabled()}>
                                                {this.getTaskButtonText(currentTask)}
                                                {isUpdatingTask && (
                                                    <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                )}
                                            </Button>
                                        )
                                    }
                                </>
                            )
                            }
                        </div>
                    </div>
                </div>


                {/* show the modal with the dice calculation result */}
                <ModalDialog show={this.state.showCalculationResultsModal} onHide={this.handleCloseCalculationResultsModal} className="modalTable" size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Calculation Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <SimilarityMetricsSelector
                            visibleMetrics={this.state.visibleMetrics}
                            onMetricVisibilityToggled={this.handleToggleMetricVisibility}
                            visibleMeasurementTypes={this.state.visibleMeasurementTypes}
                            onMeasurementTypeVisibilityToggled={this.handleToggleMeasurementTypeVisibility}
                        />

                        {similarityMetrics !== undefined && (
                            <div className="metrics-table">
                                <Table className="modalTable">
                                    <thead className="metrics-headers">
                                        <tr>
                                            {showBothRoiNames ? (
                                                <>
                                                    <th title="Test structure name">Test structure</th>
                                                    <th title="Reference structure name">Ref structure</th>
                                                </>
                                            ) : (
                                                    <th title="Structure name">Structure</th>
                                                )}
                                            {currentVisibleMeasurementTypes.map(c => (<th key={c} title={MeasurementTypeExportLabels[c]}>{MeasurementTypeLabels[c]}</th>))}
                                            {currentVisibleMetrics.map(m => (<th key={m}>{MetricLabels[m]}</th>))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {similarityMetrics.map((metric, index) => {
                                            const ac = this.getAcceptanceCriteria(metric);
                                            return (
                                                <tr key={metric.user_roi_name || index}>
                                                    {showBothRoiNames ? (
                                                        <>
                                                            <td className="info-cell">{metric.user_roi_name || `Structure ${index + 1}`}</td>
                                                            <td className="info-cell">{metric.gt_roi_name || `Structure ${index + 1}`}</td>
                                                        </>
                                                    )
                                                        : (
                                                            <td className="info-cell">{metric.user_roi_name || `Structure ${index + 1}`}</td>
                                                        )}
                                                    {currentVisibleMeasurementTypes.map(c => (<td key={c} className="metric-cell">{this.getMeasurementTypeCell(c, metric)}</td>))}
                                                    {currentVisibleMetrics.map(m => (<td key={m} className="metric-cell">{this.getMetricCell(m, metric, ac)}</td>))}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {!currentTask && (<Button variant="secondary" onClick={this.handleRecalculateMetricsClicked}>Re-calculate Metrics</Button>)}
                        <Button variant="secondary" onClick={this.handleExportMetrics}>Export Results</Button>
                        <Button variant="outline-secondary" onClick={this.handleCloseCalculationResultsModal}>Close</Button>
                    </Modal.Footer>
                </ModalDialog>

                {this.state.showTaskGradeModal && currentTask && (
                    <TaskGradeModal
                        task={currentTask}
                        show={this.state.showTaskGradeModal}
                        handleClose={() => this.setState({ showTaskGradeModal: false })}
                        onGradeUpdate={this.handleGradeUpdate}
                    />
                )}

                {this.state.showRunMetricsCalculationModal && (
                    <RunCalculationModal
                        show={this.state.showRunMetricsCalculationModal}
                        handleCalculateMetrics={this.handleCalculateMetrics}
                        handleClose={() => this.handleShowRunMetricsCalculationModal(false)}
                    />
                )}

                <BlockingProgressDialog
                    show={this.state.calcDiceIsLoading}
                    title="Calculating Metrics"
                    text="Please wait, metrics calculation for structure set similarity is in progress. Calculation may take a while for larger sets."
                    screenReaderProgress="Waiting for metrics calculation to finish..."
                />

                <BlockingProgressDialog
                    show={this.props.isUpdatingTask || false}
                    title={getTaskUpdateProgressTitle(this.state.taskUpdateType)}
                    text={getTaskUpdateProgressText(this.state.taskUpdateType)}
                    screenReaderProgress="Waiting for task update to finish..."
                />

            </Row>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(DifferencesToolbar);
