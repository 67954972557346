import React from "react";
import { Button, Modal } from "react-bootstrap";

type OwnProps = {
    onClose(): void,
    isVisible: boolean,
    description: string,
}

type AllProps = OwnProps;

class TaskDescriptionDialog extends React.PureComponent<AllProps> {

    handleClose = () => {
        this.props.onClose();
    }


    render() {
        const { description, isVisible } = this.props;

        return (
            <Modal show={isVisible} onHide={this.handleClose} style={{ wordBreak: "break-word" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {description}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default TaskDescriptionDialog;