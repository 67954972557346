import '../LocalPage.css';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';

import { RTViewerDisplayVersion } from '../../environments';
import HeadingTitle from '../welcome-page/ui-elements/HeadingTitle';
import { User } from '../../store/user';
import { Link } from 'react-router-dom';
import { TrainingTaskState } from '../../datasets/training-task';
import DonutPlot from '../welcome-page/statistics/DonutPlot';
import { MdLocalLibrary } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import routePaths from '../../routes';

type DispatchProps = {
    reloadTasks(): void,
}

type AllProps = StoreState & DispatchProps;

export class HomePage extends React.Component<AllProps> {
    displayName = HomePage.name;

    handleMouseMove = (e: any) => {
        const cards = document.getElementById('mv-landing-page-cards')!.getElementsByClassName('card');
        for (const card of cards) {
            const rect = card.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            (card as HTMLElement).style.setProperty('--mouse-x', `${x}px`);
            (card as HTMLElement).style.setProperty('--mouse-y', `${y}px`);
        }
    };

    componentDidMount = () => {
        this.props.reloadTasks();
        const cardsElement = document.getElementById('mv-landing-page-cards');
        if (cardsElement) {
            cardsElement.addEventListener('mousemove', this.handleMouseMove);
        }
    }

    componentWillUnmount = () => {
        const cardsElement = document.getElementById('mv-landing-page-cards');
        if (cardsElement) {
            cardsElement.removeEventListener('mousemove', this.handleMouseMove);
        }
    }

    renderHomePage = () => {

        const user: User | undefined = this.props.user;

        if (!user) {
            return null;
        }

        let userName = user.username
        if (userName) {
            userName = userName.split('.')[0];
        } else {
            userName = 'User';
        }

        const traineeIconWidth = user.permissions.isTrainee && !user.permissions.isSupervisor && !user.permissions.qaToolAccess ? 200 : 160;
        const traineeVerifyButtonMargin = user.permissions.isTrainee && !user.permissions.isSupervisor && user.permissions.qaToolAccess ? '0' : '5rem'; 

        const tasks = this.props.trainingTasks;

        const notStarted = tasks!.filter(task => task.state === TrainingTaskState.NotStarted).length;
        const inProgress = tasks!.filter(task => task.state === TrainingTaskState.Started).length;
        const completed = tasks!.filter(task => task.state === TrainingTaskState.Finished).length;
        const graded = tasks!.filter(task => task.state === TrainingTaskState.Graded).length;

        // create an empty array to hold the collections
        const collections: any[] = [];

        // get each unique patient id from tasks in an array
        const patientIds = tasks!.map(task => task.fileShare).filter((value, index, self) => self.indexOf(value) === index);

        // loop through each patient id and create a collection object
        patientIds.forEach(patientId => {
            // get the tasks for the current patient id
            const patientTasks = tasks!.filter(task => task.fileShare === patientId);

            // create the collection object for the current patient id
            const collection = {
                id: patientId,
                label: patientId,
                value: patientTasks.length
            };

            // add the collection object to the collections array
            collections.push(collection);
        });

        const data = [
            {
                "id": "Not Started",
                "value": notStarted,
                "label": "Not started",
            },
            {
                "id": "Started",
                "value": inProgress,
                "label": "Started",
            },
            {
                "id": "Finished",
                "value": completed,
                "label": "Finished"

            },
            {
                "id": "Graded",
                "value": graded,
                "label": "Graded"
            }
        ]

        return (

            <>
                <div className={`local-page-container center`}>
                    <br />
                    <Col className='center' style={{ textAlign: 'center' }}>
                        <HeadingTitle title={" "} />
                    </Col>
                    <br />
                    <Row className='center' id="mv-landing-page-cards">

                        {user.permissions.tpAccess && (
                            <Card className="card-btn" style={{ textAlign: 'center', display: 'grid', placeItems: 'center' }}>
                                <img src='/img/guide-small.svg' alt="Guide" width={traineeIconWidth} style={{ paddingBottom: '1rem', paddingTop: '2rem' }} />
                                <img src={"/img/guide-title-new.svg"} alt="logo" width={190} />
                                <Col className='center' style={{ display: 'grid' }}>
                                    {user.permissions.isSupervisor &&
                                        <Link to={routePaths.referenceLibrary} className='mv-landing-page-card'>
                                            <Card className="card-btn col-card-btn card-shadow icon-btn" data-cy='card-reference-library'>
                                                <MdLocalLibrary size={25} />
                                                <h5>Reference Library</h5>
                                            </Card>
                                        </Link>}
                                    <Link to={routePaths.guidelineTraining} className='mv-landing-page-card'>
                                        <Card className="card-btn col-card-btn card-shadow icon-btn" data-cy='card-guideline-training'>
                                            <FaTasks size={25} />
                                            <h5>Guideline Training</h5>
                                        </Card>
                                    </Link>
                                </Col>
                            </Card>
                        )}

                        {user.permissions.qaToolAccess && (
                            <Card className="card-btn">
                                <Card.Body className='grid-center' data-cy='card-qa-tool'>
                                    <img src='/img/verify-small.svg' alt="Verify" width={160} style={{ paddingTop: '2rem' }} />
                                    <img src={"/img/verify-title-new.svg"} alt="logo" width={190} />
                                    <Link to={routePaths.verify} className='mv-landing-page-card'>
                                        <Card className="card-btn col-card-btn card-shadow icon-btn" data-cy='card-guideline-training' style={{ marginBottom: traineeVerifyButtonMargin, textAlign: 'center' }}>
                                            <img width={23} src='/img/icon-quality-assurance-sm.svg' alt='Verify' />
                                            <h5>Open Verify</h5>
                                        </Card>
                                    </Link>
                                </Card.Body>
                            </Card>
                        )}

                        {user.permissions.isTrainee && !user.permissions.isSupervisor && !user.permissions.qaToolAccess && (
                            <>
                                <Card style={{ width: '19rem', height: '35rem', margin: '1rem' }} className='card-shadow flex'>
                                    <h3 style={{ alignSelf: "center" }}>Task State</h3>
                                    <DonutPlot data={data} />
                                </Card>
                                <Card style={{ width: '19rem', height: '35rem', margin: '1rem' }} className='card-shadow flex'>
                                    <h3 style={{ alignSelf: "center" }}>Collections</h3>
                                    <DonutPlot data={collections} />
                                </Card>
                            </>
                        )}

                    </Row>

                    <div className="version-number">{"Version: " + RTViewerDisplayVersion}</div>
                </div>
            </>
        );
    }

    render() {
        return this.renderHomePage();
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(HomePage);
