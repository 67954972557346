import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import AcceptanceCriteriaTableRow from "./AcceptanceCriteriaTableRow";
import "./AcceptanceCriteriaTable.css";
import { TrainingTaskRoi, CalculatedMetrics, getCalculatedMetric, TrainingTask, getDefaultVisibleMetrics } from "../../../../datasets/training-task";
import SimilarityMetricsSelector from "../../SimilarityMetricsSelector";
import { Metric, AllMetricsInOrder, MetricLabels } from "../../../../web-apis/similarity-metrics";
import _ from "lodash";

type OwnProps = {
    taskAcceptanceCriteria: TrainingTaskRoi[];
    changeAcceptanceCriteria(roiToUpdate: string, updates: Partial<CalculatedMetrics>): void;
    task?: TrainingTask | undefined,
};

type OwnState = {
    visibleMetrics: { [m in Metric]: boolean },
}
export default class AcceptanceCriteriaTable extends PureComponent<OwnProps, OwnState> {

    constructor(props: OwnProps) {
        super(props);

        this.state = {
            visibleMetrics: getDefaultVisibleMetrics(this.props.task),
        }
    }

    handleToggleMetricVisibility = (metric: Metric) => {
        const visibleMetrics = this.state.visibleMetrics;
        const newVisibleMetrics = Object.assign({}, visibleMetrics, { [metric]: !visibleMetrics[metric] });
        this.setState({ visibleMetrics: newVisibleMetrics });
    }

    handleAcceptanceCriteriaChanged = (roiName: string, metric: Metric, value: number | undefined) => {
        const calculatedMetric = getCalculatedMetric(metric);
        this.props.changeAcceptanceCriteria(roiName, { [calculatedMetric]: value });
    }

    render() {
        const currentVisibleMetrics = AllMetricsInOrder.filter(m => this.state.visibleMetrics[m] === true);

        return (
            <div>
                <div className="similarity-metrics-selector-floater">
                    <SimilarityMetricsSelector
                        visibleMetrics={this.state.visibleMetrics}
                        onMetricVisibilityToggled={this.handleToggleMetricVisibility}
                        embed
                        showMeasurementTypes={false}
                    />
                </div>
                <Table
                    className="roi-table acceptance-criteria-table"
                    hover
                    borderless
                    striped
                    size="sm"
                    responsive
                >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Structure</th>
                            {currentVisibleMetrics.map(m => (<th key={m}>{MetricLabels[m]}</th>))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.taskAcceptanceCriteria ? this.props.taskAcceptanceCriteria.map((roi, index) => (
                            <AcceptanceCriteriaTableRow
                                key={roi.roiName}
                                roiNumber={index + 1}
                                roiName={roi.roiName}
                                visibleMetrics={this.state.visibleMetrics}
                                acceptanceCriteria={roi.acceptanceCriteria}
                                onAcceptanceCriteriaChanged={this.handleAcceptanceCriteriaChanged}
                            />
                        )) : null}
                    </tbody>
                </Table>
            </div>
        );
    }
}
