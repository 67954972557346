import React, { PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import GlobalToolbar from './common/GlobalToolbar';
import UserSettingsDialog from './common/dialogs/UserSettingsDialog';
import HelpDialog from './common/dialogs/HelpDialog';
import { StoreState } from '../store/store';

import './LandingPageLayout.css';

type OwnProps = {
    className?: string,
}

type AllProps = OwnProps & StoreState;

class LandingPageLayout extends React.Component<PropsWithChildren<AllProps>> {
    render() {

        const { user } = this.props;
        const isLoggedIn = user && user.isAuthenticated;

        return (
            <div className="landing-container">
                <div className="landing-global-toolbar-container">
                    {isLoggedIn &&
                        (<GlobalToolbar isEmbedded={false} />)
                    }
                </div>

                <Container fluid className="home-page">

                    <div className={`local-page-container home-page ${this.props.className || ''}`}>
                        <div className="local-background" />
                        <div className="local-foreground">
                            <div className="landing-panels">

                                <div className="home-panel">

                                    <div className="home-logo">
                                        <img src="/img/logo-mvision-w.svg" alt="MVision Logo" width="600px" />
                                    </div>

                                    {this.props.children}

                                </div>
                            </div>
                        </div>
                    </div>

                    <UserSettingsDialog />
                    <HelpDialog />

                </Container>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    null,
)(LandingPageLayout);
