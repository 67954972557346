import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { TrainingTask, TrainingTaskState } from '../../datasets/training-task';
import GradeButtons from './GradeButtons';

interface Props {
    task: TrainingTask;
    show: boolean;
    handleClose: () => void;
    onGradeUpdate: (grade: string, comments: string) => boolean,
}


export interface Grade {
    selectedGrade: number | null;
}


interface State {
    grade: string;
    comments: string;
}

class TaskGradeModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            grade: this.props.task.grade || '0',
            comments: this.props.task.comments || '',
        };
    }

    handleGradeUpdate = () => {
        const { grade, comments } = this.state;
        const { onGradeUpdate } = this.props;

        if (onGradeUpdate(grade, comments)) {
            this.props.handleClose();
        }
    };

    handleGradeChange = (grade: number) => {
        this.setState({ grade: grade.toString() });
    };

    handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        this.setState({ comments: value });
    };

    render() {
        const { task } = this.props;

        const isTaskFinished = task.state === TrainingTaskState.Finished

        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Task Grading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="task-grade">
                            <Form.Label>Grade</Form.Label>
                            <GradeButtons grade={this.state.grade} onGradeSelect={this.handleGradeChange} />
                        </Form.Group>

                        <Form.Group controlId="task-comments">
                            <Form.Label>Overall comments</Form.Label>
                            <Form.Control as="textarea" rows={3} value={this.state.comments} onChange={this.handleCommentsChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleGradeUpdate} className={isTaskFinished ? "grade-ready" : ""}>
                        Grade Task
                    </Button>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default TaskGradeModal;
