import React from 'react';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import { ViewerState } from '../../../rtviewer-core/viewer-state';
import * as structureSet from '../../../dicom/structure-set';
import { StoreState } from '../../../store/store';
import { Roi, StructureSet } from '../../../dicom/structure-set';
import * as sagas from '../../../store/sagas';
import ControlledSelect, { ControlledSelectOption } from './contouring-toolbars/ControlledSelect';
import { ComparisonSelectorCollection } from './comparison-types';

import './ComparisonSelector.css';
import { TrainingTask } from '../../../datasets/training-task';


type OwnProps = {
    viewerState: ViewerState,
    task: TrainingTask | undefined,
    structureSets: structureSet.StructureSet[],
}

type DispatchProps = {
    setTestAndReferenceStructureObjects: (testAndReferenceStructureObjects: ComparisonSelectorCollection) => void,
    clearSimilarityMetrics: () => void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch: boolean;
}

enum ComparisonTarget { Test, Reference };

/** Comparison Selector component for DifferencesToolbar. */
class ComparisonSelector extends React.Component<AllProps, OwnState> {
    displayName = ComparisonSelector.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            refreshSwitch: false
        };
    }

    componentDidMount = async () => {
        const { task, viewerState, setTestAndReferenceStructureObjects, structureSets } = this.props;

        viewerState.addListener(this.updateView);

        if (task !== undefined) {
            // special handling for MVision Guide (i.e. we have a valid task object)
            const testStructureSet = structureSets.find(s => s.structureSetId === task.traineeStructureSet.sopInstanceUid && s.seriesUid === task.traineeStructureSet.seriesInstanceUid);
            const referenceStructureSet = structureSets.find(s => s.structureSetId === task.gtStructureSet.sopInstanceUid && s.seriesUid === task.gtStructureSet.seriesInstanceUid);

            // ensure we have SDFs
            if (testStructureSet) {
                testStructureSet.toIM(this.props.viewerState.image);
                await this.props.viewerState.generateSdfsIfNeeded(testStructureSet, !!this.props.task);
            }
            if (referenceStructureSet) {
                referenceStructureSet.toIM(this.props.viewerState.image);
                await this.props.viewerState.generateSdfsIfNeeded(referenceStructureSet, !!this.props.task);
            }

            // set initial test and reference ROIs
            const testRoi = viewerState.selectedRoi && viewerState.selectedRoi.structureSet === testStructureSet ? viewerState.selectedRoi : undefined;
            const referenceRoi = referenceStructureSet && testRoi ? referenceStructureSet.getRois().find(r => r.name === testRoi.name) : undefined;

            setTestAndReferenceStructureObjects({ testStructureSet, referenceStructureSet, testRoi, referenceRoi });
        }
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
        // TODO: decide if we should call this.props.setTestAndReferenceStructureObjects here and set everything to undefined
    }

    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });

        // change current test structure set and test roi if they got changed in the main application flow,
        // unless the application is changing to the comparison structure set
        // NOTE: if this function turns out to have weird timing problems then consider moving
        // testStructureSet, testRoi, referenceStructureSet, referenceRoi, and their related functionalities
        // to viewerState. But this should be ok as long as we don't write anything to viewerState in this component.
        const { viewerState } = this.props;
        if (viewerState.selectedRoi && viewerState.selectedRoi !== this.props.testRoi && !viewerState.isComparisonStructureSetSelected()) {
            const targets: ComparisonSelectorCollection = {
                testStructureSet: viewerState.selectedStructureSet || undefined,
                testRoi: viewerState.selectedRoi || undefined
            };
            if (this.props.task) {
                // also get matching reference roi if in MVision Guide
                targets.referenceRoi = this.getMatchingReferenceRoi(targets.testRoi, this.props.task);
            }
            this.props.setTestAndReferenceStructureObjects({ ...targets });
        }
    }

    getRoiFromOption = (option: ControlledSelectOption) => {
        const { structureSets } = this.props;
        const rois = structureSets.map(ss => ss.getRois()).reduce((a, b) => a.concat(b), []).filter(roi => roi.roiId === option.value);
        return rois[0];
    }

    memoizedStructureSetOptionsCreator = memoizeOne((structureSets: structureSet.StructureSet[], refreshSwitch: boolean) => structureSets
        .map(ss => ({
            value: ss.structureSetId,
            label: ss.getLabel()
        })));

    memoizedReferenceRoiOptionsCreator = memoizeOne((structureSet: structureSet.StructureSet | undefined, refreshSwitch: boolean) => {
        if (!structureSet) { return []; }

        return structureSet.getRois()
            .sort((roiA, roiB) => roiA.name.localeCompare(roiB.name)) // Sort by ROI names
            .map(roi => ({
                value: roi.roiId,
                label: roi.name
            }));
    });


    memoizedTestRoiOptionsCreator = memoizeOne((structureSet: structureSet.StructureSet | undefined, refreshSwitch: boolean) => {
        if (!structureSet) { return []; }

        return structureSet.getRois()
            .sort((roiA, roiB) => roiA.name.localeCompare(roiB.name)) // Sort by ROI names
            .map(roi => ({
                value: roi.roiId,
                label: roi.name
            }));
    });

    onChangeStructureSet = async (option: ControlledSelectOption | null, target: ComparisonTarget) => {
        const targetStructureSet = target === ComparisonTarget.Test ? 'testStructureSet' : 'referenceStructureSet';
        const targetRoi = target === ComparisonTarget.Test ? 'testRoi' : 'referenceRoi';

        // set new structure set
        const matchingStructureSet = !option ? undefined : (this.props.structureSets || []).find(ss => ss.structureSetId === option.value);
        const stateTargetStructureSet: ComparisonSelectorCollection = { [targetStructureSet]: matchingStructureSet }

        // ensure we have SDFs
        if (matchingStructureSet) {
            // temporarily unsubscribe this component to prevent nested calls to updateView that would override manual user dropdown selections
            this.props.viewerState.removeListener(this.updateView);
            try {
                matchingStructureSet.toIM(this.props.viewerState.image);
                await this.props.viewerState.generateSdfsIfNeeded(matchingStructureSet, !!this.props.task);
            }
            finally {
                this.props.viewerState.addListener(this.updateView);
            }
        }

        // set new roi
        let stateTargetRoi: ComparisonSelectorCollection = {};
        // change currently selected roi to one where the label matches (or should we just clear it?)
        if (this.props[targetStructureSet] !== undefined && matchingStructureSet && this.props[targetStructureSet]!.structureSetId !== matchingStructureSet.structureSetId) {
            // structure set was changed from a valid structure set to another valid structure set -- change the current roi
            const roi = this.props[targetRoi];
            if (roi) {
                const roisMatchingNameInNewStructureSet = matchingStructureSet.getRois().filter(r => r.name === roi.name);
                if (roisMatchingNameInNewStructureSet.length === 1) {
                    stateTargetRoi = { [targetRoi]: roisMatchingNameInNewStructureSet[0] };
                } else {
                    // if there wasn't exactly a single match, then reset the roi selection
                    stateTargetRoi = { [targetRoi]: undefined };
                }
            }
        } else if (!this.props[targetStructureSet] || !matchingStructureSet) {
            // either previous or current (or both) of the structure sets wasn't valid, so clear any current roi entry
            stateTargetRoi = { [targetRoi]: undefined };
        }

        // finally, apply state
        this.props.setTestAndReferenceStructureObjects({ ...stateTargetStructureSet, ...stateTargetRoi });
        this.props.clearSimilarityMetrics();
    }

    onChangeTestStructureSet = (option: ControlledSelectOption | null) => this.onChangeStructureSet(option, ComparisonTarget.Test);
    onChangeReferenceStructureSet = (option: ControlledSelectOption | null) => this.onChangeStructureSet(option, ComparisonTarget.Reference);

    onChangeRoi = (option: ControlledSelectOption | null, target: ComparisonTarget) => {
        const targetStructureSet = target === ComparisonTarget.Test ? 'testStructureSet' : 'referenceStructureSet';
        const targetRoi = target === ComparisonTarget.Test ? 'testRoi' : 'referenceRoi';

        const matchingRoi = this.props[targetStructureSet] && option ? this.props[targetStructureSet]!.getRois().find(r => r.roiId === option.value) : undefined;

        if (target === ComparisonTarget.Test && this.props.task) {
            // also change reference roi to match if we're in MVision Guide
            const referenceRoi = this.getMatchingReferenceRoi(matchingRoi, this.props.task);
            this.props.setTestAndReferenceStructureObjects({ testRoi: matchingRoi, referenceRoi: referenceRoi });
        } else {
            this.props.setTestAndReferenceStructureObjects({ [targetRoi]: matchingRoi });
        }
    }

    onChangeTestRoi = (option: ControlledSelectOption | null) => this.onChangeRoi(option, ComparisonTarget.Test);
    onChangeReferenceRoi = (option: ControlledSelectOption | null) => this.onChangeRoi(option, ComparisonTarget.Reference);

    /** Return a ComparisonSelect-matching list item ("value") matching a current selection (from out of four options -- test/reference and roi/structure set) */
    getSelectedDropdownValue = (options: ControlledSelectOption[], item: StructureSet | Roi | undefined): ControlledSelectOption | undefined => {
        if (item === undefined) {
            return undefined;
        }

        if (item instanceof StructureSet) {
            return options.find(o => o.value === item.structureSetId);
        } else {
            return options.find(o => o.value === item.roiId);
        }
    }

    getMatchingReferenceRoi = (testRoi: structureSet.Roi | undefined, task: TrainingTask): Roi | undefined => {
        let referenceRoi: Roi | undefined = undefined;
        if (testRoi && this.props.referenceStructureSet && task.traineeStructureSet.rois.find(r => r.roiName === testRoi.name)) {
            // if we have a valid test roi, and the test roi is included in trainee's task rois, and the test roi is also included in reference data, then swap
            // the current reference roi to the matching one from reference structure set
            const taskGtRoi = task.gtStructureSet.rois.find(r => r.roiName === testRoi.name);
            if (taskGtRoi) {
                referenceRoi = this.props.referenceStructureSet.getRois().find(r => r.name === taskGtRoi.roiName);
            }
        }
        return referenceRoi;
    }

    render() {
        // set refreshSwitch as a prop to the memoization functions to make sure they get re-constructed if any of the underlying
        // viewerState-adjacent data is updated (such as ROI names)
        const structureSetOptions = this.memoizedStructureSetOptionsCreator(this.props.structureSets, this.state.refreshSwitch);
        const testRoiOptions = this.memoizedTestRoiOptionsCreator(this.props.testStructureSet, this.state.refreshSwitch);
        const referenceRoiOptions = this.memoizedReferenceRoiOptionsCreator(this.props.referenceStructureSet, this.state.refreshSwitch);

        // are we in MVision Guide or in MVision Verify?
        const isInTrainingTool = this.props.currentTask !== undefined;

        return (
            <div className="comparison-selectors">
                <div className="comparison-selector">
                    <div>
                        <ControlledSelect
                            label="Test structure set"
                            onChange={this.onChangeTestStructureSet}
                            options={structureSetOptions}
                            value={this.getSelectedDropdownValue(structureSetOptions, this.props.testStructureSet)}
                            isDisabled={isInTrainingTool}
                        />
                    </div>
                    <div>
                        <ControlledSelect
                            label="Test structure"
                            onChange={this.onChangeTestRoi}
                            options={testRoiOptions}
                            value={this.getSelectedDropdownValue(testRoiOptions, this.props.testRoi)}
                        />
                    </div>
                </div>
                <div className="comparison-selector">
                    <div>
                        <ControlledSelect
                            label="Reference structure set"
                            onChange={this.onChangeReferenceStructureSet}
                            options={structureSetOptions}
                            value={this.getSelectedDropdownValue(structureSetOptions, this.props.referenceStructureSet)}
                            isDisabled={isInTrainingTool}
                        />
                    </div>
                    <div>
                        <ControlledSelect
                            label="Reference structure"
                            onChange={this.onChangeReferenceRoi}
                            options={referenceRoiOptions}
                            value={this.getSelectedDropdownValue(referenceRoiOptions, this.props.referenceRoi)}
                            isDisabled={isInTrainingTool}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(ComparisonSelector);
