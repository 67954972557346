import { MouseToolBase } from "./mouse-tool-base";
import { View } from "../view";
import { deepCopy } from '../../util';

export default class WindowLevelTool extends MouseToolBase {

    handleDrag(view: View, mousePointMm: number[], diff: number[], mouseButton: number): void {
        const vm = view.viewManager;
        if (mouseButton === 0) {
            const vs = vm.viewerState;
            const wwDiff = Math.round(diff[0] * 2000);
            const wcDiff = Math.round(diff[1] * 2000);
            const windowLevel = deepCopy(vs.windowLevel);
            windowLevel.ww += wwDiff;
            windowLevel.wc += wcDiff;
            if (windowLevel.ww < 1) windowLevel.ww = 1;
            vs.setWindowLevel(windowLevel);
        } else {
            // pan if LMB wasn't being used
            vm.pan(view, diff[0], diff[1]);
        }
    }
}