import _ from "lodash";
import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlus, FaTimesCircle, FaExclamationTriangle } from "react-icons/fa";
import { connect } from "react-redux";
import Select from 'react-select';
import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import AppAuth from "../../../web-apis/app-auth";

import { AzureShareInfo } from "../../../web-apis/azure-files";
import { Backend } from "../../../web-apis/backends";
import ModalDialog from "../ModalDialog";
import "./AddTaskDialog.css";
import { PageImage, PageStructureSet } from "../../annotation-page/models/PagePatient";
import { DatasetStructureSet } from "../../../datasets/dataset-structure-set";
import AcceptanceCriteriaTable from "./roi-settings/AcceptanceCriteriaTable";
import { TrainingTaskRoi, createBatchTasks, CalculatedMetrics, TrainingTask, TrainingTaskGroup, isLandmarkRoiName } from "../../../datasets/training-task";
import { produce } from "immer";
import { SessionNotification, NotificationType, DEFAULT_SESSION_TIMEOUT_IN_MS } from "../models/SessionNotification";
import { TrainingUser } from "../../../store/training-user";
import { sortAlphanumeric } from "../../../util";
import { Dataset } from "../../../datasets/dataset";

const TASK_NAME_LENGTH_MIN = 4;
const TASK_NAME_LENGTH_MAX = 60;

const VALIDATION_ERRORS = {
    NO_TASK_NAME: "no task name set",
    TASK_NAME_TOO_SHORT: `task name must be ${TASK_NAME_LENGTH_MIN} characters or longer`,
    NO_TASK_TYPE: "no task type set",
    NO_STRUCTURES: "no structures selected",
    NO_SCANS: "no scans selected",
    NO_TRAINEES: "no trainees selected",
    ONLY_LANDMARK_STRUCTURES: "only landmark structures selected, please add at least one non-landmark structure",
};

/** Returns true if the requested user was assigned a version of the task we're duplicating, false otherwise.
 * NOTE: does NOT check in archived tasks!
 */
const wasDuplicateeTaskAssignedToUser = (previouslyAssignedTraineeUserIds: string[], userId: string): boolean => {
    return previouslyAssignedTraineeUserIds.includes(userId);
}

type ValidationErrors = {
    taskName: string | undefined,
    type: string | undefined,
    structures: string | undefined,
    scans: string | undefined,
    trainees: string | undefined,
}

type OwnProps = {
    isVisible: boolean,
    shareInfo: AzureShareInfo | undefined,
    duplicateFromTask?: TrainingTask,
    taskGroups?: TrainingTaskGroup[],
    onClose: () => void,
    loadDatasetForDuplication?: (azureShare: AzureShareInfo) => void,
}

export enum NewStructureSetOption {
    NoSelection, FromScratch, AutoContour
}

type DispatchProps = {
    setUserSettingBackend(backend: Backend | null): void,
    logIntoBackend(backend: Backend | null): void,
    logIntoAppAuth: (appAuth: AppAuth) => void,
    requestLogOut: () => void,
    setUserSettingPatientInfoVisibility(showPatientInfo: boolean): void,
    downloadDataset: (azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) => void,
    reloadTasks: (azureShare: AzureShareInfo) => void,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
}

type OwnState = {
    pageStructureSets: PageStructureSet[],
    roiNames: string[],
    selectedRoiNames: string[],
    acceptanceCriteria: TrainingTaskRoi[],
    selectedPatients: string[],
    taskName: string,
    taskDescription: string,
    selectedResidents: TrainingUser[],
    type: string,
    isDuplicating: boolean,
    duplicateShareInfo: AzureShareInfo | undefined,
    previouslyAssignedTraineeUserIds: string[],
    // Field-specific touched state instead of a single global flag:
    touched: {
        taskName: boolean;
        type: boolean;
        structures: boolean;
        scans: boolean;
        trainees: boolean;
    };
    // Store individual validation error messages:
    errors: ValidationErrors;
    useAiModel: boolean,
    selectedAiModel: string | null,
}


type AllProps = OwnProps & StoreState & DispatchProps;

class AddTasksDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            pageStructureSets: [],
            roiNames: [],
            selectedRoiNames: [],
            acceptanceCriteria: [],
            selectedResidents: [],
            selectedPatients: [],
            taskName: "",
            taskDescription: "",
            type: "",
            isDuplicating: false,
            duplicateShareInfo: undefined,
            previouslyAssignedTraineeUserIds: [],
            touched: {
                taskName: false,
                type: false,
                structures: false,
                scans: false,
                trainees: false,
            },
            errors: {
                taskName: undefined,
                type: undefined,
                structures: undefined,
                scans: undefined,
                trainees: undefined,
            },
            useAiModel: false,
            selectedAiModel: null,
        };
    }

    componentDidMount = () => {
        if (this.props.duplicateFromTask !== undefined) {
            this.initializeFromExistingTask(this.props.duplicateFromTask, this.props.taskGroups ? this.props.taskGroups : []);
        
        // When duplicating, immediately show trainee validation error since it must be filled
        this.setState(produce((draft: OwnState) => {
            draft.touched.trainees = true;
            draft.errors.trainees = VALIDATION_ERRORS.NO_TRAINEES;
        }));
        }
    }
    componentDidUpdate = (prevProps: AllProps, prevState: OwnState) => {
        // pre-configure dialog if we have a task duplicatee set
        if (this.props.duplicateFromTask !== undefined && this.props.duplicateFromTask !== prevProps.duplicateFromTask) {
            this.initializeFromExistingTask(this.props.duplicateFromTask, this.props.taskGroups ? this.props.taskGroups : []);
        }
    }

    initializeFromExistingTask = (task: TrainingTask, groups: TrainingTaskGroup[]) => {

        if (this.props.loadDatasetForDuplication === undefined) {
            throw new Error('Duplication code not initialized properly');
        }

        // find similar-enough tasks
        const group = groups.find(g => g.name === task.name);
        if (!group) {
            throw new Error(`Could not find matching task group for task '${task.name}'`);
        }

        const azureShareInfo = new AzureShareInfo(task.storageAccount, task.fileShare);

        this.props.loadDatasetForDuplication(azureShareInfo);

        const selectedStructures = task.traineeStructureSet.rois.map(r => r.roiName).concat(task.landmark_rois);

        const acceptanceCriteria = task.gtStructureSet.rois.concat(task.landmark_rois.map(landmark => ({ 
            roiName: landmark,
            roiIdx: -1, // temporary index, will be re-assigned during form submit
            acceptanceCriteria: {},
            comments: '',
         })));

        this.setState({
            taskName: task.name,
            taskDescription: task.description,
            type: task.type,
            selectedPatients: _.uniq(group.trainingTasks.map(t => t.patientId)),
            acceptanceCriteria: acceptanceCriteria,
            selectedRoiNames: selectedStructures,
            isDuplicating: true,
            duplicateShareInfo: azureShareInfo,
            previouslyAssignedTraineeUserIds: group.trainingTasks.map(t => t.trainee.user_id),
            selectedAiModel: task.ai_model,
            useAiModel: task.ai_model !== null,
        });
    }

    // Returns validation error for a specific field
    getFieldError = (fieldName: keyof ValidationErrors): string | undefined => {
        let error: string | undefined = undefined;

        switch (fieldName) {
            case "taskName":
                if (!this.state.taskName) {
                    error = VALIDATION_ERRORS.NO_TASK_NAME;
                } else if (this.state.taskName.length < TASK_NAME_LENGTH_MIN) {
                    error = VALIDATION_ERRORS.TASK_NAME_TOO_SHORT;
                }
                break;
            case "type":
                if (!this.state.type) {
                    error = VALIDATION_ERRORS.NO_TASK_TYPE;
                }
                break;
                case "structures":
                    if (this.state.acceptanceCriteria.length === 0) {
                        error = VALIDATION_ERRORS.NO_STRUCTURES;
                    } else {
                        // Check if all structures are landmarks
                        const nonLandmarkRois = this.state.acceptanceCriteria.filter(roi => 
                            !isLandmarkRoiName(roi.roiName)
                        );
                        
                        if (nonLandmarkRois.length === 0) {
                            error = VALIDATION_ERRORS.ONLY_LANDMARK_STRUCTURES;
                        }
                    }
                    break;
            case "scans":
                if (this.state.selectedPatients.length === 0) {
                    error = VALIDATION_ERRORS.NO_SCANS;
                }
                break;
            case "trainees":
                if (this.state.selectedResidents.length === 0) {
                    error = VALIDATION_ERRORS.NO_TRAINEES;
                }
                break;
        }

        return error ? this.capitalize(error) : undefined;
    };

    // Updates state with validation error
    validateField = (fieldName: keyof ValidationErrors) => {
        const error = this.getFieldError(fieldName);
        
        this.setState(produce((draft: OwnState) => {
            draft.errors[fieldName] = error;
            draft.touched[fieldName] = true;
        }));
    };

    // Check if the entire form is valid
    validateForm = (): boolean => {
        const fieldNames = Object.keys(this.state.errors) as Array<keyof ValidationErrors>;
        const currentErrors: Partial<Record<keyof ValidationErrors, string | undefined>> = {};
        
        // Gather all validation errors without setState calls
        fieldNames.forEach(field => {
            currentErrors[field] = this.getFieldError(field);
        });
        
        // Update state once with all errors
        this.setState(produce((draft: OwnState) => {
            fieldNames.forEach(field => {
                draft.errors[field] = currentErrors[field];
                draft.touched[field] = true;
            });
        }));
        
        // Return validity based on collected errors
        return !Object.values(currentErrors).some(error => error !== undefined);
    };

    // Returns an array of errors from all touched fields, or undefined if none exist.
    getFormValidationErrors = (): undefined | string[] => {
        const errorMessages: string[] = [];
        const { errors, touched } = this.state;
        if (touched.taskName && errors.taskName) {
            errorMessages.push(errors.taskName);
        }
        if (touched.type && errors.type) {
            errorMessages.push(errors.type);
        }
        if (touched.structures && errors.structures) {
            errorMessages.push(errors.structures);
        }
        if (touched.scans && errors.scans) {
            errorMessages.push(errors.scans);
        }
        if (touched.trainees && errors.trainees) {
            errorMessages.push(errors.trainees);
        }
        return errorMessages.length === 0 ? undefined : errorMessages;
    };

    capitalize = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    // Generic change handler for validated fields.
    handleFieldChange = (fieldName: keyof ValidationErrors, value: any) => {
        this.setState(produce((draft: OwnState) => {
            (draft as OwnState & Record<string, any>)[fieldName] = value;
            draft.touched[fieldName] = true;
        }), () => this.validateField(fieldName));
    }

    // Mark a field as touched and validate it.
    handleFieldBlur = (fieldName: keyof ValidationErrors) => {
        this.setState(
            (prevState) => ({
                touched: {
                    ...prevState.touched,
                    [fieldName]: true,
                },
            }),
            () => {
                this.validateField(fieldName);
            }
        );
    };

    handleClose = () => {
        this.props.onClose();
    }

    handleRoisChange = (option: any) => {
        // if option.value already exists in selectedRois array, remove it
        let selectedRois: string[] = [];
        if (this.state.selectedRoiNames.includes(option.value)) {
            selectedRois = this.state.selectedRoiNames.filter(s => s !== option.value);
        } else {
            selectedRois = [...this.state.selectedRoiNames, option.value];
        }

        // map selected rois to acceptance criteria name leaving the rest of the fields empty
        const acceptanceCriteria: TrainingTaskRoi[] = selectedRois.map((roiName, i) => {
            const existingAcceptanceCriteria = _.cloneDeep(this.state.acceptanceCriteria.find(ac => ac.roiName === roiName));
            if (existingAcceptanceCriteria) {
                existingAcceptanceCriteria.roiIdx = i;
                return existingAcceptanceCriteria;
            }

            return {
                roiName: roiName,
                roiIdx: i,
                description: '',
                grade: '',
                acceptanceCriteria: {}
            }
        });

        this.setState({
            selectedRoiNames: selectedRois, acceptanceCriteria
        }, () => {
            // Mark 'structures' as touched and validate.
            this.setState(
                (prevState) => ({
                    touched: { ...prevState.touched, structures: true },
                }),
                () => this.validateField("structures")
            )
        });
    }

    handlePatientsChange = (option: any) => {
        this.setState(produce((draft: OwnState) => {
            if (draft.selectedPatients.includes(option.label)) {
                draft.selectedPatients = draft.selectedPatients.filter(s => s !== option.value);
            } else {
                draft.selectedPatients.push(option.value);
            }
        }), () => this.validateField("scans"));
    }

    handleResidentChange = (option: any) => {
        this.setState(produce((draft: OwnState) => {
            // Check if the resident is already in the array based on ID
            const existingIndex = draft.selectedResidents.findIndex(
                resident => resident.id === option.value.id
            );
            if (existingIndex >= 0) {
                draft.selectedResidents.splice(existingIndex, 1);
            } else {
                draft.selectedResidents.push(option.value);
            }
        }), () => this.validateField("trainees"));
    }

    handleChangeAcceptanceCriteria = (roiToUpdate: string, updates: Partial<CalculatedMetrics>) => {
        this.setState(produce((draft: OwnState) => {
            const matchingACRoiIndex = draft.acceptanceCriteria.findIndex(r => r.roiName === roiToUpdate);
            if (matchingACRoiIndex === -1) {
                throw new Error(`Could not find structure ${roiToUpdate} from acceptance criteria!`);
            }
            draft.acceptanceCriteria[matchingACRoiIndex].acceptanceCriteria = Object.assign({}, {
                ...draft.acceptanceCriteria[matchingACRoiIndex].acceptanceCriteria, ...updates,
                hasInteractedWithForm: true
            });
        }));
    }

    // handle submit of form to create new task
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Run full validation before submitting
        if (!this.validateForm()) {
            return;
        }

        if (this.props.trainingUsers === undefined) { throw new Error('Users collection not initialized'); }

        const fileShare = this.state.isDuplicating ? this.state.duplicateShareInfo : this.props.shareInfo;

        if (!fileShare) {
            throw new Error('Undefined fileshare');
        }

        // Reindex all ROIs sequentially starting from 0
        const roisForTask: TrainingTaskRoi[] = this.state.acceptanceCriteria.map(
            (roi, index): TrainingTaskRoi => ({
                ...roi,
                roiIdx: index
            })
        );

        const tasks = {
            storageAccount: fileShare.storageAccountName,
            fileShare: fileShare.fileShareName,
            name: this.state.taskName,
            description: this.state.taskDescription,
            ai_model: (!this.state.useAiModel) ? null : this.state.selectedAiModel,
            type: this.state.type,
            supervisor: {
                user_id: this.props.user!.userId,
                user_name: this.props.user!.username,
            },
            patientIds: this.state.selectedPatients,
            trainees: this.state.selectedResidents.map(r => ({ user_id: r.userId, user_name: r.userName })),
            rois: roisForTask,
        };

        const multipleTasks = tasks.patientIds.length > 1 || tasks.trainees.length > 1;

        createBatchTasks(tasks)
            .then(() => {
                this.props.onClose();
                this.props.reloadTasks(fileShare);
                const notificationId = `task-added-${Date.now().toString()}`;
                this.props.addNotification(new SessionNotification(notificationId,
                    multipleTasks ? 'Tasks were created successfully.' : 'Task was created successfully.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS));
            })
            .catch(error => {
                const message = multipleTasks ? 'An error occurred while trying to create tasks.' : 'An error occurred while trying to create task.';
                const errorDetail = _.isError(error) ? error.message : undefined;
                console.error(message);
                console.error(error);
                const notificationId = `task-failed-to-add-${Date.now().toString()}`;
                this.props.addNotification(new SessionNotification(notificationId, message, NotificationType.Failure, errorDetail));
            });

        this.handleClose();
    };

    handleChange = (option: any) => {
        const value: PageImage = option.value[0];
        const ss = value.structureSets;
        this.setState({ pageStructureSets: ss });
    }

    handlePageStructChange = (option: any) => {
        const ss: DatasetStructureSet = option.value;
        // get the name of the rois coming from the Roimapping from ss and put them all in an array of strings
        const rois: string[] = ss.roiMappings.map(r => r.originalName);
        this.setState({ roiNames: rois });
    }

    renderFieldError = (fieldName: keyof ValidationErrors) => {
        // Check if the field is touched and has an error
        if (this.state.touched[fieldName] && this.state.errors[fieldName]) {
            return (
                <Form.Text className="text-warning my-1">
                    {this.state.errors[fieldName]}
                </Form.Text>
            );
        }
        return null;
    }

    handleToggleUseAiModel = (evt: any) => {
        const value = evt.nativeEvent.target.checked;
        this.setState({ useAiModel: value });

        // set default AI model when checkbox is first toggled on
        const { selectedAiModel } = this.state;
        const { referenceAiModels } = this.props;
        if (value && !selectedAiModel && referenceAiModels && referenceAiModels.length > 0) {
            this.setState({ selectedAiModel: referenceAiModels[0].name });
        }
    }

    isFormValid = (): boolean => {
        // Get all validation field names
        const fieldNames = Object.keys(this.state.errors) as Array<keyof ValidationErrors>;
        
        // Check each field for errors
        for (const field of fieldNames) {
            const error = this.getFieldError(field);
            if (error) {
                return false;
            }
        }
        
        return true;
    };    

    render() {
        const isVisible = this.props.isVisible;
        
        
        const isFormValid = this.isFormValid();
        const validationTooltip = "Please fill in all required fields";

        const { gtRois, referenceAiModels, shareInfo } = this.props;
        if (!gtRois) {
            return null;
        }

        let isLoading = false;

        const { duplicateShareInfo } = this.state;

        // use whatever relevant azure dataset we're currently using, whether we're creating a new task or
        // duplicating an existing one
        const currentShareInfo = duplicateShareInfo !== undefined ? duplicateShareInfo : shareInfo;

        // show a spinner if current dataset is still loading
        if (currentShareInfo) {
            const datasetId = Dataset.generateDatasetId(currentShareInfo);
            if (!!this.props.datasetsDownloading[datasetId]) {
                isLoading = true;
            }

        }

        // filter out landmark structures since they are not valid targets for acceptance criteria 
        const filteredAcceptanceCriteria = this.state.acceptanceCriteria.filter(r => !isLandmarkRoiName(r.roiName))

        const aiModelOptions: { label: string; value: string; }[] = [];
        if (referenceAiModels) {
            referenceAiModels.forEach(m => aiModelOptions.push({ label: `${m.label} (${m.name})`, value: m.name }));
        }

        const patientOptions = this.props.patients.map((patient: any) => {
            return {
                label: patient,
                value: patient,
            }
            // if patients is in selected patients, then erase it from the list
        }).filter((patient: any) => {
            return !this.state.selectedPatients.includes(patient.label)
        }).sort((a: { label: string; }, b: { label: string; }) => sortAlphanumeric(a.label, b.label));

        const roiOptions = gtRois.map((roi: string) => {
            return {
                label: roi,
                value: roi,
            }
            // if Roi is in selected Rois, then erase it from the list
        }).filter((roi: any) => {
            return !this.state.selectedRoiNames.includes(roi.value);
        }).sort((a, b) => sortAlphanumeric(a.label, b.label));

        const pageStructsOptions = this.state.pageStructureSets.map((structureSet: PageStructureSet) => {
            return {
                label: structureSet.structureSet.label,
                value: structureSet.structureSet,
            }
        }).sort((a, b) => sortAlphanumeric(a.label, b.label)).flat();

        const users = !this.props.trainingUsers ? [] : this.props.trainingUsers.map(u => {
            return {
                label: u.userName,
                value: u,
            }
            // if user is in selected users, then erase it from the list
        }).filter(user => !this.state.selectedResidents.includes(user.value)).sort((a, b) => sortAlphanumeric(a.label, b.label));

        const wasPreviousTaskAssigned = (residentId: string): boolean => {
            const { duplicateFromTask, taskGroups } = this.props;

            // find task group (if one exists)
            const group = !!taskGroups && duplicateFromTask ? taskGroups.find(g => g.name === duplicateFromTask.name) : undefined;

            // return false if we're not duplicating
            if (!this.state.isDuplicating || !duplicateFromTask) { return false; }

            // return false if user wasn't assigned to the original task or
            // current form state looks different from duplicatee task.
            // these checks go in order of increasing complexity so we can try to exit early
            return wasDuplicateeTaskAssignedToUser(this.state.previouslyAssignedTraineeUserIds, residentId) && (!!group &&
                !!this.state.duplicateShareInfo &&
                this.state.taskName === duplicateFromTask.name &&
                // this.state.taskDescription === duplicateFromTask.description && // ignore description from this check
                this.state.type === duplicateFromTask.type &&
                this.state.duplicateShareInfo.storageAccountName === duplicateFromTask.storageAccount &&
                this.state.duplicateShareInfo.fileShareName === duplicateFromTask.fileShare &&
                this.state.selectedRoiNames.length === duplicateFromTask.traineeStructureSet.rois.length &&
                group.trainingTasks.map(t => t.patientId).every(pId => this.state.selectedPatients.includes(pId)) &&
                duplicateFromTask.traineeStructureSet.rois.map(r => r.roiName).every(rName => this.state.selectedRoiNames.includes(rName)) &&
                // last one: check acceptance criteria here -- this can get a bit slower, depending on size of AC
                duplicateFromTask.gtStructureSet.rois.map(r => r.acceptanceCriteria).every(dac => this.state.acceptanceCriteria.map(ac => ac.acceptanceCriteria).some(ac => _.isEqual(ac, dac)))
            );
        }

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                backdrop="static"
                size="lg"
                data-cy="create-task-modal">

                <Modal.Header closeButton>
                    <Modal.Title>{this.state.isDuplicating ? (<>Duplicate Task</>) : (<>Create a New Task</>)}</Modal.Title>
                </Modal.Header>
                {isLoading ? (
                    <Modal.Body>
                        <div className="dialog-spinner">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    </Modal.Body>
                ) : (
                        <Form onSubmit={this.handleSubmit}>
                            <Modal.Body className="user-settings">
                                <div className="notice">
                                    <p>Setup structure sets and structures for the new task.</p>
                                    {this.state.isDuplicating && (<p>Settings from previous task are used by default but can be changed.</p>)}
                                </div>

                                {/* get tasks from store and use a select with the task uid of each one */}
                                <Form.Group className="mb-1" as={Row} controlId="formPatientId">
                                    <Form.Label column>
                                        <div>Task Name:</div>
                                    </Form.Label>
                                    <Col className="value-row-shift-input mb-0">
                                        <Form.Control
                                            type="text"
                                            minLength={TASK_NAME_LENGTH_MIN}
                                            maxLength={TASK_NAME_LENGTH_MAX}
                                            placeholder="Task Name"
                                            value={this.state.taskName}
                                            onChange={(e: any) => this.handleFieldChange('taskName', e.target.value)}
                                            onBlur={() => this.handleFieldBlur('taskName')}
                                            isInvalid={!!this.state.errors.taskName}
                                            data-cy="task-modal-name"
                                        />
                                        {this.renderFieldError('taskName')}
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-1" as={Row} controlId="formDescription">
                                    <Form.Label column>
                                        <div>Description (optional):</div>
                                    </Form.Label>
                                    <Col className="value-row-shift-input mb-0">
                                        <textarea
                                            className="form-control"
                                            placeholder="Description"
                                            value={this.state.taskDescription}
                                            onChange={(e: any) => this.setState({ taskDescription: e.target.value })}
                                            data-cy="task-modal-descr"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-1" as={Row} controlId="formType">
                                    <Form.Label column className="mb-0">
                                        <div>Type:</div>
                                    </Form.Label>
                                    <Col className="mb-0" data-cy="task-modal-type">
                                        <Select
                                            placeholder="Type"
                                            aria-label="Type"
                                            className={"select-input"}
                                            options={[{ label: "Test", value: "TEST" }, { label: "Practice", value: "PRACTICE" }]}
                                            name="type"
                                            onChange={(option: any) => this.handleFieldChange("type", option.value)}
                                            onBlur={() => this.handleFieldBlur("type")}
                                            data-cy="task-modal-type-options"
                                            id="task-modal-type-options"
                                            value={this.state.type === 'TEST' ? { label: "Test", value: "TEST" } : this.state.type === 'PRACTICE' ? { label: "Practice", value: "PRACTICE" } : undefined}
                                        />
                                        {this.renderFieldError('type')}
                                    </Col>
                                </Form.Group>
                                {referenceAiModels && referenceAiModels.length > 0 && (
                                    <Form.Group className="mb-2" as={Row} controlId="formAiModels">
                                        <Form.Label column>
                                            <div>Practice AI contours:</div>
                                        </Form.Label>
                                        <Col className="mb-2" data-cy="task-modal-ai-model">
                                            <Form.Check label="Practice contouring on an AI model" onChange={this.handleToggleUseAiModel} checked={this.state.useAiModel} />
                                            {this.state.useAiModel && (
                                                <Select
                                                    placeholder="Select AI model to practice..."
                                                    aria-label="AI Model"
                                                    className="select-input"
                                                    options={aiModelOptions}
                                                    name="ai model"
                                                    onChange={(e: any) => this.setState({ selectedAiModel: e.value })}
                                                    data-cy="task-modal-type-options"
                                                    id="task-modal-type-options"
                                                    value={this.state.selectedAiModel === null ? aiModelOptions[0] : aiModelOptions.find(m => m.value === this.state.selectedAiModel)}
                                                />
                                            )}
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group className="mb-0" as={Row} controlId="formRois">
                                    <Form.Label column>
                                        <div>Select structures for task:</div>
                                    </Form.Label>
                                    <Col className="mb-1" data-cy="task-modal-structures">
                                        <Select
                                            placeholder="Select structures"
                                            aria-label="Select structures"
                                            className={"select-input"}
                                            options={roiOptions}
                                            onChange={this.handleRoisChange}
                                        />
                                        {this.renderFieldError('structures')}
                                    </Col>
                                </Form.Group>
                                {this.state.selectedRoiNames.length > 0 && (
                                    <Form.Group className="mb-2" as={Row} controlId="formSelectedRois">
                                        <Form.Label column>
                                            <div>Selected structures</div>
                                        </Form.Label>
                                        <Col>
                                            <div className="rois-div">
                                                {this.state.selectedRoiNames.map(roiName => (
                                                    <div key={roiName} className="roi-div"><p className="roi-p">{roiName}<FaTimesCircle className="times" onClick={() => this.handleRoisChange({ label: roiName, value: roiName })} /></p></div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group className="mb-0" as={Row} controlId="formPatients">
                                    <Form.Label column>
                                        <div>Scan(s):</div>
                                    </Form.Label>
                                    <Col className="mb-1" data-cy="task-modal-scans">
                                        <Select
                                            placeholder="Scan"
                                            className="select-input"
                                            options={patientOptions}
                                            onChange={this.handlePatientsChange}
                                        />
                                        {this.renderFieldError('scans')}
                                    </Col>
                                </Form.Group>
                                {this.state.selectedPatients.length > 0 &&
                                    <Form.Group className="mb-2" as={Row} controlId="formPatientsSelected">
                                        <Form.Label column>
                                            <div>Scan(s) selected:</div>
                                        </Form.Label>
                                        <Col className="mb-0">
                                            <div className="rois-div">
                                                {this.state.selectedPatients.map(patient => (
                                                    <div className="roi-div" key={patient}>
                                                        <p className="roi-p">
                                                            {patient}
                                                            <FaTimesCircle
                                                                className="times"
                                                                onClick={() => this.handlePatientsChange({
                                                                    label: patient,
                                                                    value: patient
                                                                })}
                                                            />
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                }
                                {this.state.pageStructureSets.length > 0 &&
                                    <Form.Group className="mb-2" as={Row} controlId="formTraineeStructureSet">
                                        <Form.Label column>
                                            <div>Select reference for patient:</div>
                                        </Form.Label>
                                        <Col className="mb-0">
                                            <Select
                                                placeholder="Trainee Structure Set"
                                                options={pageStructsOptions}
                                                className="select-input"
                                                onChange={this.handlePageStructChange}
                                            />
                                            {this.renderFieldError('scans')}
                                        </Col>
                                    </Form.Group>
                                }
                                <Form.Group className="mb-0" as={Row} controlId="formResidentId">
                                    <Form.Label column>
                                        <div>Trainee(s):</div>
                                    </Form.Label>
                                    <Col className="mb-1" data-cy="task-modal-trainees">
                                        <Select
                                            placeholder="Trainee"
                                            options={users}
                                            className="select-input"
                                            onChange={this.handleResidentChange}
                                        />
                                        {this.renderFieldError('trainees')}
                                    </Col>
                                </Form.Group>
                                {this.state.selectedResidents.length > 0 &&
                                    <Form.Group className="mb-2" as={Row} controlId="formResidentsSelected">
                                        <Form.Label column>
                                            <div>Trainee(s) selected:</div>
                                        </Form.Label>
                                        <Col className="mb-2">
                                            <div className="rois-div">
                                                {this.state.selectedResidents.map(resident => (
                                                    <div className={`roi-div ${wasPreviousTaskAssigned(resident.id) ? 'resident-already-included' : ''}`}
                                                        key={resident.id}
                                                        title={wasPreviousTaskAssigned(resident.id) ? 'This task is already assigned to this trainee (but you can assign it again if you wish)' : undefined}
                                                    >
                                                        <p className="roi-p">{resident.userName}</p>
                                                        {wasPreviousTaskAssigned(resident.id) && (<FaExclamationTriangle className="task-prev-assigned-notification" />)}
                                                        <FaTimesCircle className="times" onClick={() => this.handleResidentChange({ label: resident.userName, value: resident })} />
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                }

                                <hr />
                                <Form.Group>
                                    <Modal.Title>Test acceptance criteria</Modal.Title>
                                    <div className="w-75">Set acceptance criteria for structures in the task (optional):</div>
                                    <br />
                                    {(filteredAcceptanceCriteria.length > 0) && (
                                        <AcceptanceCriteriaTable
                                            taskAcceptanceCriteria={filteredAcceptanceCriteria}
                                            changeAcceptanceCriteria={this.handleChangeAcceptanceCriteria}
                                            task={this.props.duplicateFromTask}
                                        />
                                    )}


                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button 
                                    variant="light" 
                                    type="submit" 
                                    data-cy="create-task-modal-submit" 
                                    disabled={!isFormValid}
                                    title={!isFormValid ? validationTooltip : undefined}
                                >
                                    <FaPlus />
                                    {this.state.isDuplicating ? (<> Duplicate task</>) : (<> Create task</>)}
                                </Button>
                                <Button variant="light" onClick={this.handleClose} data-cy="create-task-modal-cancel">
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(AddTasksDialog);
