import _ from 'lodash';
import { CalculatedMetrics } from '../datasets/training-task';

/** Type of a similarity metric. NOTE: keep in sync with the AllMetrics and MetricLabels tables (below in this file). */
export enum Metric {
    Dice = 'Dice',
    SDice2 = 'SDice2',
    HD95 = 'HD95',
    SDice1 = 'SDice1',
    SDice3 = 'SDice3',
    SDice5 = 'SDice5',
    AverageAplMm = 'AverageAplMm',
    TotalAplMm = 'TotalAplMm',
    HD = 'HD',
    MeanHD = 'MeanHD',
}

/* Type for measurement types for (e.g. volume and Z-Length).  */
export enum MeasurementType {
    TestVolume = 'TestVolume',
    ReferenceVolume = 'ReferenceVol',
    VolumeDifference = 'VolDifference',
    TestZLength = 'TestZ-Length',
    ReferenceZLength = 'ReferenceZ-Length',
}

/** All supported similarity metric types **in order** as they should appear in UI. This is mainly for 
 * convenience so it's easy to iterate through all supported metrics in code in a consistent way.
 */
export const AllMetricsInOrder = [
    Metric.Dice,
    Metric.SDice1,
    Metric.SDice2,
    Metric.SDice3,
    Metric.SDice5,
    Metric.HD95,
    Metric.HD,
    Metric.MeanHD,
    Metric.AverageAplMm,
    Metric.TotalAplMm,
];

export const AllMeasurementTypesInOrder = [
    MeasurementType.TestVolume,
    MeasurementType.ReferenceVolume,
    MeasurementType.VolumeDifference,
    MeasurementType.TestZLength,
    MeasurementType.ReferenceZLength,
];

/** UI labels with further specifications or units where appropriate 
 * for different similarity metrics. */
export const MetricLabels = {
    [Metric.Dice]: 'DSC',
    [Metric.SDice2]: 'S-DSC (@2mm)',
    [Metric.HD95]: 'HD95 (mm)',
    [Metric.SDice1]: 'S-DSC (@1mm)',
    [Metric.SDice3]: 'S-DSC (@3mm)',
    [Metric.SDice5]: 'S-DSC (@5mm)',
    [Metric.AverageAplMm]: 'Avg APL (mm)',
    [Metric.TotalAplMm]: 'Total APL (mm)',
    [Metric.HD]: 'HD (mm)',
    [Metric.MeanHD]: 'HDmean (mm)',
}

/** UI labels for different measurement types. */
export const MeasurementTypeExportLabels = {
    [MeasurementType.TestVolume]: 'Test volume (cm3)',
    [MeasurementType.ReferenceVolume]: 'Reference volume (cm3)',
    [MeasurementType.VolumeDifference]: 'Volume difference (cm3)',
    [MeasurementType.TestZLength]: 'Test Z-length (mm)',
    [MeasurementType.ReferenceZLength]: 'Reference Z-length (mm)',
}

export const MeasurementTypeLabels = {
    [MeasurementType.TestVolume]: 'Test vol (cm3)',
    [MeasurementType.ReferenceVolume]: 'Ref vol (cm3)',  
    [MeasurementType.VolumeDifference]: 'Vol diff (%)',
    [MeasurementType.TestZLength]: 'Test Z-length (mm)',
    [MeasurementType.ReferenceZLength]: 'Ref Z-length (mm)',
}

export type SimilarityMetrics = {
    user_vol_mm3: number,
    gt_vol_mm3: number,
    user_z_length_mm: number,
    gt_z_length_mm: number,
    user_roi_name?: string,
    gt_roi_name?: string,
    dice: number | null,
    sdice2: number | null,
    hd95: number | null,
    sdice1: number | null,
    sdice3: number | null,
    sdice5: number | null,
    average_apl_mm: number | null,
    total_apl_mm: number | null,
    hd: number | null,
    mean_hd: number | null,
}

/** Returns requested similarity metric from the supplied SimilarityMetrics object, or throws
 * an error if requested metric is not supported (or implemented). */
export const getSimilarityMetricsValue = (metric: Metric, similarityMetrics: SimilarityMetrics | CalculatedMetrics) => {
    let value: number | undefined | null = undefined;
    switch (metric) {
        case Metric.Dice:
            value = similarityMetrics.dice;
            break;
        case Metric.SDice1:
            value = similarityMetrics.sdice1;
            break;
        case Metric.SDice2:
            value = similarityMetrics.sdice2;
            break;
        case Metric.SDice3:
            value = similarityMetrics.sdice3;
            break;
        case Metric.SDice5:
            value = similarityMetrics.sdice5;
            break;
        case Metric.HD:
            value = similarityMetrics.hd;
            break;
        case Metric.HD95:
            value = similarityMetrics.hd95;
            break;
        case Metric.MeanHD:
            value = similarityMetrics.mean_hd;
            break;
        case Metric.AverageAplMm:
            value = similarityMetrics.average_apl_mm;
            break;
        case Metric.TotalAplMm:
            value = similarityMetrics.total_apl_mm;
            break;
        default:
            throw new Error(`Unsupported metric: ${metric}`);

    }

    return value === undefined ? null : value;
}

export const getSimilarityMetricsValueForMeasurementType = (measurementType: MeasurementType, similarityMetrics: SimilarityMetrics | CalculatedMetrics) => {
    let value: number | undefined | null = undefined;
    switch (measurementType) {
        case MeasurementType.TestVolume:
            value = (similarityMetrics.user_vol_mm3! / 1000);
            break;
        case MeasurementType.ReferenceVolume:
            value = (similarityMetrics.gt_vol_mm3! / 1000);
            break;
        case MeasurementType.VolumeDifference:
            value = (100 * (similarityMetrics.user_vol_mm3! / similarityMetrics.gt_vol_mm3! - 1));
            break;
        case MeasurementType.TestZLength:
            value = similarityMetrics.user_z_length_mm;
            break;
        case MeasurementType.ReferenceZLength:
            value = similarityMetrics.gt_z_length_mm;
            break;
        default:
            throw new Error(`Unsupported measurement type: ${measurementType}`);
    }
    return value === undefined ? null : value;
}

export const convertJsonToSimilarityMetrics = (json: any): SimilarityMetrics[] => {
    const expectedProperties = ['user_roi_name', 'user_vol_mm3', 'gt_vol_mm3', 'user_z_length_mm', 'gt_z_length_mm',
        'dice', 'sdice2', 'hd95', 'sdice1', 'sdice3', 'sdice5', 'average_apl_mm', 'total_apl_mm', 'hd', 'mean_hd',
    ];
    if (!_.isArray(json) || json.every(m => !expectedProperties.every(prop => _.has(m, prop)))) {
        const errorMessage = 'Could not convert received JSON file to similarity metrics';
        console.log(errorMessage);
        console.log(json);
        throw new Error(errorMessage);
    }

    return json as SimilarityMetrics[];
}
