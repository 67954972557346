// Common state class for all viewer components. (components under components/rtviewer)
// Also contains functions used by several components.

import { Image } from '../dicom/image';
import { StructureSet, Roi, StructureSetModalMessages } from '../dicom/structure-set';
import { ViewManager } from './view-manager';
import { WindowLevel } from './window-level';
import { deepCopy } from '../util';
import { MouseToolBase } from './mouse-tools/mouse-tool-base';
import * as storage from '../local-storage';
import { Sdf, getRoiSdfResolution } from './webgl/sdf/sdf';
import { rtViewerApiClient } from '../web-apis/rtviewer-api-client';
import { MainMenu } from '../components/rtviewer/toolbars/MainToolbar';
import { ContouringMenu } from '../components/rtviewer/toolbars/ContouringToolbar';
import { BooleanOperatorUi, SimpleBooleanOperationSelections, SimpleBooleanOperand } from '../components/rtviewer/toolbars/contouring-toolbars/BooleanOperationsToolbar';
import { mouseTools } from './mouse-tools/mouse-tools';
import * as util from '../util';
import { SdfOperations, BooleanOperator } from './webgl/sdf/boolean/sdf-operations';
import { Propagation } from './webgl/sdf/propagation/sdf-propagation';
import { Axis } from './view';
import { BoundingBox } from '../math/bounding-box';
import { UndoStack } from './undo-stack';
import { Clipboard } from './clipboard';
import { InfoMenu } from "../components/rtviewer/toolbars/InfoToolbar";
import { DatasetImage } from '../datasets/dataset-image';
import { Dataset } from '../datasets/dataset';
import { DifferencesMenu } from '../components/rtviewer/toolbars/DifferencesToolbar';
import { Vector3f } from '../math/Vector3f';
import { ComparisonRois } from '../components/rtviewer/toolbars/comparison-types';
import { Interpolation } from './webgl/sdf/interpolation/sdf-interpolation';

export class ViewerState {

    private listeners: (() => void)[];
    public viewManager: ViewManager;
    public undoStack: UndoStack;
    public clipboard: Clipboard;
    public username: string;
    public canEdit: boolean;
    public canCreateRtstruct: boolean;
    public image: Image;
    public dataset: Dataset | null;
    public datasetImage: DatasetImage | null;
    public selectedStructureSet: StructureSet | null;
    public visibleStructureSets: StructureSet[];

    //** transient comparison structure set that is used to temporarily house ROI comparison results */
    public comparisonStructureSet: StructureSet | null;

    /** A structure set that viewerstate is in the process of changing to. This is a bit of a hack, but it is used to signal
     * React components subscribed to viewerstate what is about to happen during a setSelectedStructureSet call which may call
     * other functions that might notify listeners.. */
    public changingToStructureSet: StructureSet | undefined;

    public selectedRoi: Roi | null;
    public hoveredRoi: Roi | null;
    public copiedRois: Roi[] | null; // Rois to be copy/pasted to different structure set
    public comparedRois: Roi[] | null; // Rois to be compared 
    public hiddenRois: {[structureSetId: string]: Set<string>}; // structureSetId -> roiNumber
    public mainMenuSelection: MainMenu;
    public contouringMenuSelection: ContouringMenu;
    public infoMenuSelection: InfoMenu;
    public differencesMenuSelection: DifferencesMenu;
    public simpleBooleanOperationSelections: SimpleBooleanOperationSelections;
    public activeMouseTools: MouseToolBase[];
    public windowLevel: WindowLevel;
    public lineWidth: number;
    public lineWidthSelected: number;
    public focusWhenMouseZooming: boolean;
    public showMultipleStructureSets: boolean ;
    public debugMode: boolean;

    // Contouring tool state
    public brushWidthMm: number;
    public erase: boolean;

    constructor(image: Image, structureSet: StructureSet | undefined, dataset: Dataset | null, datasetImage: DatasetImage | null, canEdit: boolean, canCreateRtstruct: boolean) {
        this.listeners = [];
        this.viewManager = new ViewManager(this, 100, 100, image);
        this.undoStack = new UndoStack(this);
        this.clipboard = new Clipboard();
        this.username = rtViewerApiClient.username;
        this.canEdit = canEdit;
        this.canCreateRtstruct = canCreateRtstruct;
        this.image = image;
        this.dataset = dataset;
        this.datasetImage = datasetImage;
        this.selectedStructureSet = null;
        this.visibleStructureSets = [];
        this.comparisonStructureSet = null;
        this.changingToStructureSet = undefined;
        this.selectedRoi = null;
        this.hoveredRoi = null;
        this.copiedRois = null;
        this.comparedRois = null;
        this.hiddenRois = {};
        this.mainMenuSelection = MainMenu.Select;
        this.contouringMenuSelection = ContouringMenu.Line;
        this.infoMenuSelection = InfoMenu.MeasureLength
        this.differencesMenuSelection = DifferencesMenu.Contours;
        this.simpleBooleanOperationSelections = new SimpleBooleanOperationSelections();
        this.activeMouseTools = [mouseTools.pan, mouseTools.select];
        this.windowLevel = deepCopy(image.defaultWindowLevel);
        this.debugMode = false;

        let lineWidthStr = localStorage.getItem(storage.LineWidth);
        this.lineWidth = lineWidthStr ? parseFloat(lineWidthStr) : 0.8;

        let lineWidthSelStr = localStorage.getItem(storage.LineWidthSelected);
        this.lineWidthSelected = lineWidthSelStr ? parseFloat(lineWidthSelStr) : 1.2;

        let focusWhenMouseZooming = localStorage.getItem(storage.FocusWhenMouseZooming);
        this.focusWhenMouseZooming = focusWhenMouseZooming ? focusWhenMouseZooming === "true" : false;

        this.showMultipleStructureSets = false;
        this.brushWidthMm = 10;
        this.erase = false;

        if(structureSet) {
            this.setSelectedStructureSet(structureSet, this.image);
        }

    }

    public addListener(f: () => void) {
        this.listeners.push(f);
    }

    public removeListener(f: () => void) {
        this.listeners = this.listeners.filter(ff => ff !== f);
    }

    public notifyListeners() {
        this.listeners.forEach(f => f());
    }

    public setSelectedStructureSet(ss: StructureSet | null | undefined, img: any | null) {
        if (ss) ss.toIM(img);

        if (!ss) { return; }
        const existingIndexInArray = this.visibleStructureSets.indexOf(ss);
        if (existingIndexInArray > -1) {
            this.visibleStructureSets.splice(existingIndexInArray, 1);
        }
        this.visibleStructureSets.unshift(ss);
        this.showMultipleStructureSets = this.areMultipleStructureSetsVisible();

        try {
            this.changingToStructureSet = ss;

            if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
            if(ss === this.selectedStructureSet) return; 
            if(ss && ss.deleted) return; // Delete context menu also creates a click event for the structureset, ignore.

            const prevSelectedRoi = this.selectedRoi;
            this.setSelectedRoi(null);
            this.selectedStructureSet = ss!;
            if(ss && prevSelectedRoi) {
                let roiNumbers = Object.keys(ss.rois);
                for(let i = 0; i < roiNumbers.length; ++i) {
                    let roi = ss.rois[roiNumbers[i]];
                    if(roi && roi.name === prevSelectedRoi.name) {
                        this.setSelectedRoi(roi);
                    }
                }
            }

            if(ss) this.generateSdfsIfNeeded(ss);
            this.notifyListeners();
            this.sizeChanged();

            if(this.mainMenuSelection === MainMenu.Contouring && ss && !ss.canEdit()) {
                this.setMainMenuSelection(MainMenu.Select);
            }

            // ensure comparison structure set is cleared when changing to any other structure set
            if (this.comparisonStructureSet !== null && ss !== this.comparisonStructureSet) {
                this.clearComparisonStructureSet();
            }
        } finally {
            this.changingToStructureSet = undefined;
        }
    }

    public setComparisonStructureSet(ss: StructureSet | null) {
        this.comparisonStructureSet = ss;
        this.notifyListeners();
    }

    /** Returns true if multiple non-comparison structure sets are currently visible */
    public areMultipleStructureSetsVisible(): boolean {

        // If there is a current selected Ss and it is included in the visibleStructureSets, then we have more than one visible structure set, return true (unless it is the comparison structure set)
        if (this.selectedStructureSet && !this.visibleStructureSets.includes(this.selectedStructureSet) && this.visibleStructureSets.filter(ss => ss !== this.comparisonStructureSet).length > 0) {
            return true;
        }
        return this.visibleStructureSets.filter(ss => ss !== this.comparisonStructureSet).length > 1;
    }

    public async clearComparisonStructureSet() {
        // TODO: check if the setFillTransparency code below is actually needed & adapt it to code changes if so
        // if (this.allStructureSets !== null) {
        //     this.allStructureSets.map(s => {
        //         s.getRois().map(roi => {
        //             roi.setFillTransparency(0);
        //         })
        //     })
        // }
 
        // delete rois from comparison structure set
        if (this.comparisonStructureSet) {
            this.comparisonStructureSet.deleteRois(this.comparisonStructureSet.getRois().map(n => n.roiNumber))
        }
    }

    public focusOnStructure(roi: Roi, ss: StructureSet): void {
        this.setSelectedRoi(roi);
        const vm = this.viewManager;
        if (roi.sdf) {
            vm.focusToRoi(roi.sdf.boundingBox);
        }
        else { // Maybe there are marker points?
            if (ss.contourData[roi.roiNumber]) {
                const sliceIds = Object.keys(ss.contourData[roi.roiNumber]);
                if (sliceIds && sliceIds.length) vm.scrollToSlice(sliceIds[0]);
            }
        }
    }

    /** Returns true if comparison structure set is currently selected or is in the process of being selected. Returns false otherwise and if 
     * comparison structure set is not defined.
    */
    public isComparisonStructureSetSelected(): boolean {
        if (this.comparisonStructureSet) {
            return this.selectedStructureSet === this.comparisonStructureSet || this.changingToStructureSet === this.comparisonStructureSet;
        }

        return false;
    }

    public interpolateRoiClick = (roi: Roi) => {
        const vm = this.viewManager;
        const ss = this.selectedStructureSet as StructureSet;
        if (!ss) { return; }

        const interpolation = new Interpolation(vm);
        document.body.style.cursor = 'wait';
        ss.modalMessage = StructureSetModalMessages.Interpolating;
        this.notifyListeners();

        setTimeout(() => {
            interpolation.interpolate(ss as StructureSet, roi);
            document.body.style.cursor = 'default';
            ss.modalMessage = null;
            this.notifyListeners();
        }, 200);
    }

    /**
     * Create and show an ROI made from the data points where the two compared ROIs overlaps by their contour data in each slice.
     * this new ROIs have a green color and the compared ROIs will change contour color to red and blue respectively 
     * @param roi1 Roi object being compared.
     * @param roi2 Roi object being compared.
     * @returns new ROI object with the data points where the two compared ROIs overlap.
     */

    public async createComparisonRois(roi1: Roi, roi2: Roi): Promise<ComparisonRois | undefined> {
            const sdfOps = new SdfOperations(this.viewManager);
            const roi1Sdf = roi1.sdf;
            const roi2Sdf = roi2.sdf;
            if (!roi1Sdf || !roi2Sdf) {
                // send a window warning message to the user that one or more of the rois have not been contoured
                let warningMessage = 'Structure comparison could not be performed -- make sure the compared structures have been contoured.';
                if (!roi1Sdf && !roi2Sdf) { warningMessage = `Structures '${roi1.name}' and '${roi2.name}' have no contours and cannot be used for comparisons.`; }
                else { warningMessage = `Structure '${!roi1Sdf ? roi1.name : roi2.name}' has no contours and cannot be used for comparisons.`; }
                window.alert(warningMessage);
                return undefined;
            }
            // compare the bounding box of the two rois and return the intersection
            if (roi1Sdf && roi2Sdf) {
                // get the inner part on where both rois overlap and create a new boundingBox for the newRoi.sdf
                const intersection = sdfOps.applyBoolean(roi1Sdf, roi2Sdf, BooleanOperator.AND, false);
                const gt = sdfOps.applyBoolean(roi1Sdf, roi2Sdf, BooleanOperator.AND_NOT, false);
                const user = sdfOps.applyBoolean(roi2Sdf, roi1Sdf, BooleanOperator.AND_NOT, false);
                // create a new roi object with the intersection data points
                if (!this.comparisonStructureSet) return; 
                this.clearComparisonStructureSet();
                // add the new roi to the structure set
                const userRoi = this.comparisonStructureSet.createOverlapRoi(this.viewManager, 'Absent', user, new Vector3f(0,0,255), new Vector3f(0,0,255), this, false);
                const gtRoi = this.comparisonStructureSet.createOverlapRoi(this.viewManager, 'Additional', gt, new Vector3f(255, 0, 0), new Vector3f(255, 0, 0), this, false);
                const compareRoi = this.comparisonStructureSet.createOverlapRoi(this.viewManager, 'Correct', intersection, roi1.lineColor, new Vector3f(0, 255, 0), this, false);
                const roi1Contour = this.comparisonStructureSet.createOverlapRoi(this.viewManager, roi1.name + ' User', roi1Sdf, new Vector3f(1, 100, 60), new Vector3f(0,0,0), this, true);
                const roi2Contour = this.comparisonStructureSet.createOverlapRoi(this.viewManager, roi2.name + ' Reference', roi2Sdf, new Vector3f(240, 100, 0), new Vector3f(0,0,0), this, true);
                // set the compare roi as the selected roi
                return {
                    additional: gtRoi,
                    absent: userRoi,
                    correct: compareRoi,
                    test: roi1Contour,
                    reference: roi2Contour
                };
        }
    }

    public async generateSdfsIfNeeded(ss: StructureSet, task:boolean = false) {
        let vm = this.viewManager;
        const img = vm.image;
        const rois = ss.getRois().filter(roi => !roi.sdf);

        if(!rois.length) return;
        ss.modalMessage = task === true ? StructureSetModalMessages.LoadingStructuresFromTask : StructureSetModalMessages.LoadingStructures;
        this.notifyListeners();
        await util.sleep(2);
        
        for(let i = 0; i < rois.length; ++i) {
            const roi = rois[i];
            try{
                const resolution = getRoiSdfResolution(img, roi);
                const roiContours = ss.contourData[roi.roiNumber];
                if(!roiContours) continue;
                let bb = new BoundingBox();
                Object.keys(roiContours).forEach(sliceId => {
                    let sliceContours = roiContours[sliceId];
                    if(sliceContours.polygons) {
                        sliceContours.polygons.forEach(polygon => {
                            polygon.forEach(point => {
                                bb.expandWithPoint(point[0], point[1], point[2]);
                            })
                        })
                    }
                });
                if(bb.getXSize() === 0 || bb.getYSize() === 0) continue;
                
                let sdf = new Sdf(vm, resolution);
                sdf.createTexture(bb, true, false);

                sdf.addContours(roiContours);
                roi.sdf = sdf;

                if(this.activeMouseTools.includes(mouseTools.brush) && mouseTools.brush.roi === roi){
                    mouseTools.brush.createDrawBuffer();
                }
            }
            catch(error) {
                console.log("Error in sdf generation!", error);
                console.error(error);
                console.log('Error in ROI:', roi)
            }
            
            this.notifyListeners();
            await util.sleep(2); // Stop this blocking thread for a while and let ui show newly created contours
        }

        ss.modalMessage = null;
        this.notifyListeners();
    }

// Works with patient "Mirada_Prostate1"
private createDummyBooleanStructures(ss: StructureSet) {
    let vm = this.viewManager;

    let bladder = ss.rois['1'];
    let body = ss.rois['0'];
    let ctv = ss.rois['2'];
    let LN = ss.rois['6'];

    if(!bladder || !bladder.sdf || !body || !body.sdf || !ctv || !ctv.sdf || !LN || !LN.sdf) return;

    let sdfOps = new SdfOperations(vm);

    let roiOr = ss.duplicateRoi(vm, bladder);
    roiOr.name = "Bladder OR LN";
    roiOr.sdf = sdfOps.applyBoolean(bladder.sdf, LN.sdf, BooleanOperator.OR, false);

    let roiAnd = ss.duplicateRoi(vm, LN);
    roiAnd.name = "LN AND CTV";
    roiAnd.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.AND, false);

    let roiAndNot = ss.duplicateRoi(vm, LN);
    roiAndNot.name = "LN AND NOT CTV";
    roiAndNot.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.AND_NOT, false);

    let roiXor = ss.duplicateRoi(vm, LN);
    roiXor.name = "LN XOR CTV";
    roiXor.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.XOR, false);

    let roi3 = ss.duplicateRoi(vm, bladder);
    roi3.name = "BLADDER COPY";
    roi3.sdf = sdfOps.copy(bladder.sdf, bladder.sdf.boundingBox, false);



    // operations with body are inaccurate due to different sdf resolution

    
    // let roi1 = ss.duplicateRoi(roiBody);
    // roi1.name = "BODY AND NOT CTV";
    // ssSdf.roiSdfs[roi1.roiNumber] = sdfOps.applyBoolean(sdfBody, sdfCtv, BooleanOperator.AND_NOT, roi1);

    // let roi2 = ss.duplicateRoi(roiCtv);
    // roi2.name = "CTV XOR BODY";
    // ssSdf.roiSdfs[roi2.roiNumber] = sdfOps.applyBoolean(sdfCtv, sdfBody, BooleanOperator.XOR, roi2);
    // console.log("body size: " + sdfBody.size)
    // console.log("xor size: " + ssSdf.roiSdfs[roi2.roiNumber].size)

    // let roi4 = ss.duplicateRoi(roiBody);
    // roi4.name = "BODY XOR CTV";
    // ssSdf.roiSdfs[roi4.roiNumber] = sdfOps.applyBoolean(sdfBody, sdfCtv, BooleanOperator.XOR, roi4);

    this.notifyListeners();
}


    // Todo: should be polygon-based when we have the round trip??
    public findPointedRoi(ptMm: number[]): Roi | null {
        if (this.visibleStructureSets.length === 0) {
            return null;
        }

        let result: Roi | null = null;
        let minDist = 9999;
        const tresholdMm = 20;
        const acceptDist = 1;  // the distance under which we instantly accept the first matching roi

        // collect structure sets that we'll do proximity comparison for
        // 1. if comparison structure set is selected, only use that one. otherwise use currently visible structure sets
        // 2. put current selected structure set to the front of the list so it'll be prioritised
        const structureSets = this.isComparisonStructureSetSelected() && this.comparisonStructureSet !== null ? [this.comparisonStructureSet] : 
            (this.selectedStructureSet ? [this.selectedStructureSet, ...this.visibleStructureSets.filter(ss => ss !== this.selectedStructureSet)] : this.visibleStructureSets);

        for (const ss of structureSets) {
            ss.getRois().forEach(roi => {
                let dist = 9999;
                if (this.hiddenRois[ss.structureSetId] && this.hiddenRois[ss.structureSetId].has(roi.roiId)) { return; }
                if (roi.sdf) {
                    dist = roi.sdf.distanceToContour(ptMm);
                }
                else { // Check for markers
                    if( ss.contourData[roi.roiNumber] ) {
                        const sliceId = this.image.sliceIds[this.viewManager.getScrolledSlice(Axis.Z)];
                        if(ss.contourData[roi.roiNumber] && ss.contourData[roi.roiNumber][sliceId]){
                            const points = ss.contourData[roi.roiNumber][sliceId].points;
                            if(points && points.length) {
                                const p = points[0];
                                dist = Math.sqrt(Math.pow((p[0] - ptMm[0]), 2) + (Math.pow(p[1] - ptMm[1], 2)) + (Math.pow(p[2] - ptMm[2], 2)));
                            }
                        }
                        
                    }
                }
                if(dist < tresholdMm && (!result || dist < minDist) ) {
                    if (dist < acceptDist) {
                        return roi;
                    }

                    result = roi;
                    minDist = dist;
                }
            });
        }

        return result;
    }

    public setSelectedRoi(roi: Roi | null) {
        if(roi) this.setSelectedStructureSet(roi.structureSet, null);
        if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
        if(this.selectedRoi !== roi) {
            if(roi && !roi.structureSet.getRois().includes(roi)){
                // Roi is being deleted, this gets called after delete context menu click
                return;
            }
            this.selectedRoi = roi;

            if(this.mainMenuSelection === MainMenu.Contouring && (roi && !roi.canEdit())) {
                this.setMainMenuSelection(MainMenu.Select);
            }

            this.activeMouseTools.forEach(t => t.handleRoiSelected(roi));
            this.notifyListeners();
        }
    }

    public setHoveredRoi(roi: Roi | null) {
        if(this.hoveredRoi !== roi) {
            this.hoveredRoi = roi;
            this.notifyListeners();
        }
    }

    public setCopiedRois(roi: Roi[] | null) {
        this.copiedRois = roi;

        this.notifyListeners();
    }

    public setRoiHidden(roi: Roi, hidden: boolean) {
        const ss = roi.structureSet;
        this.hiddenRois[ss.structureSetId] = this.hiddenRois[ss.structureSetId] || new Set<string>();
        
        if (hidden) {
            this.hiddenRois[ss.structureSetId].add(roi.roiId); 
        }
        else {
            this.hiddenRois[ss.structureSetId].delete(roi.roiId);
        }

        this.notifyListeners();
    } 

    /**
     * Hide (or show) given, or all, ROIs.
     * @param hidden true to hide, false to show
     * @param roiIdsToHide array of roiIds to hide, or undefined for every ROI in current selected structure set
     */
    public hideRois(hidden: boolean, roiIdsToHide?: string[]) {
        const ss = this.selectedStructureSet as StructureSet;
        if (!ss) { return; }

        this.hiddenRois[ss.structureSetId] = this.hiddenRois[ss.structureSetId] || new Set<string>();
        if( hidden) {
            const roisToHide = roiIdsToHide === undefined ? ss.getRois() : ss.getRois().filter(r => roiIdsToHide.includes(r.roiId));
            roisToHide.forEach(roi => this.hiddenRois[ss.structureSetId].add(roi.roiId));
        }
        else {
            if (roiIdsToHide === undefined) {
                // show everything
                this.hiddenRois[ss.structureSetId] = new Set<string>();
            } else {
                // we need to only show certain ROIs
                roiIdsToHide.forEach(rId => this.hiddenRois[ss.structureSetId].delete(rId));
            }
        }
        
        this.notifyListeners();
    }

    public setMainMenuSelection(menu: MainMenu) {
        switch(menu) {
            case MainMenu.Contouring:
                this.setContouringMenuSelection(this.contouringMenuSelection);
                break;
            case MainMenu.Info:
                this.setInfoMenuSelection(this.infoMenuSelection);
                break;
            case MainMenu.Preferences:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                break;
            case MainMenu.Select:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                break;
            case MainMenu.WindowLevel:
                this.setActiveMouseTools([mouseTools.windowLevel, mouseTools.select]);
                break;
            case MainMenu.Differences:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                this.setDifferencesMenuSelection(this.differencesMenuSelection);
                break;
            default:
        }
        this.mainMenuSelection = menu;
        this.notifyListeners();
        this.sizeChanged();
    }

    public setContouringMenuSelection(menu: ContouringMenu) {
        switch(menu) {
            case ContouringMenu.Line:
                this.setActiveMouseTools([mouseTools.lineDraw]);
                break;
            case ContouringMenu.Brush:
                this.setActiveMouseTools([mouseTools.brush]);
                break;
            case ContouringMenu.Boolean:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                this.simpleBooleanOperationSelections = new SimpleBooleanOperationSelections();
                break;
            case ContouringMenu.Crop:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.BorderMove:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Deform:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Margin:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Smoothing:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Clear:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                break;
            default:
        }
        this.contouringMenuSelection = menu;
        this.notifyListeners();
        this.sizeChanged();
    }

    public setInfoMenuSelection(menu: InfoMenu) {
        switch (menu) {
            case InfoMenu.MeasureLength:
                this.setActiveMouseTools([mouseTools.measureLength]);
            break;
            case InfoMenu.IntensityProfile:
                this.setActiveMouseTools([mouseTools.intensityProfile]);
            break;
        default:
        }
    this.infoMenuSelection = menu;
    this.notifyListeners();
    this.sizeChanged();
    }

    public setDifferencesMenuSelection(menu: DifferencesMenu) {
        switch(menu) {
            case DifferencesMenu.Calculate:
                // Calculate differences between two ROIs contours
            break;
            case DifferencesMenu.Contours:
                // Show differences between two ROIs contours
            break;
        }
    }

    public setSimpleBooleanOperator(operator: BooleanOperatorUi) {
        this.simpleBooleanOperationSelections.setOperator(operator);
        this.notifyListeners();
    }

    public setSimpleBooleanOperand(operandIndex: SimpleBooleanOperand, roi: Roi | null) {
        this.simpleBooleanOperationSelections.setOperand(operandIndex, roi);
        this.notifyListeners();
    }

    public setActiveMouseTools(tools: MouseToolBase[]) {
        let toolsToDeactivate = this.activeMouseTools.filter(t => !tools.includes(t));
        let toolsToActivate = tools.filter(t => !this.activeMouseTools.includes(t));
        toolsToDeactivate.forEach(t => t.handleDeactivate());
        toolsToActivate.forEach(t => t.handleActivate(this.viewManager));
        this.activeMouseTools = tools;
        if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
    }

    public setWindowLevel(windowLevel: WindowLevel) {
        this.windowLevel = windowLevel;
        this.notifyListeners();
    }

    public resetWindowLevel() {
        this.windowLevel = deepCopy(this.image.defaultWindowLevel);
        this.notifyListeners();
    }

    public setLineWidth(w: number) {
        w = Math.min(w, 10);
        this.lineWidth = w;
        localStorage.setItem(storage.LineWidth, w.toString());
        this.notifyListeners();
    }
    // calculate difference between two ROIs and show it in a different color
    // Use the and operator between two ROIs to check the area of overlap 

    public setActiveRoi(roi: Roi) {
        this.selectedRoi = roi;
        this.notifyListeners();
    }
    

    public setLineWidthSelected(w: number) {
        w = Math.min(w, 10);
        this.lineWidthSelected = w;
        localStorage.setItem(storage.LineWidthSelected, w.toString());
        this.notifyListeners();
    }

    public setBrushWidth(w: number) {
        this.brushWidthMm = util.clamp(w, 1, 50);
        this.notifyListeners();
    }

    public setFocusWhenMouseZooming(b: boolean) {
        localStorage.setItem(storage.FocusWhenMouseZooming, b ? "true" : "false");
        this.focusWhenMouseZooming = b;
        this.notifyListeners();
    }

    public setErase(erase: boolean) {
        this.erase = erase;
        this.notifyListeners();
    }

    public roisChanged(ss: StructureSet) {
        ss.unsaved = true;
        this.notifyListeners();
    }

    public contoursChanged(ss: StructureSet) {
        ss.unsaved = true;
        this.notifyListeners();
    }

    public compareROIs(comparisonRois: ComparisonRois) {
        if (!this.comparisonStructureSet) {
            throw new Error('No transient comparison structure set');
        }

        this.showMultipleStructureSets = true;
        this.setSelectedStructureSet(this.comparisonStructureSet, this.image);
        this.hideRois(true);
        // just map 3 of the 5 ROIs 
        [comparisonRois.additional, comparisonRois.absent, comparisonRois.correct].forEach((r) => {
            r.setFillTransparency(0.25);
            this.setRoiHidden(r, false);
        });
        [comparisonRois.reference, comparisonRois.test].forEach((r) => {
            this.setRoiHidden(r, false);
        });
        this.notifyListeners();
    }

    public sizeChanged() {
        // Trigger window.resize so that the view grid knows to update its dimensions.
        setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 50);
    }

    public setDebugMode(debug: boolean) {
        this.debugMode = debug;
        this.notifyListeners();
    }

    // TODO: Maybe remove this and only propagate when needed (before interpolation etc.)
    private propagateIfNeeded(roi: Roi) {
        const pg = new Propagation(this.viewManager);
        setTimeout(function(){ 
            if(roi && roi.sdf && roi.sdf.propagationPending) {
                pg.propagate(roi);
            }
        }, 100);
    }
}