import _ from "lodash";

/** A map of alias names for ROIs. Aliases for each ROI are stored in order -- the first alias (index 0)
 * has the highest priority of being automatically picked when relevant. This is usually the latest alias 
 * the user has interacted with for this particular ROI.
 */
export type AliasMap = { [roiName: string]: string[] };

/** Local alias maps are used to exactly match a reference ROI with a single test ROI. The initializeLocalAliasMap
 * function is used to generate a local alias maps from initial data.
 */
export type LocalAliasMap = { [roiName: string]: string | undefined };

/**
 * Initializes a local alias map from a store alias map and structure set ROIs.
 */
export const initializeLocalAliasMap = (
    testRois: string[],
    referenceRois: string[],
    defaultAliasMap: AliasMap): LocalAliasMap => {

    const localAliasMap: LocalAliasMap = {};

    // find initial aliases
    for (const testRoi of testRois) {
        let found = false;

        // first look for identically named ROIs in the two structure sets
        const referenceRoiName = referenceRois.find(r => r.localeCompare(testRoi, undefined, { sensitivity: 'base' }) === 0);
        if (referenceRoiName) {
            localAliasMap[testRoi] = referenceRoiName;
            continue;
        }

        // if not found, search for existing aliases from the default map we just got from store
        for (const aliasKeyName of Object.keys(defaultAliasMap)) {
            const aliasKeyFoundInReference = referenceRois.find(r => r.localeCompare(aliasKeyName, undefined, { sensitivity: 'base' }) === 0);
            if (aliasKeyFoundInReference) {
                const testRoiFoundInAlias = defaultAliasMap[aliasKeyName].find(r => r.localeCompare(testRoi, undefined, { sensitivity: 'base' }) === 0);
                if (testRoiFoundInAlias) {
                    localAliasMap[testRoi] = aliasKeyName;
                    found = true;
                    break;
                }
            }
        }

        if (found) { continue; }

        // if not found, try flipping the search
        const testRoiFoundInAliasKeys = Object.keys(defaultAliasMap).find(r => r.localeCompare(testRoi, undefined, { sensitivity: 'base' }) === 0);
        if (testRoiFoundInAliasKeys) {
            const defaultAliases = defaultAliasMap[testRoiFoundInAliasKeys];
            if (defaultAliases.length > 0) {
                const firstMatchingAlias = defaultAliases.find(alias =>
                    referenceRois.find(r => r.localeCompare(alias, undefined, { sensitivity: 'base' }) === 0));
                if (firstMatchingAlias) {
                    localAliasMap[testRoi] = firstMatchingAlias;
                    continue;
                }
            }
        }

        // if no match at all, then leave as undefined
        localAliasMap[testRoi] = undefined;
    }

    return localAliasMap;
}

/** Combines two alias maps. */
export const combineAliasMaps = (map1: AliasMap, map2: AliasMap): AliasMap => {
    const combined: AliasMap = {};

    for (const roi1 of Object.keys(map1)) {
        combined[roi1] = map1[roi1];
    }

    for (const roi2 of Object.keys(map2)) {
        const aliases2 = map2[roi2];

        if (_.has(combined, roi2)) {
            // combine maps
            combined[roi2] = _.uniq(combined[roi2].concat(aliases2));
        } else {
            // add new entry
            combined[roi2] = aliases2;
        }
    }

    return combined;
}
