import * as storage from '../local-storage';
import { AvailableModels } from './contouring-options';
import { StructureTemplate } from './structure-template';
import { UserPermissions } from './user-permissions';
import { getRtViewerBackendClient } from './auth';
import { User } from '../store/user';
import { SimilarityMetrics, convertJsonToSimilarityMetrics } from './similarity-metrics';
import { TrainingTask } from '../datasets/training-task';
import { RTStructLock } from '../datasets/locks';
import { AzureFileInfo } from './azure-files';
import _ from 'lodash';
import { TrainingUser, convertJsonToTrainingUsers } from '../store/training-user';
import { Image } from '../dicom/image';
import { UserSettings, convertUserSettingsToJson } from '../store/user-settings';

const ApiRoot = '/api';
const ContouringOptionsUrl = `${ApiRoot}/contouringoptions/contouringoptions`;
const StructureTemplatesUrl = `${ApiRoot}/structuretemplates/`;
const UserPermissionsUrl = `${ApiRoot}/permissions`;
const SasUrl = `${ApiRoot}/sas/`;
const UserAccessUrl = `${ApiRoot}/sas/access`;

const dataRoot = '/data';
const saveRTStructUrl = `${dataRoot}/save-rtstruct`

export interface ContouringGuideline {
    link?: string;
    text?: string;
}

export interface RoiGuidelineEntry {
    roiName: string;
    contouringGuidelines: ContouringGuideline[];
}

export type RoiGuidelines = RoiGuidelineEntry[];

export type RoiNameMap = { testRoiName: string, referenceRoiName: string };

export type ReferenceAiModel = { label: string, name: string };

const LOCKED_BY_SOMEONE_ELSE_ERROR = 'cannot not lock the RTSTRUCT file as another user already has lock for this lock_id';


const getMetadataForComputeMetrics = (image: Image, roiNameMaps: RoiNameMap[]) => {
    // populate data to be used in the dice calculation

    // get correct patient orientation from DICOM tags, or fall back to hardcoded default (only works with HFS)
    const orientation = _.get(image.dicomTags, 'ImageOrientationPatient', image.orientationMatrix.slice(0, 6));

    const data = {
        'pixel_spacing': [image.kSpacing, image.jSpacing, image.iSpacing],
        'orientation': orientation,
        'min_patient_pos': image.cubePatient[0],
        'max_patient_pos': image.cubePatient[4],
        'n_slices': image.sliceIds.length,
        'shape': image.imShape,
        'roi_names': roiNameMaps.map(m => [m.referenceRoiName, m.testRoiName]),

        // disable backend swapping pixel spacing coordinates
        // TODO: remove this once the hack is completely removed from backend
        // see: https://app.clickup.com/t/8693yg30d 
        'hack_spacing_order': false,
    }

    return data;
}

class RTViewerAPIClient {

    // TODO: separate these user specific properties from the client functions below
    // NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
    public username: string;
    public email: string;
    public permissions: UserPermissions;

    constructor() {
        this.username = "default-user";
        this.email = "";
        this.permissions = new UserPermissions(false);
    }

    public isMVisionUser() {
        // TODO: handle this with RTViewer config user group settings
        return this.email.includes("@mvision.ai") && !this.email.includes("demo");
    }

    // update the redux store user
    // NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
    public updateUser(user: User) {
        this.username = user.username;
        this.email = user.email;
        this.permissions = user.permissions;
    }


    // API CALL METHODS
    // TODO: separate these from the rtviewer user class above
    public async getAvailableModels(): Promise<AvailableModels> {

        const client = getRtViewerBackendClient();
        const url = ContouringOptionsUrl;

        const response = await client.fetch(url);
        if (response.status !== 200) {
            console.log(response);
            throw new Error("Invalid model options: " + response.status);
        }
        const options = await response.json();
        options.available_models = [];
        options.backend_models = [];
        return options as AvailableModels;
    }

    public async getStructureTemplates(): Promise<StructureTemplate[]> {
        const url = StructureTemplatesUrl;
        const client = getRtViewerBackendClient();

        let response: Response | undefined = undefined;

        try {
            response = await client.fetch(url);
        }
        catch (error) {
            console.log('No response received:');
            console.error(error);
            return [];
        }

        if (!response || response.status !== 200) {
            console.error("Could not fetch structure templates: " + response !== undefined ? response!.status : 'no response received')
            return [];
        }
        const templateData = await response.json();

        // convert incoming template data into StructureTemplate objects
        // TODO: RUTHERFORD: sort these into rutherford and non-rutherford ones
        const templates: StructureTemplate[] = [];
        templateData.forEach((t: any) => templates.push(new StructureTemplate(t)));

        // validate & sort templates
        // 1. every template must have a unique name
        // 2. template name must not be an empty string
        // 3. every roi in a template must have a unique title
        // 4. roi title must not be an empty string
        // -> if any of these conditions does not pass, print an error and remove the template(s) from the list
        const uniqueTemplateNames: { [name: string]: number } = {};
        let invalidTemplates: StructureTemplate[] = [];

        templates.forEach(t => {
            const name = t.name;
            if (name === '') {
                console.error('Structure template has an empty string for its name!');
                invalidTemplates.push(t);
            }

            if (uniqueTemplateNames.hasOwnProperty(name)) {
                uniqueTemplateNames[name] += 1;
            } else {
                uniqueTemplateNames[name] = 1;
            }

            const uniqueRoiTitles: { [name: string]: number } = {};
            t.templateRois.forEach(r => {
                const title = r.title;
                if (title === '') {
                    console.error(`Structure for Structure template ${name} has an empty string for its name!`);
                    invalidTemplates.push(t);
                }

                if (uniqueRoiTitles.hasOwnProperty(title)) {
                    uniqueRoiTitles[title] += 1;
                } else {
                    uniqueRoiTitles[title] = 1;
                }
            });

            Object.entries(uniqueRoiTitles).forEach(
                ([key, value]) => {
                    if (value > 1) {
                        console.error(`Structure ${key} has been configured ${value} times in template ${name} -- all structures must have unique names.`);
                        invalidTemplates.push(t);
                    }
                }
            );

            // while we're here, sort the ROIs alphabetically
            t.templateRois.sort((a, b) => a.title.localeCompare(b.title));
        });

        Object.entries(uniqueTemplateNames).forEach(
            ([key, value]) => {
                if (value > 1) {
                    console.error(`${value} templates have the same name '${key}' -- all templates must have unique names.`);
                    const duplicateNameTemplates = templates.filter(t => t.name === key);
                    invalidTemplates = invalidTemplates.concat(duplicateNameTemplates);
                }
            }
        );

        // remove invalid templates & sort the ones left
        const sortedTemplates = templates.filter(t => !invalidTemplates.includes(t)).sort((a, b) => a.name.localeCompare(b.name));

        return sortedTemplates;
    }

    public async getAnnotationStorages(): Promise<string[]> {
        const url = SasUrl + "annotationstorages";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const names = await response.json();
        if (!_.isArray(names) || !names.every(n => _.isString(n))) {
            throw new Error('Received unexpected data from backend');
        }

        return (names as string[]).sort();
    }


    public async getAllStorages() {
        const url = SasUrl + "allstorages";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const names = await response.json();
        return names.sort();
    }

    /**
    * Call backend with 2 Dicom RTSRUCTs from ROIs and get the response data
    * @param rStruct1: DICOM (RTSTRUCT) file
    * @param rStruct2: DICOM (RTSTRUCT) file
    * @param data: string
    *
    * 
    **/
    public async computeMetrics(testBlob: Blob, referenceBlob: Blob, image: Image, roiNameMaps: RoiNameMap[]): Promise<SimilarityMetrics[]> {
        const url = "/metrics/compute-contour-metrics";
        let formData = new FormData();

        formData.append('userRTSTRUCT', testBlob, `${testBlob.arrayBuffer.name}.dcm`);
        formData.append('gtRTSTRUCT', referenceBlob, `${referenceBlob.arrayBuffer.name}.dcm`);

        const data = getMetadataForComputeMetrics(image, roiNameMaps);
        formData.append("data", JSON.stringify(data));

        const client = getRtViewerBackendClient();
        const response = await client.post(url, {
            headers: {
                // 'skip_dicom_validation': true,
                // "Allow-Control-Allow-Origin": "*",
                // "Allow-Control-Allow-Credentials": true,
            },
            body: formData
        }, {
            fetchTimeoutInMs: 10 * 60 * 1000,  // max timeout: 10 minutes
            maxRetries: 0,  // don't retry compute metrics calls
        });

        if (response.status !== 200) {
            const text = await response.text();
            console.log('Backend response:', response);
            throw new Error(`Could not compute metrics${text ? `: ${text}` : ''}`);
        }

        const responseJson = await response.json();
        return convertJsonToSimilarityMetrics(responseJson);
    }


    // pass clientId and authorization bearer token to the backend
    public async getAvailableTasks(includeArchived?: boolean): Promise<TrainingTask[]> {
        const url = "/training/retrieve-tasks";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url, {
            headers: {
                includeArchived: includeArchived ? 'true' : 'false',
            }
        });
        if (response.status !== 200) {
            console.error("Could not fetch available tasks: " + response.status);
            return [];
        }
        return await response.json();
    }

    public async getTask(taskId: string): Promise<TrainingTask | undefined> {
        const url = "/training/retrieve-task";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url, {
            headers: {
                'id': taskId,
            }
        });
        if (response.status !== 200) {
            console.error(`Could not retrieve task ${taskId}: ${response.status}`);
            return undefined;
        }
        return await response.json();
    }

    //list all available users from the clinic of the supervisor
    public async getAvailableUsers(): Promise<TrainingUser[]> {
        const url = "/training/list-users";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url, {
        });
        if (response.status !== 200) {
            console.error("Could not fetch available users: " + response.status);
            return [];
        }
        const jsonData = await response.json();
        const users = convertJsonToTrainingUsers(jsonData);
        return users;
    }

    //list all available patients from the clinic of the supervisor
    public async getAvailablePatients(storageAccount: string, fileShare: string): Promise<string[]> {
        const url = "/training/list-patients";
        const client = getRtViewerBackendClient();
        const response = await client.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'storageAccount': storageAccount,
                'fileShare': fileShare
            }
        });
        if (response.status !== 200) {
            console.error("Could not fetch available patients: " + response.status);
            return [];
        }
        return await response.json();
    }

    //List available gt rois from the clinic of the supervisor
    public async getAvailableGtRois(storageAccount: string, fileShare: string): Promise<string[]> {
        const url = "/training/list-gt-rois";
        const client = getRtViewerBackendClient();
        const response = await client.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'storageAccount': storageAccount,
                'fileShare': fileShare
            }
        });
        if (response.status !== 200) {
            console.error("Could not fetch available gt rois: " + response.status);
            return [];
        }
        return await response.json();
    }

    public async getAvailableReferenceAiModels(storageAccount: string, fileShare: string): Promise<ReferenceAiModel[]> {
        const url = "/training/list-ai-models";
        const client = getRtViewerBackendClient();
        const response = await client.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'storageAccount': storageAccount,
                'fileShare': fileShare
            }
        });
        if (response.status !== 200) {
            console.error("Could not fetch available reference AI models: " + response.status);
            return [];
        }

        const json = await response.json();
        const aiModels: ReferenceAiModel[] = [];

        if (_.isArray(json)) {
            for (const item of json) {
                if (_.has(item, 'label') && _.has(item, 'name')) {
                    aiModels.push({ label: item['label'], name: item['name'] });
                }
            }
        }

        return aiModels;
    }

    // list all guidelines for each ROI
    public async getGuidelines(): Promise<RoiGuidelines | undefined> {
        const url = "/training/list-roi-contouring-guidelines";
        const client = getRtViewerBackendClient();

        try {
            const response = await client.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status !== 200) {
                console.error("Could not fetch available guidelines: " + response.status);
                return undefined;
            }
            return await response.json();
        }
        catch (err) {
            const message = 'Failed to download guidelines';
            console.error(message);
            console.error(err);
            return undefined;
        }
    }

    /** Tries to lock a structure set. Returns true if successful, false if locked already
     * by someone else, and throws in other cases. */
    public async lockRTStruct(rtStructLock: RTStructLock): Promise<boolean> {
        const url = "/locks/lock-rtstruct";
        const client = getRtViewerBackendClient();
        const response = await client.post(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rtStructLock)
        });

        if (response.status !== 200) {
            const details = await response.json();
            if ((_.get(details, 'detail', '') as string).includes(LOCKED_BY_SOMEONE_ELSE_ERROR)) {
                return false;
            }

            const message = "Could not lock task: " + response.status;
            console.log(message);
            console.error(response);
            throw new Error(message);
        }

        return true;
    }

    /** Tries to unlock a structure set. Returns true if successful, false if locked 
     * by someone else, and throws in other cases. */
    public async unlockRTStruct(rtStructLock: RTStructLock): Promise<boolean> {
        const url = "/locks/unlock-rtstruct";
        const client = getRtViewerBackendClient();
        const response = await client.post(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rtStructLock)
        });

        if (response.status !== 200) {
            const details = await response.json();
            if ((_.get(details, 'detail', '') as string).includes(LOCKED_BY_SOMEONE_ELSE_ERROR)) {
                return false;
            }

            const message = "Could not unlock task: " + response.status;
            console.log(message);
            console.error(response);
            throw new Error(message);
        }

        return true;
    }

    // get locked rtstructs for the storage account and file share
    public async getLockedRtstructs(storageAccount: string, fileShare: string): Promise<RTStructLock[]>;
    public async getLockedRtstructs(): Promise<RTStructLock[]>;
    public async getLockedRtstructs(storageAccount?: string, fileShare?: string): Promise<RTStructLock[]> {
        const url = "/locks/list-locked-rtstructs";
        const client = getRtViewerBackendClient();

        const locationDetails: any = {};
        if (storageAccount && fileShare) {
            locationDetails['storageAccount'] = storageAccount;
            locationDetails['fileShare'] = fileShare;
        }

        const response = await client.get(url, {
            headers: {
                'Content-Type': 'application/json',
                ...locationDetails
            }
        });
        if (response.status !== 200) {
            console.error(response);
            const message = "Could not fetch locked rtstructs: " + response.status;
            console.error(message);
            throw new Error(message);
        }
        return await response.json();
    }


    // create a new task in the backend
    // pass clientId, authorization bearer token, and task data to the backend
    public async createTask(task: TrainingTask) {
        try {
            const url = "/training/create-task";
            const client = getRtViewerBackendClient();
            await client.post(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(task)
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // create a new batch of tasks in the backend
    // pass clientId, authorization bearer token, and task data to the backend\
    public async batchCreateTasks(tasks: any) {
        try {
            const url = "/training/batch-create-tasks";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(tasks)
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to create tasks:');
                console.error(errorData);
                throw new Error(`Could not create task(s)${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // update an existing task in the backend
    // pass clientId, authorization bearer token, and task data to the backend
    public async updateTask(task: TrainingTask) {
        try {
            const url = "/training/update-task";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(task)
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to update task:');
                console.error(errorData);
                throw new Error(`Could not update task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // start an existing task in the backend
    // pass clientId, authorization bearer token, and task data to the backend
    public async startTask(task: TrainingTask) {
        try {
            const url = "/training/start-task";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'id': task.id
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to start task:');
                console.error(errorData);
                throw new Error(`Could not start task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    //finish an existing task in the backend
    // pass clientId, authorization bearer token, and task data to the backend

    public async finishTask(task: TrainingTask, image: Image, roiNameMaps: RoiNameMap[]) {
        const endpoint = '/training/finish-task';
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            id: task.id,
        };
        const requestBody = new URLSearchParams();

        const data = getMetadataForComputeMetrics(image, roiNameMaps);

        requestBody.append('data', JSON.stringify(data));

        try {
            const client = getRtViewerBackendClient();
            const response = await client.post(endpoint, {
                headers,
                body: requestBody,
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to finish task:');
                console.error(errorData);
                throw new Error(`Could not finish task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }



    // delete an existing task in the backend
    // pass clientId, authorization bearer token, and task to the backend
    public async deleteTask(task: TrainingTask) {
        try {
            const url = "/training/delete-task";
            const client = getRtViewerBackendClient();
            const response = await client.delete(url, {
                headers: {
                    'id': task.id,
                    'Access-Control-Allow-Origin': '*',
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to delete task:');
                console.error(errorData);
                throw new Error(`Could not delete task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    // archive an existing task in the backend
    // pass clientId, authorization bearer token, and taskid to the backend
    public async archiveTask(task: TrainingTask) {
        try {
            const url = "/training/archive-task";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'id': task.id,
                    'storageAccount': task.storageAccount,
                    'fileShare': task.fileShare,
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to archive task:');
                console.error(errorData);
                throw new Error(`Could not archive task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // unarchive an existing task in the backend
    // pass clientId, authorization bearer token, and taskid to the backend
    public async unarchiveTask(task: TrainingTask) {
        try {
            const url = "/training/unarchive-task";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'id': task.id,
                    'storageAccount': task.storageAccount,
                    'fileShare': task.fileShare,
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to unarchive task:');
                console.error(errorData);
                throw new Error(`Could not unarchive task${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getUserSettings(): Promise<any> {
        const url = "/training/clinic-settings";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        if (response.status !== 200) {
            console.error(`Could not retrieve user settings: ${response.status}`);
            return undefined;
        }
        
        return await response.json();
    }

    public async saveUserSettings(settings: UserSettings) {
        try {
            const url = "/training/clinic-settings";
            const client = getRtViewerBackendClient();
            const response = await client.post(url, {
                headers: {
                    'content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(convertUserSettingsToJson(settings))
            });
            if (!response.ok) {
                const errorData = await response.json();
                const message = _.get(errorData, 'message', undefined);
                console.error('An error occurred while trying to save user settings:');
                console.error(errorData);
                throw new Error(`Could not save user settings${message ? `: ${message}` : '.'}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    /** Saves given RT STRUCT file into backend.
     * @param gZippedRTStruct Blob file of the DICOM RT STRUCT file to save. File MUST be gzipped!
     * @param fileInfo Fileinfo of the file to save, including the filename extension (should probably be .dcm.gz)
     */
    public async saveRTStruct(gZippedRTStruct: Blob, fileInfo: AzureFileInfo) {
        let formData = new FormData();

        formData.append('RTSTRUCT', gZippedRTStruct, fileInfo.filename);

        const client = getRtViewerBackendClient();
        const response = await client.post(saveRTStructUrl, {
            headers: {
                storageAccount: fileInfo.storageAccountName,
                fileShare: fileInfo.fileShareName
            },
            body: formData
        });
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = `An error occurred when trying to save RT STRUCT.`;
            console.error(message);
            console.log(json);
            if (_.has(json, 'detail')) {
                const details = json.detail;
                console.log('ERROR DETAILS:');
                console.log(details);

                if (_.isString(details)) {
                    if (details.includes('does not have lock for RTSTRUCT')) {
                        throw new Error('Item is not locked -- cannot save.');
                    }
                }

            }
            throw new Error(message);
        }
    }


    // Returns a cached sas if found (from local storage)
    public async getSas(storageAccountName: string) {
        const cachedSas = storage.getSas(storageAccountName);
        if (cachedSas) {
            return cachedSas;
        }
        const url = SasUrl + storageAccountName;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        if (response.status === 200) {
            const sas = await response.text();
            storage.setSas(storageAccountName, sas);
            return sas;
        }
        console.log("Failed to get SAS from the server. Status code: " + response.status);
    }

    public async getAuthenticatedUser() { // Call this only once! Then, use sasClient.username 
        const url = SasUrl + 'email';
        const client = getRtViewerBackendClient();

        const email = (await client.quickFetch(url, null, { asText: true })).toLowerCase();
        const username = email.replace("@mvision.ai", "")
        const permissions = await this.getUserPermissions();

        return { username: username, email: email, permissions: permissions };
    }

    public async getUserPermissions(): Promise<UserPermissions> {
        const url = UserPermissionsUrl;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const permissionsData = await response.json();
        return new UserPermissions(permissionsData);
    }

    /** Fetch user access data from backend (e.g. which annotation datasets current user has access to) */
    public async getUserAccess(): Promise<any> {
        const url = UserAccessUrl;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const accessData = await response.json();
        return accessData;
    }
}

// NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
export const rtViewerApiClient = new RTViewerAPIClient();
