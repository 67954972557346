/* 

A toolbar with some buttons that are supposed to have only one instance in the UI. Despite the name this can be placed anywhere, multiple times, although you
should make sure not to actually show more than once at a time to user to avoid confusion.

Usually this sort of functionality would be handled with a single main toolbar in the root layout file. This implementation allows us to also have this
global toolbar in RT Viewer without it taking an extra row of vertical space like a more traditional main toolbar would.

*/

import React from 'react';
import { connect } from 'react-redux';
import { Badge, Dropdown, Row } from 'react-bootstrap';
import { IconContext } from 'react-icons';

import './GlobalToolbar.css';
import { StoreState } from '../../store/store';
import * as sagas from '../../store/sagas';
import { User } from '../../store/user';
import { UserPermissions } from '../../web-apis/user-permissions';


type OwnProps = {
    // if the toolbar is NOT embedded, render some extra styles over it to position it correctly. Otherwise assume the containing component handles it.
    isEmbedded?: boolean
};

type DispatchProps = {
    setBatchJobPaneVisibility(value: boolean): void,
    setUserSettingsDialogVisibility(value: boolean): void,
    requestLogOut: () => void,
    setHelpDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;



class GlobalToolbar extends React.PureComponent<AllProps>{
    handleLogOutClick = () => {
        this.props.requestLogOut();
    }

    handleViewSettingsClick = () => {
        this.props.setUserSettingsDialogVisibility(true);
    }

    handleViewHelpClick = () => {
        this.props.setHelpDialogVisibility(true);
    }

    handlePermissionString = (permissions: UserPermissions): string => {
        if (permissions.isSupervisor) {
            return '(Supervisor)';
        } else if (permissions.isTrainee) {
            return '(Trainee)';
        } else {
            return '';
        }
    }

    render() {
        // TODO: when the value of rtViewerApiClient.isMVisionUser() changes, React won't trigger an update (because it doesn't know anything about it)
        const isEmbedded = this.props.isEmbedded !== undefined ? this.props.isEmbedded : false;
        const user = this.props.user as User;
        const permissionString = this.handlePermissionString(user.permissions as UserPermissions);

        // remove domain name (after the @) from username
        const username = user.username.split('@')[0];

    

        const toolbarButtons = (
            <div className="global-toolbar-buttons">
                <Row style={{ alignItems: 'center'}}>
                    <IconContext.Provider value={{ size: '18px' }} >
                        {/* <IoMdNotificationsOutline size={25} style={{ margin: '5px' }} fill='xl'/> */}
                        <Dropdown>
                        <Dropdown.Toggle as={Badge} id="dropdown-custom-components username-select" size='lg' data-cy="user-logged-dropdown">
                            {username} {permissionString}
                        </Dropdown.Toggle>
                            <Dropdown.Menu alignRight>
                                <Dropdown.Item eventKey="1" onClick={this.handleViewSettingsClick}>Settings</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={this.handleViewHelpClick}>Help</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="3" onClick={this.handleLogOutClick}>Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </IconContext.Provider>
                </Row>
            </div>
        );

        return (
            <div className="toolbar-main-container">
                {isEmbedded ? toolbarButtons : (<div className="global-main-toolbar"><div className="toolbar-container">{toolbarButtons}</div></div>)}
            </div>
        );
    }
}

export default connect(state => Object.assign({}, state), sagas.mapDispatchToProps)(GlobalToolbar);
