import React from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip, Spinner } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { IoMdEye } from 'react-icons/io';
import { Link } from 'react-router-dom';

import * as sagas from '../../../store/sagas';
import { StoreState } from '../../../store/store';
import './DifferencesToolbar.css';
import { selectSupportingScansForCurrentWorkState } from '../../../store/selectors';
import routePaths from '../../../routes';
import { UrlQuery, UrlQueryType } from '../../../store/url-query';

import './SupportingScansToolbar.css';
import { DatasetImage } from '../../../datasets/dataset-image';
import _ from 'lodash';


type OwnProps = {
}

type DispatchProps = {
    loadSupportingScanData(supportingScans: DatasetImage[]): void,
}

// combine redux & selector props
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => Object.assign({}, state,
    {
        supportingScans: selectSupportingScansForCurrentWorkState(state),
    });
const connector = connect(mapStateToProps, sagas.mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = OwnProps & StoreState & DispatchProps & ReduxProps;
// ... other imports remain the same

class SupportingScansToolbar extends React.PureComponent<AllProps> {
    displayName = SupportingScansToolbar.name;

    componentDidMount = () => {
        const { supportingScanData, supportingScans } = this.props;

        const supportingScansToLoad: DatasetImage[] = [];

        if (supportingScanData) {
            for (const supportingScan of supportingScans) {
                if (!Object.keys(supportingScanData).includes(supportingScan.scanId)) {
                    supportingScansToLoad.push(supportingScan);
                }
            }
        }

        if (supportingScansToLoad.length > 0) {
            this.props.loadSupportingScanData(supportingScansToLoad);
        }
    }

    getSupportingScanInfo = (scan: DatasetImage, includeTagNames = true): string[] => {
        const { supportingScanData } = this.props;

        const values = [];

        // Add descriptions in order of priority
        if (scan.seriesDescription) {
            values.push(`${includeTagNames ? 'SeriesDescription: ' : ''}${scan.seriesDescription}`);
        }

        const supportingScan = supportingScanData ? supportingScanData[scan.scanId] : undefined;
        if (supportingScan) {
            const slice = supportingScan.imageSlice;
            if (slice.studyDescription) { values.push(`${includeTagNames ? 'StudyDescription: ' : ''}${slice.studyDescription}`); }
            if (slice.protocolName) { values.push(`${includeTagNames ? 'ProtocolName: ' : ''}${slice.protocolName}`); }
            if (slice.seriesDate) { values.push(`${includeTagNames ? 'SeriesDate: ' : ''}${slice.seriesDate}`); }
            if (slice.studyDate) { values.push(`${includeTagNames ? 'StudyDate: ' : ''}${slice.studyDate}`); }
        }

        return values;
    }

    getLabel = (scan: DatasetImage, index: number) => {
        const values = this.getSupportingScanInfo(scan, false);

        const supportingScanLabel = values.length > 0 ? `: ${values[0]}` : '';

        return `#${index + 1}${supportingScanLabel} (${scan.modality})`;
    }

    getTooltipInfo = (scan: DatasetImage) => {
        const values = this.getSupportingScanInfo(scan);

        return values.length > 0 ? (<div>{values.map(v => (<div key={v}>{v}</div>))}</div>) : 'Supporting scan';
    };

    render() {
        const { supportingScans, isLoadingSupportingScanData } = this.props;
        // all supporting scans should be from the same dataset!
        const dataset = this.props.currentWorkState!.dataset;

        if (!dataset) {
            throw new Error('No valid dataset');
        }

        return (
            <Row className='task-details-tab'>
                <Col style={{ display: "flex" }}>
                    <span className="supporting-scans-header"><h5>Supporting Scans</h5></span>

                    {isLoadingSupportingScanData ?
                        (
                            <div className="supporting-scan-spinner">
                                <Spinner animation="border" role="status" className="spinner">
                                    <span className="sr-only">Loading data for supporting scans, please wait</span>
                                </Spinner>
                                <div>Loading data for supporting scans...</div>
                            </div>
                        )
                        :
                        supportingScans.map((scan, index) => (
                            <span key={scan.scanId} className="supporting-scan-link">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-${scan.scanId}`} bsPrefix="supporting-scan-tooltip tooltip">
                                            {this.getTooltipInfo(scan)}
                                        </Tooltip>
                                    }
                                >
                                    <Link
                                        target="_blank" rel="noopener noreferrer"
                                        to={{
                                            pathname: routePaths.supportingScan,
                                            search: new UrlQuery(
                                                UrlQueryType.SupportingScanQuery,
                                                dataset.datasetFile.storageAccountName,
                                                dataset.datasetFile.fileShareName,
                                                scan.patientId,
                                                scan.frameOfReferenceUid,
                                                scan.seriesId
                                            ).getQueryParameters(UrlQueryType.SupportingScanQuery),
                                        }}>
                                        <Button
                                            className='view-button supporting-scan'
                                            variant={"light"}
                                            size="sm">
                                            <span>
                                                <IoMdEye size={20} className="supporting-scan-icon" />
                                                <span>{this.getLabel(scan, index)}</span>
                                            </span>
                                        </Button>
                                    </Link>
                                </OverlayTrigger>
                            </span>
                        ))}
                </Col>
            </Row>
        );
    }
}

export default connector(SupportingScansToolbar);
