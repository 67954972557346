import _ from "lodash";
import { AliasMap } from "./alias-map";

export type UserSettings = {
    clinicId: string,
    rois: {
        roiName: string,
        aliases: string[],
    }[],
};

export const convertJsonToUserSettings = (json: any): UserSettings => {
    if (!_.has(json, 'id') || !_.isString(json.id)) {
        throw new Error('Expected to find prop \'id\' from user settings');
    }

    const clinicId = json.id;

    if (!clinicId) {
        throw new Error('User has not been configured into a valid clinic');
    }

    const userSettings: UserSettings = { clinicId, rois: [] };

    if (!_.has(json, 'rois') || !_.isArray(json.rois)) {
        throw new Error('Expected to find prop \'rois\' from user settings');
    }

    for (const roi of json.rois) {
        if (!_.has(roi, 'roi_name') || !_.isString(roi.roi_name)) {
            throw new Error('Expected to find prop \'roi_name\' from user settings roi object');
        }
        if (_.has(roi, 'aliases') && (!_.isArray(roi.aliases) || (roi.aliases.length > 0 && roi.aliases.every((a: any) => !_.isString(a))))) {
            throw new Error('Expected to find prop \'aliases\' from user settings roi object');
        }

        userSettings.rois.push({ roiName: roi.roi_name, aliases: roi.aliases || [] });
    }

    return userSettings;
}

export const convertUserSettingsToJson = (userSettings: UserSettings): any => {

    const json: any = { id: userSettings.clinicId, rois: [] };

    for (const roi of userSettings.rois) {
        json.rois.push({ roi_name: roi.roiName, aliases: roi.aliases });
    }

    return json;
}

export const getAliasMapFromUserSettings = (userSettings: UserSettings): AliasMap => {
    const aliasMap: AliasMap = {};
    userSettings.rois.forEach(r => aliasMap[r.roiName] = r.aliases);

    return aliasMap;
}

export const addAliasMapToUserSettings = (userSettings: UserSettings, aliasMap: AliasMap): UserSettings => {
    const newUserSettings: UserSettings = { clinicId: userSettings.clinicId, rois: [] };
    for (const roi of Object.keys(aliasMap)) {
        // don't include
        // 1. empty arrays
        // 2. empty values
        // 3. values that are identical to roi name itself
        const aliases = aliasMap[roi].filter(a => a !== '' && a !== roi);
        if (aliases.length > 0) {
            newUserSettings.rois.push({ roiName: roi, aliases: aliases });
        }
    }

    return newUserSettings;
}
