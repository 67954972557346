import Immerable from "../../../store/immerable";

export enum NotificationType {
    Information, Success, Failure, Warning, Error
}

export const DEFAULT_SESSION_TIMEOUT_IN_MS = 4000;

export class SessionNotification extends Immerable {
    id: string;
    message: string;
    type: NotificationType;
    detailedMessage?: string | React.ReactNode;
    timeoutInMs: number | undefined;

    constructor(id: string, message: string, type: NotificationType, detailedMessage?: string | React.ReactNode, timeoutInMs?: number) {
        super();
        this.id = id;
        this.message = message;
        this.type = type;
        this.detailedMessage = detailedMessage;
        this.timeoutInMs = timeoutInMs;
    }
}

export function notificationTypeToBootstrapVariant(type: NotificationType) {
    switch (type) {
        case NotificationType.Success:
            return 'success';
        case NotificationType.Failure:
            return 'danger';
        case NotificationType.Warning:
            return 'warning';
        case NotificationType.Error:
            return 'danger';
        case NotificationType.Information:
        default:
            return 'secondary';
    }
}
